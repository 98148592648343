import { useMemo } from "react";
import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";

export const useMechanics = (customer_id) => {
    const query = useQuery(["mechanics", customer_id], () => taskAPI.getMechanics(customer_id), {
        enabled: !!customer_id,
    });

    return {
        ...query,
        data: useMemo(
            () =>
                query.data?.map((item) => {
                    return { ...item, name: `${item.first_name} ${item.last_name}` };
                }),
            [query.data]
        ),
    };
};
