import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePermission } from "../user/hooks/usePermission";
import { useUnitComments } from "./hooks/useUnitComments";
import { useUnitsComments } from "./hooks/useUnitsComments";
import { useUnitCommentAcknowledge } from "./mutations/useUnitCommentAcknowledge";
import { device } from "../../theme";
import QueryWrapper from "../../components/application/QueryWrapper";

import { standardDate } from "../../common/date";
import { Row, Vertical } from "../../components/layout/FlexGrid";
import { Checkbox } from "../../components/inputs";
import { T, S, TB } from "../../components/texts";
import { Button } from "../../components/buttons";
import StatusLine from "../../components/layout/StatusLine";
import { ErrorIcon } from "../../components/icons";
import { isSameDay, isSameMonth } from "date-fns";

export const Comments = ({ id }) => {
    const [checked, setChecked] = useState([]);
    const { t } = useTranslation();

    const permission = usePermission("unit_comment_admin");
    const unitOneComments = useUnitComments(id);
    const unitAllComments = useUnitsComments();
    const unitComments = id ? unitOneComments : unitAllComments;
    const { mutate: acknowledgeComment } = useUnitCommentAcknowledge();

    function handleCheckedChange(commentId, value) {
        if (value) {
            setChecked([...checked.filter((c) => c.id !== commentId), { id: commentId, value }]);
        } else {
            setChecked([...checked.filter((c) => c.id !== commentId)]);
        }
    }

    function removeComments() {
        const ids = checked.map((c) => c.id).join(",");
        acknowledgeComment(ids);
    }

    return (
        <QueryWrapper data={unitComments}>
            <CommentsStyled>
                {(id || permission) && unitComments?.data?.length > 0 && (
                    <>
                        <Header>
                            <TB>{t("needfolllowup")}</TB>
                        </Header>

                        {unitComments.data.map((comment, index) => (
                            <Comment
                                key={`${comment.id}${index}`}
                                onChange={handleCheckedChange}
                                id={comment.id}
                                unitOnly={id ? true : false}
                                workorder={comment.workorder}
                            />
                        ))}

                        {permission && (
                            <Button
                                disabled={!checked.some((c) => c.value)}
                                onClick={() => removeComments()}
                            >
                                {t("remove_from_list")}
                            </Button>
                        )}
                    </>
                )}
            </CommentsStyled>
        </QueryWrapper>
    );
};

export const Comment = ({ id, workorder, onChange, unitOnly }) => {
    const [checked, setChecked] = useState(false);

    const { t } = useTranslation();

    const allComments = useUnitsComments();
    const permission = usePermission("unit_comment_admin");
    const comment = allComments?.data?.find((item) => item.id === id);

    useEffect(() => {
        onChange(comment?.id, checked);
    }, [checked]);

    const fixStartAndEndDate = useMemo(() => {
        if (!workorder) return null;
        if (!workorder.tasks) return null;
        if (workorder.tasks.length === 0) return null;

        const { startTask, endTask } = workorder.tasks.reduce(
            (acc, curr) => {
                if (curr.start) {
                    if (acc.startTask === null) {
                        acc.startTask = curr;
                    } else if (new Date(curr.start) < new Date(acc.startTask.start)) {
                        acc.startTask = curr;
                    }
                }

                if (curr.end) {
                    if (acc.endTask === null) {
                        acc.endTask = curr;
                    } else if (new Date(curr.end) < new Date(acc.endTask.end)) {
                        acc.endTask = curr;
                    }
                }

                return acc;
            },
            {
                startTask: null,
                endTask: null,
            }
        );

        if (startTask === null) return null;
        if (endTask === null) return null;

        const start = new Date(startTask.start);
        const end = new Date(endTask.end);
        if (isSameDay(start, end)) {
            return `${start.toLocaleString("no", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            })} - ${end.toLocaleString("no", {
                hour: "2-digit",
                minute: "2-digit",
            })}`;
        }

        if (isSameMonth(start, end)) {
            const startDay = start.getDate();
            const month = start.getMonth() + 1;
            return `${startDay < 10 ? `0${startDay}` : startDay}.${month < 10 ? `0${month}` : month} - ${end.toLocaleString(
                "no",
                {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                }
            )}`;
        }

        return `${start.toLocaleString("no", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        })} - ${end.toLocaleString("no", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        })}`;
    }, [workorder?.tasks]);

    return (
        <CommentStyled $status={comment?.status}>
            <QueryWrapper data={allComments}>
                {permission && comment?.unit && (
                    <>
                        <CheckInfo>
                            <div>
                                <Checkbox
                                    size={20}
                                    checked={checked}
                                    onClick={() => setChecked(!checked)}
                                />
                                <CheckboxLine $comment={comment} />
                                {comment.status === 3 && (
                                    <ErrorIconContainer>
                                        <ErrorIcon />
                                    </ErrorIconContainer>
                                )}
                            </div>

                            <Vertical style={{ marginLeft: "0.5rem" }}>
                                <T style={{ marginBottom: "0.3rem" }}>
                                    <TB style={{ fontWeight: 700 }}>{`${
                                        comment.label || t("post_check")
                                    }: `}</TB>
                                    {comment.comment || "–"}
                                </T>
                                {!unitOnly && (
                                    <S>
                                        {comment.unit.int_id} {comment.unit.group.name}{" "}
                                        {comment.unit.name} {comment.unit.type}
                                    </S>
                                )}
                                <S>
                                    {comment.user.first_name} {comment.user.last_name},{" "}
                                    {standardDate(comment.createdAt)}
                                </S>
                            </Vertical>
                        </CheckInfo>

                        {fixStartAndEndDate !== null ? (
                            <DateWrapper>
                                <DateDescriptionWrapper>{t("planned_fix")}:</DateDescriptionWrapper>
                                <div>{fixStartAndEndDate}</div>
                            </DateWrapper>
                        ) : null}
                    </>
                )}

                {!permission && comment?.unit && (
                    <>
                        <Row>
                            <StatusLine data={comment} />
                            <TB>
                                {comment?.status === 3
                                    ? t("defect_usage_forbidden")
                                    : t("deviation")}
                            </TB>
                        </Row>
                        <Row>
                            <Vertical>
                                <T>{comment?.comment}</T>
                                {!unitOnly && (
                                    <S>
                                        {comment?.unit.int_id} {comment?.unit.group.name}{" "}
                                        {comment?.unit.name} {comment?.unit.type}
                                    </S>
                                )}
                                <S>
                                    {comment?.user.first_name} {comment?.user.last_name}{" "}
                                    {comment?.createdAt}
                                </S>
                            </Vertical>
                        </Row>
                    </>
                )}
            </QueryWrapper>
        </CommentStyled>
    );
};

const ErrorIconContainer = styled.div`
    margin-top: 0.3rem;

    svg {
        width: 1.2rem;
        height: 1.2rem;
    }
`;

const Header = styled.div`
    margin-bottom: 0.3rem;
`;

const CommentStyled = styled.div`
    display: flex;
    margin-bottom: 0.2rem;
    padding: 0.5rem 1.5rem 1rem 0.5rem;
    max-width: 62rem;

    background: ${({ theme, $status }) =>
        $status === 3 ? theme.color.error.xxlight : theme.color.warning.xxlight};

    @media only screen and ${device.mobile} {
        flex-direction: column;
    }
`;

const DateDescriptionWrapper = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    margin-bottom: 0.25em;
`;

const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and ${device.mobile} {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 1.7rem;
    }
`;

const CheckInfo = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const CheckboxLine = styled.div`
    border-bottom: 2px solid
        ${(props) =>
            props.$comment.status === 2
                ? props.theme.color.warning.base
                : props.theme.color.error.base};
    width: 20px;
`;

const CommentsStyled = styled.div`
    button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;
