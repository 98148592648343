import { useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DateInput, InputWithLabel } from "../../../components/inputs";
import { Horizontal } from "../../../components/layout/FlexGrid";
import WorkshopSelect from "../../../components/application/workshop/WorkshopSelect";
import VehicleTable from "./VehicleTable";
import MainArea from "../../../components/layout/MainArea";
import { useSearchParams } from "../../application/hooks/useSearchParams";
import { useUnitsOverview } from "../../unit/hooks/useUnitsOverview";
import { formatISO } from "date-fns";
import { DateTime, Interval } from "luxon";

const selectFilter = (row, columnId, value) => {
    if (columnId === "status") return true;
    const { getValue } = row;
    return getValue(columnId) === value;
};

const VehicleOverview = () => {
    const { t } = useTranslation();

    const methods = useForm();
    const search = methods.watch("search");

    const { getParam, setParam, deleteParam } = useSearchParams();
    const workshop_id = getParam("workshop_id");
    const fromParam = getParam("from");
    const toParam = getParam("to");

    const units = useUnitsOverview({
        queryParamsObject: {
            customer_id: workshop_id,
            from: fromParam == null ? undefined : fromParam,
            to: toParam == null ? undefined : toParam,
        },
        enabled: workshop_id != null,
    });

    const data = useMemo(() => {
        if (units?.data == null) return [];

        const now = DateTime.local();
        const from = DateTime.fromJSDate(fromParam);
        const to = DateTime.fromJSDate(toParam);
        return units.data.map(
            ({
                int_id,
                group,
                manufacturer,
                type,
                defected_at,
                fixed_at,
                createdAt,
                downtime,
                tasks,
            }) => {
                let downtimeSum = Number(downtime);
                const defectedAt = DateTime.fromISO(defected_at);
                if (fixed_at == null) {
                    const start = DateTime.max(from, defectedAt);
                    const end = DateTime.min(to, now);
                    if (end > start) {
                        downtimeSum += Math.floor(
                            Interval.fromDateTimes(start, end).length("hours")
                        );
                    }
                } else {
                    const fixedAt = DateTime.fromISO(fixed_at);
                    if (fixedAt > from) {
                        downtimeSum += Math.floor(
                            Interval.fromDateTimes(
                                DateTime.max(from, defectedAt),
                                DateTime.min(to, fixedAt, now)
                            ).length("hours")
                        );
                    }
                }

                const downtimeHours = Interval.fromDateTimes(
                    DateTime.max(from, DateTime.fromISO(createdAt)),
                    DateTime.min(to, now)
                ).length("hours");
                return {
                    int_id,
                    group: group.name,
                    manufacturer,
                    type,
                    costs: tasks.reduce(
                        (acc, curr) => {
                            const materials = curr.parts_cost;
                            const hours = curr.invoice_total - curr.parts_cost;
                            acc.materials += materials;
                            acc.hours += hours;
                            acc.total += materials + hours;

                            return acc;
                        },
                        {
                            materials: 0,
                            hours: 0,
                            total: 0,
                        }
                    ),
                    uptime: (100 - 100 * (downtimeSum / downtimeHours)).toFixed(0),
                };
            }
        );
    }, [units?.data, fromParam, toParam]);

    const columns = useMemo(
        () => [
            {
                header: t("id"),
                accessorKey: "int_id",
            },
            {
                header: t("vehicle_group"),
                accessorKey: "group",
            },
            {
                header: `${t("brand")} - ${t("type")}`,
                accessorFn: ({ manufacturer, type }) => `${manufacturer} ${type}`,
            },
            {
                header: t("hourly_cost"),
                accessorKey: "costs.hours",
                cell: ({ getValue }) => getValue().toFixed(0),
            },
            {
                header: t("material_cost"),
                accessorKey: "costs.materials",
                cell: ({ getValue }) => getValue().toFixed(0),
            },
            {
                header: t("total_cost"),
                accessorKey: "costs.total",
                cell: ({ getValue }) => getValue().toFixed(0),
            },
            {
                header: t("uptime"),
                accessorKey: "uptime",
                cell: ({ getValue }) => `${getValue()}%`,
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        filterFns: {
            select: selectFilter,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function updateFromToDate(newDate, key) {
        const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

        if (formattedDate) {
            setParam(key, formattedDate);
        } else {
            deleteParam(key);
        }
    }

    return (
        <MainArea>
            <Container>
                <Header>
                    <CustomerSelectContainer>
                        <WorkshopSelect
                            onChange={(e) => setParam("workshop_id", e.value)}
                            defaultWorkshopId={workshop_id}
                        />
                    </CustomerSelectContainer>
                </Header>

                <FormProvider {...methods}>
                    {!!workshop_id && (
                        <form>
                            <InputsContainer>
                                <SearchInput
                                    name="search"
                                    label={t("search_units")}
                                    autoComplete="off"
                                />
                                <DateInputContainer>
                                    <DateInput
                                        name="from"
                                        label={t("from")}
                                        value={fromParam ? new Date(fromParam) : null}
                                        setValue={(v) => updateFromToDate(v, "from")}
                                    />
                                </DateInputContainer>
                                <DateInputContainer>
                                    <DateInput
                                        name="to"
                                        label={t("to")}
                                        value={toParam ? new Date(toParam) : null}
                                        setValue={(v) => updateFromToDate(v, "to")}
                                    />
                                </DateInputContainer>
                            </InputsContainer>

                            <VehicleTable
                                headerGroups={headerGroups}
                                rowModel={rowModel}
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                setPageIndex={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                                isLoading={units?.isLoading}
                            />
                        </form>
                    )}
                </FormProvider>
            </Container>
        </MainArea>
    );
};

export default VehicleOverview;

const InputsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

const SearchInput = styled(InputWithLabel)`
    flex-grow: 1;
    max-width: 27rem;
    margin-right: 1rem;
`;

const DateInputContainer = styled.div`
    margin-right: 1rem;
`;

const Container = styled.div`
    opacity: 0;
    animation: fadeIn 300ms ease forwards;
`;

const Header = styled(Horizontal)`
    margin-top: 0.8rem;
    margin-bottom: 1rem;

    align-items: center;
    flex-wrap: wrap;
`;

const CustomerSelectContainer = styled.section`
    min-width: 20rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
`;
