import { useMemo } from "react";
import { startOfMinute, endOfMinute } from "date-fns";
import styled from "styled-components";

import { useUnits } from "../../unit/hooks/useUnits";
import { useMultipleUnitSensorDataFromTo } from "../../unit/hooks/useMultipleUnitSensorDataFromTo";
import { MapHighlightProvider } from "./hooks/useMapHighlight";

import UnitStatusMap from "./UnitStatusMap";
import UnitStatusTable from "./UnitStatusTable";
import { useUnitDefectHistories } from "../../unit/hooks/useUnitDefectHistories";
import { TaskStatus } from "../../../api/core/taskAPI";
import { useTasksForUnit } from "../../task/hooks/useTasksForUnit";

const sensorColors = [
    "#352ef8",
    "#ff0000",
    "#ffcd11",
    "#389446",
    "#c40694",
    "#55c8da",
    "#87503e",
    "#616565",
    "#eb662e",
    "#ff80df",
];

const UnitStatusDataDisplay = ({
    selectedUnits,
    fromDate,
    toDate,
    handleRemoveUnitFromSelected,
}) => {
    const allUnits = useUnits();
    const selectedUnitIds = useMemo(
        () => selectedUnits?.map((item) => item.value),
        [selectedUnits]
    );

    const parsedFromDate = fromDate ? `${startOfMinute(new Date(fromDate)).toISOString()}` : null;
    const parsedToDate = toDate ? `${endOfMinute(new Date(toDate)).toISOString()}` : null;
    const sensorData = useMultipleUnitSensorDataFromTo(
        selectedUnitIds,
        parsedFromDate,
        parsedToDate
    );
    const unitDefectHistories = useUnitDefectHistories(
        parsedFromDate,
        parsedToDate,
        parsedFromDate != null && parsedToDate != null
    );
    const unitTasks = useTasksForUnit(fromDate, toDate, selectedUnitIds.join(","), [
        TaskStatus.COMPLETED,
        TaskStatus.INVOICED,
    ]);
    const isSensorDataLoading = sensorData?.some((item) => item.status === "loading");

    return (
        <MainContainer>
            <MapHighlightProvider>
                <UnitStatusMap
                    sensorData={sensorData}
                    selectedUnitIds={selectedUnitIds}
                    allUnits={allUnits}
                    sensorColors={sensorColors}
                    isSensorDataLoading={isSensorDataLoading}
                    fromDate={fromDate}
                    toDate={toDate}
                />

                <UnitStatusTable
                    sensorData={sensorData}
                    allUnits={allUnits}
                    selectedUnits={selectedUnits}
                    sensorColors={sensorColors}
                    fromDate={fromDate}
                    toDate={toDate}
                    handleRemoveUnitFromSelected={handleRemoveUnitFromSelected}
                    unitDefectHistories={unitDefectHistories}
                    unitTasks={unitTasks}
                />
            </MapHighlightProvider>
        </MainContainer>
    );
};

export default UnitStatusDataDisplay;

const MainContainer = styled.div`
    position: relative;
`;
