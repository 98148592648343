import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnit } from "./hooks/useUnit";
import { checkForPermission, usePermissions } from "../user/hooks/usePermissions";
import { PERMISSIONS } from "../auth/permissions";
import QueryWrapper from "../../components/application/QueryWrapper";

import UnitPlannedMaintenanceTable from "./UnitPlannedMaintenanceTable";
import UnitMaintenanceHistoryTable from "./UnitMaintenanceHistoryTable";
import MainArea from "../../components/layout/MainArea";
import UnitUsing from "../../components/application/unit/UnitUsing";
import { H1, T } from "../../components/texts";
import Underline from "../../components/helpers/Underline";
import { Col, Row } from "../../components/layout/FlexGrid";
import { ClockIcon, ServiceIcon, ClockNextIcon } from "../../components/icons";
import { Comments } from "./Comments";
import { UseUnitButton } from "../../components/buttons/UseUnitButton";
import { useTasksForUnit } from "../task/hooks/useTasksForUnit";
import { startOfWeek } from "../../common/date.js";
import { TaskStatus } from "../../api/core/taskAPI.js";

const Unit = ({
    noBackButton = false,
    handleUnitFinishPrefix = "/unit/finish/",
    handleUnitFinishSuffix = "",
    preCheckPrefix = "/pre-check/check-out/",
    preCheckSuffix = "",
    onReturnHomeNavigateTo = "/",
}) => {
    const { t } = useTranslation();
    const params = useParams();
    const id = Number(params.id);
    const unit = useUnit(id, true);
    const permissions = usePermissions();
    const scheduledTasks = useTasksForUnit(
        startOfWeek(new Date()).toISODate(),
        startOfWeek(new Date()).plus({ months: 12 }).toISODate(),
        id,
        [TaskStatus.SCHEDULED, TaskStatus.SCHEDULED_DRAFT, TaskStatus.STARTED]
    );

    const canUse = useMemo(
        () =>
            permissions?.data
                ? checkForPermission({
                      dataToCheck: permissions.data,
                      permission: PERMISSIONS.unit_use.name,
                      customer_id: unit?.data?.customer_id,
                  })
                : false,
        [permissions?.data]
    );

    const nextService = useMemo(() => {
        const nextServiceTask = scheduledTasks?.data?.reduce((acc, curr) => {
            if (acc == null) return curr;
            if (new Date(curr.start) < new Date(acc.start)) return curr;
            return acc;
        }, null);

        if (nextServiceTask) {
            return new Date(nextServiceTask.end).toLocaleString("no", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
        }

        if (!unit.data?.hour_counter || !Array.isArray(unit.data?.data?.service_intervals))
            return t("unknown");
        if (!(unit.data?.data?.service_intervals.length > 0)) return t("unknown");

        const sortedServiceIntervals = unit.data.data.service_intervals.sort((a, b) => {
            if (a.interval > b.interval) return 1;
            if (b.interval > a.interval) return -1;
            return 0;
        });
        const reducedInterval = Math.round(
            (unit.data.data.service_intervals.reduce((acc, curr) => {
                if (curr.interval === 0) return acc;
                return acc % curr.interval;
            }, unit.data.hour_counter) *
                100) /
                100
        );
        const nextInterval = sortedServiceIntervals.find(
            ({ interval }) => interval > reducedInterval
        );
        const remainingIntervalHours =
            (nextInterval ?? unit.data.data.service_intervals[0]).interval - reducedInterval;
        return `${remainingIntervalHours.toFixed(1)}${t("hours_unit_symbol")}`;
    }, [unit?.data, scheduledTasks?.data]);

    return (
        <MainArea back={!noBackButton}>
            <QueryWrapper data={unit}>
                <H1>
                    {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name} {unit.data?.type}
                </H1>
                <Underline $second />

                <EntryStyled>
                    <UnitUsing unit={unit?.data} />
                </EntryStyled>
                <Underline $second />

                {/* <EntryStyled>
                    <Row>
                        <Col size={1}>{unit.data?.defect ? <DefectIcon /> : <ClockIcon />}</Col>
                        <Col size={8}>
                            <T>{unit.data?.defect ? t("downtime") : t("previous_downtime")}</T>
                        </Col>
                        <Col size={3}>
                            <T>
                                {unit.data?.defected_at
                                    ? `${(
                                          Math.round(
                                              hoursBetweenDates(
                                                  DateTime.fromJSDate(
                                                      !unit.data?.defect &&
                                                          unit.data?.defect_fixed_at
                                                          ? new Date(unit.data?.defect_fixed_at)
                                                          : new Date()
                                                  ),
                                                  DateTime.fromISO(unit.data?.defected_at)
                                              ) * 100
                                          ) / 100
                                      ).toFixed(1)} ${t("hours_unit_symbol")}`
                                    : t("unknown")}
                            </T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second /> */}

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ClockIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("timecounter")}</T>
                        </Col>
                        <Col size={3}>
                            <T>
                                {unit.data?.hour_counter
                                    ? (Math.round(unit.data.hour_counter * 100) / 100).toFixed(1)
                                    : t("unknown")}
                            </T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second />

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ServiceIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("nextservice")}</T>
                        </Col>
                        <Col size={3}>
                            <QueryWrapper data={scheduledTasks}>
                                <T>{nextService}</T>
                            </QueryWrapper>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second />

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ClockNextIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("nextcontrol")}</T>
                        </Col>
                        <Col size={3}>
                            <T>
                                {unit.data?.next_periodic_technical_inspection_date
                                    ? new Date(
                                          unit.data?.next_periodic_technical_inspection_date
                                      ).toLocaleDateString("no", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                      })
                                    : "–"}
                            </T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second style={{ marginBottom: "2rem" }} />

                <Comments id={unit.data?.id} />

                <ButtonsContainer>
                    {canUse && (
                        <UseUnitButton
                            text
                            id={unit?.data?.id}
                            handleUnitFinishPrefix={handleUnitFinishPrefix}
                            handleUnitFinishSuffix={handleUnitFinishSuffix}
                            preCheckPrefix={preCheckPrefix}
                            preCheckSuffix={preCheckSuffix}
                            onReturnHomeNavigateTo={onReturnHomeNavigateTo}
                        />
                    )}
                </ButtonsContainer>
            </QueryWrapper>

            <UnitPlannedMaintenanceTable unitId={id} />
            <UnitMaintenanceHistoryTable unitId={id} />
        </MainArea>
    );
};

export default Unit;

const EntryStyled = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 0.65rem;
`;

const ButtonsContainer = styled.section`
    > * {
        margin-top: 10px;
        display: block;
        width: fit-content;
    }
`;
