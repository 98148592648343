import { Fragment } from "react";
import { SortIcon } from "../icons";
import { TB } from "../texts";
import { flexRender } from "@tanstack/react-table";
import StandardTableRow from "./StandardTableRow";

const StandardTableContent = ({ headerGroups, rowModel, renderRowSubComponent }) => (
    <>
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        const canSort = header.column.getCanSort();
                        const isSorted = header.column.getIsSorted();

                        return (
                            <th
                                className={header.column.columnDef.className}
                                key={header.id}
                                colSpan={header.colSpan}
                            >
                                <div
                                    className={canSort ? "cursor-pointer select-none" : ""}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TB>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </TB>

                                    {canSort ? <SortIcon sort={isSorted ?? ""} /> : null}
                                </div>
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>

        <tbody>
            {rowModel.rows.map((row) => {
                const isGrouped = row.getIsGrouped();
                if (isGrouped) {
                    // This code has currently been written to work with MyTasks.jsx
                    // Please rewrite to work with any component if necessary.
                    return (
                        <Fragment key={row.id}>
                            <tr>
                                <td
                                    style={{ paddingTop: "2rem", textAlign: "left" }}
                                    colSpan={row.getVisibleCells().length}
                                >
                                    <TB>{row.groupingValue}</TB>
                                </td>
                            </tr>
                            {row.subRows.map((subRow) => (
                                <StandardTableRow
                                    key={subRow.id}
                                    row={subRow}
                                    renderRowSubComponent={renderRowSubComponent}
                                />
                            ))}
                        </Fragment>
                    );
                }

                return (
                    <StandardTableRow
                        key={row.id}
                        row={row}
                        renderRowSubComponent={renderRowSubComponent}
                    />
                );
            })}
        </tbody>
    </>
);

export default StandardTableContent;
