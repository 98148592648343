import { useMemo, useState, useEffect } from "react";
import { useMatch, useLocation, NavLink } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

import { useFeatureFlagsForCurrentUser } from "../../features/feature-flag/hooks/useFeatureFlagsForCurrentUser";
import { usePermissions, checkForPermission } from "../../features/user/hooks/usePermissions";
import { PERMISSIONS } from "../../features/auth/permissions";

import NavBarSubMenu from "./NavBarSubMenu";
import { H1, TB } from "../texts";
import { HamburgerIcon, MenuXIcon, HelpIcon, LogoIcon } from "../icons";

const modules = [
    {
        path: "dashboard",
        link: "/dashboard",
        name: "dashboard",
        permissions: [PERMISSIONS.dashboard_view.name],
    },
    {
        path: "scheduler",
        link: "/scheduler",
        name: "scheduler",
        featureFlags: ["extended"],
        sub: [
            {
                path: "/scheduler/calendar",
                name: "calendar",
                permissions: [PERMISSIONS.scheduler_view_calendar.name],
            },
            {
                path: "/scheduler/tasks",
                name: "task_management",
                permissions: [PERMISSIONS.scheduler_view_taskmanagement.name],
            },
            {
                path: "/scheduler/recurring",
                name: "recurring_tasks",
                permissions: [PERMISSIONS.scheduler_view_recurring_tasks.name],
            },
        ],
    },
    {
        path: "materials",
        link: "/materials",
        name: "materials",
        featureFlags: ["extended"],
        sub: [
            {
                path: "/materials/requests",
                name: "requisitions",
                permissions: [PERMISSIONS.part_view_requests.name],
            },
            {
                path: "/materials/parts",
                name: "parts",
                featureFlags: ["extended"],
                permissions: [PERMISSIONS.part_view_inventory.name],
            },
            {
                path: "/materials/inventory-withdrawal",
                name: "inventory_withdrawal",
                featureFlags: ["extended"],
                permissions: [PERMISSIONS.part_view_storage_withdrawal.name],
            },
        ],
    },
    {
        path: "units",
        link: "/units",
        name: "vehicles",
        sub: [
            {
                path: "/units/all",
                name: "overview",
                permissions: [PERMISSIONS.unit_view_overview.name],
            },
            {
                path: "/units/status",
                name: "unit_status",
                featureFlags: ["extended"],
                permissions: [PERMISSIONS.unit_view_status.name],
            },
            {
                path: "/units/groups",
                name: "groups",
                permissions: [PERMISSIONS.unit_view_groups.name],
            },
            {
                path: "/units/prechecks",
                name: "precheck",
                permissions: [PERMISSIONS.unit_view_precheck.name],
            },
        ],
    },
    {
        path: "reports",
        link: "/reports",
        name: "reports",
        sub: [
            {
                path: "/reports/prechecks",
                name: "prechecks",
                permissions: [PERMISSIONS.reports_view_prechecks.name],
            },
            {
                path: "/reports/hours",
                name: "hour_report",
                permissions: [PERMISSIONS.reports_view_hourreport.name],
            },
            { path: "/reports/sja", name: "SJA", permissions: [PERMISSIONS.reports_view_sja.name] },
            {
                path: "/reports/downtime",
                name: "downtime",
                permissions: [PERMISSIONS.reports_view_downtime.name],
            },
            {
                path: "/reports/comments",
                name: "deviation_list",
                permissions: [PERMISSIONS.reports_view_deviation_list.name],
            },
            {
                path: "/reports/vehicle-overview",
                name: "vehicle_overview",
                permissions: [PERMISSIONS.reports_view_unit_overview.name],
            },
        ],
    },
    {
        path: "user",
        hide: true,
        name: "my_page",
        sub: [
            {
                path: "/user/mytasks",
                name: "my_tasks",
                featureFlags: ["extended"],
            },
            {
                path: "/user/profile",
                name: "profile",
            },
            {
                path: "/user/logout",
                name: "logout",
            },
        ],
    },
    {
        path: "administration",
        link: "/administration",
        name: "module_administration",
        sub: [
            {
                path: "/administration/users",
                permissions: [PERMISSIONS.administration_users_and_permissions_view.name],
                name: "users_and_access",
            },
            {
                path: "/administration/customers",
                permissions: [PERMISSIONS.administration_customers_view.name],
                name: "customers",
            },
            {
                path: "/administration/roles",
                permissions: [PERMISSIONS.administration_roles_view.name],
                name: "roles",
            },
            {
                path: "/administration/setup",
                permissions: [PERMISSIONS.administration_setup_view.name],
                name: "setup",
            },
        ],
    },
];

const NavBar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();

    const featureFlags = useFeatureFlagsForCurrentUser();
    const permissions = usePermissions();
    const location = useLocation();
    const menu = useMatch({ path: "/:module", end: false });

    // Close mobile menu when location changes
    useEffect(() => {
        setIsMobileMenuOpen(false);
    }, [location]);

    // Blocks body scroll when mobile menu is open
    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.removeProperty("overflow");
        }
        return () => {
            document.body.style.removeProperty("overflow");
        };
    }, [isMobileMenuOpen]);

    function shouldShowModule({ module, permissions, featureFlags }) {
        const hasPermissionLimit = "permissions" in module && module.permissions.length > 0;
        const userHasOneOfPermissions =
            hasPermissionLimit &&
            hasOneOfPermissions({
                dataToCheck: permissions,
                permissionsToCheck: module.permissions,
            });

        const hasFeatureLimit = "featureFlags" in module;
        const userHasAllFeatureFlags =
            hasFeatureLimit &&
            hasAllFeatureFlags({
                dataToCheck: featureFlags,
                featureFlagsToCheck: module.featureFlags,
            });

        const shouldShowModule =
            (!hasPermissionLimit || userHasOneOfPermissions) &&
            (!hasFeatureLimit || userHasAllFeatureFlags);

        return shouldShowModule;
    }

    function hasOneOfPermissions({ dataToCheck, permissionsToCheck }) {
        return permissionsToCheck
            ?.map((permission) => checkForPermission({ dataToCheck, permission }))
            ?.some((check) => check === true);
    }

    function hasAllFeatureFlags({ dataToCheck, featureFlagsToCheck }) {
        return featureFlagsToCheck.every((item) => dataToCheck.some((flag) => flag.name === item));
    }

    const module = useMemo(() => {
        if (permissions?.data == null) return null;
        if (featureFlags?.data == null) return null;
        if (menu?.params?.module == null) return null;

        const foundModule = modules.find((module) => module.path === menu.params.module);
        if (foundModule == null) return null;
        const showModule = shouldShowModule({
            module: foundModule,
            permissions: permissions.data,
            featureFlags: featureFlags.data,
        });
        return showModule ? foundModule : null;
    }, [menu, permissions?.data, featureFlags?.data]);

    const subs = useMemo(() => {
        if (permissions?.data == null) return null;
        if (featureFlags?.data == null) return null;
        if (module?.sub == null) return null;
        return module.sub.filter((item) =>
            shouldShowModule({
                module: item,
                permissions: permissions.data,
                featureFlags: featureFlags.data,
            })
        );
    }, [module, permissions?.data, featureFlags?.data]);

    const availableModules = useMemo(() => {
        if (permissions?.data == null) return null;
        if (featureFlags?.data == null) return null;

        return modules.filter((module) => {
            const hasModuleAccess = shouldShowModule({
                module,
                permissions: permissions.data,
                featureFlags: featureFlags.data,
            });
            if (!hasModuleAccess) return false;
            if (module.sub == null) return true;

            return module.sub.some((sub) =>
                shouldShowModule({
                    module: sub,
                    permissions: permissions.data,
                    featureFlags: featureFlags.data,
                })
            );
        });
    }, [permissions?.data, featureFlags?.data]);

    return isMobileMenuOpen ? (
        <MobileMenuContainer>
            <MobileHeader>
                <NavLink to="/">
                    <LogoIcon />
                </NavLink>

                <MenuButton onClick={() => setIsMobileMenuOpen(false)}>
                    <MenuXIcon stroke="white" />
                    <H1 $second>{t("menu")}</H1>
                </MenuButton>
            </MobileHeader>
            <MobileMenuContent>
                <li>
                    <NavLinkMain to="/">{t("main")}</NavLinkMain>
                </li>

                {availableModules?.length > 0 &&
                    availableModules.map((item, index) =>
                        item.sub?.length > 0 ? (
                            <NavBarSubMenu module={item} key={item.path + index} />
                        ) : (
                            <li key={item.path + index}>
                                <NavLinkMain to={item.link ? item.link : item.path}>
                                    {t(item.name)}
                                </NavLinkMain>
                            </li>
                        )
                    )}

                <li>
                    <NavLinkMain to="/docs/mainflow-hjelp.pdf" target="_blank">
                        <HelpIconContainer>
                            <HelpIcon />
                        </HelpIconContainer>
                        {t("help_and_documentation")}
                    </NavLinkMain>
                </li>
            </MobileMenuContent>
        </MobileMenuContainer>
    ) : (
        <HeaderContainer>
            <MainHeader>
                <PathDisplay>
                    <LogoLink to="/">
                        <LogoIcon />
                    </LogoLink>

                    {availableModules?.length > 0 &&
                        availableModules
                            .filter((module) => module.path !== "user")
                            ?.map((module, index) => (
                                <NavLinkMainDesktop to={module.link} key={`${module}-${index}`}>
                                    <TB $second>{t(module.name)}</TB>
                                </NavLinkMainDesktop>
                            ))}
                </PathDisplay>

                <NavLinkMainDesktop to="/user">
                    <TB $second>{t("my_page")}</TB>
                </NavLinkMainDesktop>
            </MainHeader>

            {subs && subs.length > 0 && (
                <SubMenu>
                    {subs
                        .filter((item) => !item.hide)
                        ?.map((item) => (
                            <NavLinkSecondary
                                key={item.path}
                                to={item.link ? item.link : item.path}
                                data-title={t(item.name)}
                            >
                                {t(item.name)}
                            </NavLinkSecondary>
                        ))}
                </SubMenu>
            )}

            <MobileHeader className="header-closed">
                <NavLink to="/">
                    <LogoIcon />
                </NavLink>

                <MenuButton onClick={() => setIsMobileMenuOpen(true)}>
                    <HamburgerContainer>
                        <HamburgerIcon stroke={theme.text.color.second} />
                    </HamburgerContainer>
                    <H1 $second>{t("menu")}</H1>
                </MenuButton>
            </MobileHeader>
        </HeaderContainer>
    );
};

export default NavBar;

const HeaderContainer = styled.div`
    top: 0;
    position: sticky;
    z-index: 40;
`;

const MainHeader = styled.nav`
    background-color: ${(props) => props.theme.header.background.color};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.125rem;

    @media (max-width: 912px) {
        display: none;
    }
`;

const MobileMenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: auto;
    background-color: ${(props) => props.theme.header.background.color};
`;

const MobileHeader = styled.nav`
    height: 4rem;
    background-color: ${(props) => props.theme.header.background.color};
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0.625rem 0;
    display: flex;
    z-index: 99;

    &.header-closed {
        @media (min-width: 913px) {
            display: none;
        }
    }
`;

const MobileMenuContent = styled.ul`
    display: flex;
    flex-direction: column;
    z-index: 10;
    color: ${(props) => props.theme.text.color.second};
    list-style: none;
    padding: 0;
    margin: 0;

    opacity: 0;
    animation: fadeIn 300ms ease forwards;

    &:before {
        content: "";
        width: calc(100% - (0.625rem * 2));
        margin-left: 0.625rem;
        height: 2px;
        background: white;
    }

    > li {
        padding: 0.5rem 0.625rem;

        &:after {
            position: absolute;
            display: block;
            content: "";
            width: calc(100% - (0.625rem * 2));
            height: 2px;
            background: #fff;
            margin-top: 0.5rem;
        }

        > a {
            display: block;
        }
    }
`;

const SubMenu = styled.nav`
    height: 3.125rem;
    background-color: ${(props) => props.theme.color.primary.xlight};
    display: flex;
    align-items: center;
    padding: 0 3.125rem;

    > * {
        margin-right: 1.875rem;
    }

    @media (max-width: 912px) {
        display: none;
    }
`;

const LogoLink = styled(NavLink)`
    margin-right: 1.5rem;
    padding: 0.6rem 1rem 0.05rem;
`;

const PathDisplay = styled.section`
    display: flex;
    align-items: center;
`;

const activeClassName = "active";

const NavLinkMain = styled(NavLink)`
    color: ${(props) => props.theme.text.color.second};
    font-weight: ${(props) => props.theme.font.tb["font-weight"]};
    font-family: ${(props) => props.theme.font.tb["font-family"]};
    text-decoration: none;
`;

const NavLinkMainDesktop = styled(NavLink).attrs({
    $activeClassName: activeClassName,
})`
    padding: 0.8rem 1rem;
    opacity: 0;
    animation: fadeIn 200ms ease forwards;

    &.${activeClassName} {
        background: ${(props) => props.theme.color.primary.light};
    }
`;

const NavLinkSecondary = styled(NavLink).attrs({
    $activeClassName: activeClassName,
})`
    color: ${(props) => props.theme.text.color.link};
    font-weight: ${(props) => props.theme.font.t["font-weight"]};
    font-family: ${(props) => props.theme.font.t["font-family"]};
    text-decoration: none;

    opacity: 0;
    animation: fadeIn 200ms ease forwards;

    &.${activeClassName} {
        font-weight: ${(props) => props.theme.font.tb["font-weight"]};
    }

    /* This is a workaround to avoid menu items jumping around.
    When a menu item is clicked, the text gets bold, which changes the element width.
    This makes sure the elements get the bold width from the start. */
    &:before {
        display: block;
        content: attr(data-title);
        font-weight: ${(props) => props.theme.font.tb["font-weight"]};
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
`;

const MenuButton = styled.button`
    background: none;
    padding: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    > * {
        margin-left: 0.3rem;
    }
`;

const HamburgerContainer = styled.div`
    position: relative;
    top: -0.1rem;
    left: -0.1rem;
`;

const HelpIconContainer = styled.span`
    display: inline-block;
    margin-right: 0.3rem;

    > svg {
        height: 1.1rem;
        position: relative;
        top: 0.2rem;
    }
`;
