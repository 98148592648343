import { updateData, url2data, fetchData, patchData, postData } from "../fetch";

const getOrders = async () => {
    return url2data(`core/task/orders/open`);
};

const getMechanics = async (customer_id) => {
    return fetchData(`core/task/mechanics/${customer_id}`);
};

const getTask = async (id) => {
    return fetchData(`core/task/${id}`);
};

const updateTask = async (data) => {
    return updateData(data, `core/task`);
};

const patchTask = async (data) => {
    const { id, ...newData } = data;
    return patchData(`core/task/${id}`, newData);
};

const getTasks = async (params) => {
    return fetchData(`core/task${params || ""}`);
};

const getRequisitions = async (params) => {
    return fetchData(`core/task/requisitions${params || ""}`);
};

const getTasksWithConnectedWorkshops = async (params) => {
    return fetchData(`core/task/with-connected-workshops${params || ""}`);
};

const getTasksWithCompletedPreOrPostCheck = async (params) => {
    return fetchData(`core/task/with-completed-pre-or-post-checks${params || ""}`);
};

const getPublicTasks = async (params) => {
    return fetchData(`core/task/public${params || ""}`);
};

const updateTaskPart = async (data) => {
    const { id, ...newData } = data;
    return patchData(`core/task/taskparts/${id}`, newData);
};

const patchTaskKittingBox = async (data) => {
    const { id, kitting_box_id } = data;
    return patchData(`core/task/${id}/kitting-box`, { kitting_box_id });
};

const postDistributeTasks = async (data) => {
    return postData("core/task/distribute-tasks", data);
};

export default {
    getOrders,
    getMechanics,
    getTask,
    updateTask,
    patchTask,
    getTasks,
    getTasksWithConnectedWorkshops,
    getTasksWithCompletedPreOrPostCheck,
    getPublicTasks,
    updateTaskPart,
    patchTaskKittingBox,
    getRequisitions,
    postDistributeTasks,
};

export const TaskStatus = {
    REQUEST: "request",
    DENIED: "denied",
    SCHEDULED_DRAFT: "scheduled_draft",
    SCHEDULED: "scheduled",
    STARTED: "started",
    COMPLETED: "completed",
    INVOICED: "invoiced",
};

export const TaskPartStatus = {
    REQUEST: "request",
    ORDERED: "ordered",
    ARRIVED: "arrived",
    PAID: "paid",
};
