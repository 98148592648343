import { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { cloneDeep } from "lodash";
import interact from "interactjs";
import { DateTime } from "luxon";
import styled, { useTheme } from "styled-components";

import { shouldTaskAppearInCalendar, convertMilitaryToNum } from "./helpers";
import { startOfWeek, getDate } from "../../common/date";
import { T } from "../../components/texts";
import { getLastUsedWorkshopId } from "../../app/localStorage";

import { useMechanics } from "../../components/application/hooks/useMechanics";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { useScheduledTasksBetween } from "../task/hooks/useScheduledTasksBetween";
import { useNotScheduledTasks } from "../task/hooks/useNotScheduledTasks";
import { usePatchTask } from "../task/mutations/usePatchTask";
import { useConfigForCustomer } from "../admin/setup/hooks/useConfigForCustomer";

import TaskEdit from "../task/TaskEdit";
import TasksList from "./TasksList";
import SchedulerMain from "./SchedulerMain";
import SchedulerHeader from "./SchedulerHeader";
import SchedulerNav from "./SchedulerNav";
import AbsenceEdit from "../absence/AbsenceEdit";
import { TaskStatus } from "../../api/core/taskAPI";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import AutomaticPlannerModal from "./AutomaticPlannerModal";
import { useSearchParams } from "../application/hooks/useSearchParams";

const Scheduler = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const workshopIdFromParams = Number(params?.workshopId);

    const { getParam, setParam, deleteParam } = useSearchParams();
    const taskQueryParam = getParam("task");
    const editTaskId = taskQueryParam
        ? taskQueryParam === "new"
            ? 0
            : Number(taskQueryParam)
        : null;

    const [isOffHoursExpanded, setIsOffHoursExpanded] = useState(false);
    const [fromDate, setFromDate] = useState(DateTime.local());
    const [editAbsenceId, setEditAbsenceId] = useState(null);
    const [editExtraId, setEditExtraId] = useState(null);
    const [currentlySelectedAbsenceIndex, setCurrentlySelectedAbsenceIndex] = useState(0);
    const [isAbsenceEditorOpen, setIsAbsenceEditorOpen] = useState(false);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);
    const [redraw, setRedraw] = useState(false);
    const [tasks, setTasks] = useState(null);
    const [mechanics, setMechanics] = useState(null);
    const [workshopId, setWorkshopId] = useState(null);
    const [isInAutoPlannerMode, setIsInAutoPlannerMode] = useState(false);
    const [isAutoPlannerReady, setIsAutoPlannerReady] = useState(false);
    const [isAutomaticPlannerModalOpen, setIsAutomaticPlannerModalOpen] = useState(false);
    const [autoPlannerSelectedTaskIds, setAutoPlannerSelectedTaskIds] = useState([]);
    const [autoPlannerPlannedTasks, setAutoPlannerPlannedTasks] = useState([]);
    const [autoPlannerSelectedMechanicIds, setAutoPlannerSelectedMechanicIds] = useState([]);

    const { mutate: patchTask } = usePatchTask();

    // Ref used to get fresh fromDate state in convertPositionToTime function
    const fromDateRef = useRef(fromDate);

    const hostMechanics = useMechanics(workshopIdFromParams);
    const customers = useCustomers();

    const notScheduledTasks = useNotScheduledTasks(workshopIdFromParams);
    const scheduledTasks = useScheduledTasksBetween(
        startOfWeek(fromDate).toISODate(),
        startOfWeek(fromDate).plus({ weeks: 1 }).toISODate(),
        workshopId
    );
    const customerConfig = useConfigForCustomer(workshopIdFromParams);
    const schedulerConfig = customerConfig?.data?.scheduler;

    const isLoadingData =
        scheduledTasks?.isLoading ||
        notScheduledTasks?.isLoading ||
        customerConfig?.isLoading ||
        hostMechanics?.isLoading ||
        customers?.isLoading;

    const daySchedule = useMemo(
        () => ({
            work_start: isOffHoursExpanded
                ? 0
                : convertMilitaryToNum(schedulerConfig?.workTime?.from),
            work_end: isOffHoursExpanded ? 24 : convertMilitaryToNum(schedulerConfig?.workTime?.to),
            break_start: convertMilitaryToNum(schedulerConfig?.lunchBreak?.from),
            break_end: convertMilitaryToNum(schedulerConfig?.lunchBreak?.to),
        }),
        [schedulerConfig, isOffHoursExpanded]
    );

    const hoursInDay = Math.ceil(daySchedule.work_end) - Math.floor(daySchedule.work_start);
    const hourWidth =
        (isOffHoursExpanded ? theme.scheduler.dayWidth : theme.scheduler.workingHoursWidth) /
        hoursInDay;

    useEffect(() => {
        if (workshopIdFromParams) {
            setWorkshopId(workshopIdFromParams);
        } else {
            tryRedirectingToLastUsedWorkshop();
        }
    }, [workshopIdFromParams]);

    async function tryRedirectingToLastUsedWorkshop() {
        const lastUsedWorkshopId = await getLastUsedWorkshopId();

        if (lastUsedWorkshopId) navigate(`/scheduler/calendar/${lastUsedWorkshopId}`);
    }

    // Redirect users who only have access to 1 workshop so that they don't have to use the selector
    useEffect(() => {
        if (customers?.data && customers.data.length === 1) {
            navigate(`/scheduler/calendar/${customers.data[0].id}`);
        }
    }, [customers?.data]);

    useEffect(() => {
        if (workshopId == null || scheduledTasks?.data == null || mechanics == null) return;

        const { tasksWithRows, mechanicsWithRows } = getStateWithRows(
            scheduledTasks.data
                .map((task) => ({
                    ...task,
                    isAutoPlanned: false,
                }))
                .concat(
                    (autoPlannerPlannedTasks ?? []).map((task) => ({
                        ...task,
                        isAutoPlanned: true,
                    }))
                ),
            mechanics
        );
        setTasks(tasksWithRows);
        setMechanics(mechanicsWithRows);
    }, [workshopId, scheduledTasks?.data, autoPlannerPlannedTasks]);

    useEffect(() => {
        setRedraw(false);
    }, [redraw]);

    useEffect(() => {
        if (mechanics && tasks && hoursInDay && notScheduledTasks?.data) setupDrag();
    }, [
        tasks,
        mechanics,
        isOffHoursExpanded,
        hoursInDay,
        notScheduledTasks?.data,
        isInAutoPlannerMode,
    ]);

    useEffect(() => {
        setIsInAutoPlannerMode(false);
    }, [workshopId]);

    function getStateWithRows(oldTasks, oldMechanics) {
        let allTasksWithRows = [];
        let allMechanicsWithRows = [];

        oldMechanics.forEach((mechanic) => {
            const tasksForMechanic = cloneDeep(oldTasks)
                .sort((a, b) => getDate(a.start)?.ts - getDate(b.start)?.ts)
                .filter((task) => task.mechanic_id === mechanic.id);

            const getHighestDisplayRow = (data) =>
                data.reduce(
                    (previous, current) =>
                        current.displayRow > previous ? current.displayRow : previous,
                    1
                );

            let tasksWithRows = [];

            for (let i = 0; i < tasksForMechanic.length; i++) {
                const task = tasksForMechanic[i];
                const otherTasksForMechanic = tasksWithRows?.filter((item) => item.id !== task.id);
                const collidingTasks = checkForCollidingTask(task, otherTasksForMechanic);

                const highestCollidingRow =
                    collidingTasks?.length && getHighestDisplayRow(collidingTasks);

                if (highestCollidingRow) {
                    tasksWithRows.push({ ...task, displayRow: highestCollidingRow + 1 });
                } else {
                    tasksWithRows.push({ ...task, displayRow: 1 });
                }
            }

            const highestRowForMechanic = getHighestDisplayRow(tasksWithRows);

            allTasksWithRows.push(...tasksWithRows);
            allMechanicsWithRows.push({ ...mechanic, rows: highestRowForMechanic });
        });

        return {
            tasksWithRows: allTasksWithRows,
            mechanicsWithRows: calculateMechanicPosition(allMechanicsWithRows),
        };
    }

    function updateFromDate(date) {
        fromDateRef.current = date;
        setFromDate(date);
    }

    function getInitialMechanics(hostMechanics) {
        return calculateMechanicPosition(
            (hostMechanics.data || []).map((mechanic) => ({
                ...mechanic,
                rows: 1,
            }))
        );
    }

    useEffect(() => {
        if (hostMechanics?.data) {
            setMechanics(getInitialMechanics(hostMechanics));
        }
    }, [hostMechanics?.data]);

    function calculateMechanicPosition(mechanicsArray) {
        let updatedMechanics = cloneDeep(mechanicsArray);
        let top = 0;

        updatedMechanics.forEach((mechanic) => {
            mechanic.top = top;
            top += theme.scheduler.cellHeight * mechanic.rows;
        });

        return updatedMechanics;
    }

    function getMechanicFromPos(yPosition) {
        let mechanic = null;

        mechanics.forEach((item) => {
            if (item.top <= yPosition) mechanic = item;
        });

        return mechanic;
    }

    function convertPositionToTime(xPosition) {
        const day = Math.floor(xPosition / theme.scheduler.dayWidth);

        const taskXPosWithinWorkingHours =
            xPosition -
            day * theme.scheduler.dayWidth -
            (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth);

        const minutes = Math.floor(
            (taskXPosWithinWorkingHours / hourWidth) * 60 + Math.floor(daySchedule.work_start) * 60
        );

        const minutesRounded = Math.round(minutes / 15) * 15;

        return startOfWeek(fromDateRef.current)
            .plus({ days: day, minutes: minutesRounded })
            .toISO();
    }

    function snapDuration(duration) {
        const snappedDuration = (Math.round((duration * 100) / 25) * 25) / 100;
        return snappedDuration >= 0.25 ? snappedDuration : 0.25;
    }

    function setupDrag() {
        // Sets up resizing
        interact(".schedule > .task > span > .task").resizable({
            enabled: !isInAutoPlannerMode,
            // Specify which corners of a task can be resized
            edges: { left: true, right: true, bottom: false, top: false },
            // Specify what happens when resizing tasks
            listeners: {
                move: handleTaskResizeMove,
                end: handleTaskResizeEnd,
            },
            modifiers: [
                interact.modifiers.restrictSize({
                    min: { width: theme.scheduler.dayWidth / (24 * 4) },
                }),
                interact.modifiers.snap({
                    targets: [
                        interact.snappers.grid({
                            x: theme.scheduler.dayWidth / (24 * 4),
                            y: 1,
                        }),
                    ],
                    range: Infinity,
                    relativePoints: [{ x: 0, y: 0 }],
                }),
            ],
        });

        // Sets up drag behavior for tasks
        interact(".task").draggable({
            autoScroll: true,
            enabled: !isInAutoPlannerMode,
            listeners: {
                start: handleTaskDragStart,
                move: handleTaskDragMove,
                end: handleTaskDragEnd,
            },
        });

        // Sets up the calendar dropzone with snapping etc.
        interact(".dropzone").dropzone({
            accept: ".task",
            overlap: 0.25,
            ondragenter: handleDropzoneDragEnter,
            ondragleave: handleDropzoneDragLeave,
            ondrop: handleDropzoneDrop,
        });
    }

    function handleDropzoneDragEnter(event) {
        const draggableElement = event.relatedTarget;
        const dropzoneElement = event.target;
        const dropzone = dropzoneElement.getAttribute("data-dropzone");

        switch (dropzone) {
            case "schedule":
                let id = parseInt(event.relatedTarget.getAttribute("data-id"));
                const allTasks = [...tasks, ...(notScheduledTasks?.data || [])];

                let task = allTasks.find((task) => task.id === id);

                draggableElement.style.width = `${
                    ((isOffHoursExpanded
                        ? theme.scheduler.dayWidth
                        : theme.scheduler.workingHoursWidth) /
                        hoursInDay) *
                    (task?.duration || 2)
                }px`;

                setRedraw(true);
                break;
            default:
                draggableElement.style.width = draggableElement.getAttribute("data-width");
        }

        draggableElement.setAttribute("data-dropzone", dropzone);
    }

    function handleDropzoneDragLeave(event) {
        const draggableElement = event.relatedTarget;

        draggableElement.classList.remove("hidden");
        draggableElement.style.removeProperty("width");
    }

    function handleDropzoneDrop(event) {
        const draggableElement = event.relatedTarget;
        const dropzoneElement = event.target;

        const dropzone = dropzoneElement.getAttribute("data-dropzone");
        const day = dropzoneElement.getAttribute("data-day");

        draggableElement.setAttribute("data-dropzone", dropzone);
        draggableElement.setAttribute("data-day", day);
        draggableElement.style.removeProperty("width");

        draggableElement.classList.remove("hidden");
    }

    function handleTaskDragStart(event) {
        const target = event.target;
        target.setAttribute("data-start-y", target.getBoundingClientRect().top);
        target.setAttribute("data-start-x", target.getBoundingClientRect().left);
        target.setAttribute("data-width", target.style.width);
        target.style.zIndex = 10;
    }

    function handleTaskDragMove(event) {
        const target = event.target;
        const scheduler = document.getElementById("scheduler")?.getBoundingClientRect();

        const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

        let translateX = x;
        let translateY = y;

        if (!scheduler) return;

        let dropzone = target.getAttribute("data-dropzone");

        if (dropzone === "schedule") {
            const xStep = theme.scheduler.dayWidth / (24 * 4);

            translateX = Math.round(x / xStep) * xStep;
            translateY = Math.round(y / theme.scheduler.cellHeight) * theme.scheduler.cellHeight;

            const deltaStartX = target.getAttribute("data-start-x") - scheduler.left;
            const deltaStartY = target.getAttribute("data-start-y") - scheduler.top;

            translateX += Math.round(translateX - deltaStartX) % Math.floor(xStep);
            translateY += Math.abs(Math.round(translateY - deltaStartY)) % 40;
        }

        // translate the element
        target.style.transform = `translate(${translateX}px, ${translateY}px)`;

        // update the posiion attributes
        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
        target.setAttribute(
            "data-schedulerX",
            target.getBoundingClientRect().left - scheduler.left
        );
        target.setAttribute("data-schedulerY", target.getBoundingClientRect().top - scheduler.top);

        target.style["border-left"] = "none";
        target.style["border-right"] = "none";
    }

    function handleTaskDragEnd(event) {
        const target = event.target;

        target.style.transform = "unset";
        target.removeAttribute("data-x");
        target.removeAttribute("data-y");
        target.style.removeProperty("border-left");
        target.style.removeProperty("border-right");

        let id = parseInt(target.getAttribute("data-id"));
        const dropzone = target.getAttribute("data-dropzone");

        const allTasks = [...tasks, ...(notScheduledTasks?.data || [])];
        const newTasks = cloneDeep(allTasks);
        let newTask = newTasks.find((task) => +task.id === id);
        const oldTask = allTasks.find((task) => +task.id === id);

        const newMechanics = cloneDeep(mechanics);

        switch (dropzone) {
            case "tasks":
                newTask.status = "request";
                break;
            case "schedule":
                newTask.status = shouldTaskAppearInCalendar(newTask.status)
                    ? newTask.status
                    : "scheduled_draft";

                // Prevent updating invoiced tasks
                if (newTask.status === TaskStatus.INVOICED) {
                    return throwWarningForInvoicedTask();
                }

                newTask.mechanic_id =
                    getMechanicFromPos(target.getAttribute("data-schedulerY"))?.id || 1;
                newTask.invoice_duration = newTask.invoice_duration || (newTask.duration ? 0 : 2);
                newTask.duration = newTask.duration || 2;
                newTask.start = convertPositionToTime(target.getAttribute("data-schedulerX"));
                newTask.end = getDate(newTask.start).plus({ hours: newTask.duration }).toISO();

                const newMechanic = newMechanics.find(
                    (mechanic) => mechanic.id === newTask.mechanic
                );

                const tasksForMechanic = cloneDeep(
                    newTasks.filter((item) => item.mechanic === newMechanic?.id)
                );

                const highestRowNumber = Math.max(
                    ...tasksForMechanic.map((item) => item.displayRow),
                    newTask.displayRow,
                    1
                );
                if (newMechanic) newMechanic.rows = highestRowNumber;

                const updatedTasks = [
                    ...tasks.filter((task) => !(task.id === newTask.id)),
                    newTask,
                ];

                const { mechanicsWithRows } = getStateWithRows(updatedTasks, newMechanics);

                setMechanics(mechanicsWithRows);

                const patchData = {
                    id: newTask.id,
                    status: newTask.status,
                    mechanic_id: newTask.mechanic_id,
                    invoice_duration: newTask.invoice_duration,
                    duration: newTask.duration,
                    start: newTask.start,
                    end: newTask.end,
                };

                if (newTask.status !== TaskStatus.INVOICED) {
                    patchData.invoice_total = calculateTaskInvoiceTotal(newTask);
                }

                patchTask(patchData);

                // Open task edit window when an unscheduled task is dragged into calendar
                if (!shouldTaskAppearInCalendar(oldTask.status)) openTaskEditorFor(newTask);

                break;
            default:
                break;
        }

        const updatedTasks = shouldTaskAppearInCalendar(newTask)
            ? [...tasks.filter((task) => !(task.id === newTask.id)), newTask]
            : tasks;

        const { tasksWithRows } = getStateWithRows(updatedTasks, newMechanics);

        setTasks(tasksWithRows);

        target.removeAttribute("data-dropzone");
        target.removeAttribute("data-day");
        target.removeAttribute("data-schedulerX");
        target.removeAttribute("data-schedulerY");
        target.removeAttribute("data-width");
        target.style.zIndex = "";
    }

    function throwWarningForInvoicedTask() {
        return toast.error(t("updating_invoiced_task_not_allowed"));
    }

    function calculateTaskInvoiceTotal(task) {
        let hourlyRate = task.customer?.price_0;
        if (task.overtime === 100) {
            hourlyRate = task.customer?.price_100;
        } else if (task.overtime === 50) {
            hourlyRate = task.customer?.price_50;
        }

        const hoursCost = (+task.duration || 0) * (hourlyRate || 0);

        const otherCosts =
            (+task.parts_cost || 0) +
                task.additional_expenses?.reduce((acc, value) => acc + value.cost, 0) || 0;
        return hoursCost + otherCosts;
    }

    function handleTaskDoubleClick(event) {
        const selectedTaskId = parseInt(event.currentTarget.getAttribute("data-id"));
        openTaskEditorFor({ id: selectedTaskId });
    }

    function handleTaskResizeMove(event) {
        const target = event.target;

        // Stop the resizing for tasks that have already been invoiced
        const taskId = parseInt(target.getAttribute("data-id"));
        const task = tasks?.find((task) => +task.id === taskId);
        if (task.status === TaskStatus.INVOICED) return;

        let x = parseFloat(target.getAttribute("data-x")) || 0;
        x += event.deltaRect.left;

        target.style.width = event.rect.width + "px";
        target.style["border-left"] = "none";
        target.style["border-right"] = "none";

        target.style.transform = `translate(${x}px, 0px)`;
        target.setAttribute("data-x", x);
    }

    function handleTaskResizeEnd(event) {
        const target = event.target;
        const scheduler = document.getElementById("scheduler")?.getBoundingClientRect();
        const id = parseInt(target.getAttribute("data-id"));

        const allTasks = [...tasks, ...(notScheduledTasks?.data || [])];
        const newTasks = cloneDeep(allTasks);
        const newTask = newTasks.find((task) => +task.id === id);

        // Prevent updating invoiced tasks
        if (newTask.status === TaskStatus.INVOICED) {
            return throwWarningForInvoicedTask();
        }

        const oldTask = allTasks.find((task) => +task.id === id);

        const newMechanics = cloneDeep(mechanics);
        const newMechanic = newMechanics.find((mechanic) => mechanic.id === newTask.mechanic);

        newTask.start = convertPositionToTime(target.getBoundingClientRect().left - scheduler.left);
        newTask.duration = snapDuration(event.rect.width / hourWidth);
        const durationChange = newTask.duration - oldTask.duration;
        const updated_invoice_duration = newTask.invoice_duration + durationChange;

        newTask.invoice_duration =
            !newTask.invoice_duration || newTask.invoice_duration === 0
                ? 0
                : updated_invoice_duration;

        newTask.end = getDate(newTask.start).plus({ hours: newTask.duration }).toISO();

        const tasksForMechanic = cloneDeep(
            newTasks.filter((item) => item.mechanic === newMechanic?.id)
        );
        const highestRowNumber = Math.max(
            ...tasksForMechanic.map((item) => item.displayRow),
            newTask.displayRow,
            1
        );
        if (newMechanic) newMechanic.rows = highestRowNumber;

        const patchData = {
            id: newTask.id,
            start: newTask.start,
            end: newTask.end,
            duration: newTask.duration,
            invoice_duration: newTask.invoice_duration,
        };

        if (newTask.status !== TaskStatus.INVOICED) {
            patchData.invoice_total = calculateTaskInvoiceTotal(newTask);
        }

        patchTask(patchData); // Patches task with updated data

        const updatedTasks = [...tasks.filter((task) => !(task.id === newTask.id)), newTask];

        const { tasksWithRows, mechanicsWithRows } = getStateWithRows(updatedTasks, newMechanics);

        setTasks(tasksWithRows);
        setMechanics(mechanicsWithRows);

        target.removeAttribute("data-x");
        target.removeAttribute("data-y");
        target.removeAttribute("data-width");

        target.style.transform = "unset";
        target.style.removeProperty("width");
        target.style.removeProperty("border-left");
        target.style.removeProperty("border-right");
    }

    function checkForCollidingTask(task, otherTasks) {
        if (otherTasks.length === 0) return false;

        const collidingTasks = otherTasks?.filter((otherTask) => {
            if (!otherTask.start || !otherTask.end) return false;

            const a = { start: getDate(task.start).ts, end: getDate(task.end).ts };
            const b = { start: getDate(otherTask.start).ts, end: getDate(otherTask.end).ts };

            return (
                a.displayRow === b.displayRow &&
                (a.start === b.start ||
                    a.end === b.end ||
                    (a.start >= b.start && a.start < b.end) ||
                    (a.end <= b.end && a.end > b.start) ||
                    (a.start <= b.start && a.end >= b.end))
            );
        });

        return collidingTasks;
    }

    function createNewTask() {
        setParam("task", "new");
    }

    function openTaskEditorFor({ id }) {
        setTaskEditorInitialData(null);
        setParam("task", `${id}`);
    }

    function closeTaskEditor(data = null) {
        setTaskEditorInitialData(data);
        deleteParam("task");
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setParam("task", "new"), 50);
    }

    function addNewAbsence() {
        setIsAbsenceEditorOpen(true);
    }

    function createPrefilledTask(data) {
        setTaskEditorInitialData(data);
        setParam("task", "new");
    }

    function editAbsence(absence) {
        if (!absence) return;
        setCurrentlySelectedAbsenceIndex(0);
        setEditAbsenceId(absence.id);
        setIsAbsenceEditorOpen(true);
    }

    function editExtra(extra) {
        if (!editExtra) return;
        setCurrentlySelectedAbsenceIndex(1);
        setEditExtraId(extra.id);
        setIsAbsenceEditorOpen(true);
    }

    return (
        <SchedulerPageStyled>
            <SchedulerNav
                createNewTask={createNewTask}
                addNewAbsence={addNewAbsence}
                mechanics={mechanics}
                customers={customers?.data}
            />

            {isLoadingData ? (
                <SpinnerContainer>
                    <LoadingSpinner light />
                </SpinnerContainer>
            ) : tasks && mechanics?.length > 0 ? (
                <>
                    <TasksList
                        workshopId={workshopId}
                        handleTaskDoubleClick={handleTaskDoubleClick}
                        isInAutoPlannerMode={isInAutoPlannerMode}
                        onTaskCheckboxUpdate={(taskId, checkboxValue) => {
                            if (checkboxValue) {
                                setAutoPlannerSelectedTaskIds(
                                    Array.from(new Set([...autoPlannerSelectedTaskIds, taskId]))
                                );
                            } else {
                                setAutoPlannerSelectedTaskIds(
                                    [...autoPlannerSelectedTaskIds].filter(
                                        (selectedTaskId) => selectedTaskId !== taskId
                                    )
                                );
                            }
                        }}
                        autoPlannerSelectedTaskIds={autoPlannerSelectedTaskIds}
                        autoPlannerPlannedTasks={autoPlannerPlannedTasks}
                        setAutoPlannerSelectedTaskIds={setAutoPlannerSelectedTaskIds}
                    />

                    <SchedulerHeader
                        isInAutoPlannerMode={isInAutoPlannerMode}
                        isAutoPlannerReady={isAutoPlannerReady}
                        setIsInAutoPlannerMode={(value) => {
                            if (value === true) {
                                setAutoPlannerSelectedTaskIds([]);
                                setAutoPlannerSelectedMechanicIds(
                                    mechanics == null ? [] : mechanics.map(({ id }) => id)
                                );
                            } else {
                                setAutoPlannerPlannedTasks([]);
                                setIsAutoPlannerReady(false);
                            }
                            setIsInAutoPlannerMode(value);
                        }}
                        autoPlannerSelectedTaskIds={autoPlannerSelectedTaskIds}
                        runAutomaticScheduler={() => setIsAutomaticPlannerModalOpen(true)}
                        completeAutomaticScheduler={() => {
                            Promise.all(
                                autoPlannerPlannedTasks.map(
                                    ({
                                        id,
                                        mechanic_id,
                                        start,
                                        end,
                                        status,
                                        status_date,
                                        status_by_id,
                                    }) =>
                                        patchTask({
                                            id,
                                            mechanic_id,
                                            start,
                                            end,
                                            status,
                                            status_date,
                                            status_by_id,
                                        })
                                )
                            ).then(() => {
                                setAutoPlannerPlannedTasks([]);
                                setIsAutoPlannerReady(false);
                                setIsInAutoPlannerMode(false);
                            });
                        }}
                        from_date={fromDate}
                        setDate={updateFromDate}
                    />

                    {!scheduledTasks.isLoading && !hostMechanics.isLoading ? (
                        <SchedulerMain
                            key={`${workshopIdFromParams}${fromDate}`}
                            tasks={tasks}
                            from_date={fromDate}
                            mechanics={mechanics}
                            createPrefilledTask={createPrefilledTask}
                            daySchedule={daySchedule}
                            isOffHoursExpanded={isOffHoursExpanded}
                            setIsOffHoursExpanded={setIsOffHoursExpanded}
                            editAbsence={editAbsence}
                            editExtra={editExtra}
                            handleTaskDoubleClick={handleTaskDoubleClick}
                            isInAutoPlannerMode={isInAutoPlannerMode}
                            onTaskCheckboxUpdate={(taskId, checkboxValue) => {
                                if (checkboxValue) {
                                    setAutoPlannerSelectedTaskIds(
                                        Array.from(new Set([...autoPlannerSelectedTaskIds, taskId]))
                                    );
                                } else {
                                    setAutoPlannerSelectedTaskIds(
                                        [...autoPlannerSelectedTaskIds].filter(
                                            (selectedTaskId) => selectedTaskId !== taskId
                                        )
                                    );
                                }
                            }}
                            autoPlannerSelectedTaskIds={autoPlannerSelectedTaskIds}
                            isCheckboxDisabled={autoPlannerPlannedTasks.length > 0}
                            autoPlannerSelectedMechanicIds={autoPlannerSelectedMechanicIds}
                            onMechanicCheckboxUpdate={(mechanicId, checkboxValue) => {
                                if (checkboxValue) {
                                    setAutoPlannerSelectedMechanicIds(
                                        Array.from(
                                            new Set([...autoPlannerSelectedMechanicIds, mechanicId])
                                        )
                                    );
                                } else {
                                    setAutoPlannerSelectedMechanicIds(
                                        [...autoPlannerSelectedMechanicIds].filter(
                                            (selectedMechanicId) =>
                                                selectedMechanicId !== mechanicId
                                        )
                                    );
                                }
                            }}
                        />
                    ) : null}

                    {isAutomaticPlannerModalOpen ? (
                        <AutomaticPlannerModal
                            isOpen={isAutomaticPlannerModalOpen}
                            onClose={() => setIsAutomaticPlannerModalOpen(false)}
                            tasks={autoPlannerSelectedTaskIds}
                            mechanics={autoPlannerSelectedMechanicIds}
                            onDistributionResponse={({ data }) => {
                                setAutoPlannerPlannedTasks(data);
                                setIsAutomaticPlannerModalOpen(false);
                                setIsAutoPlannerReady(true);
                            }}
                            host_id={workshopIdFromParams}
                        />
                    ) : null}

                    {editTaskId != null ? (
                        <TaskEdit
                            editTaskId={editTaskId ? editTaskId : 0}
                            isOpen={true}
                            onClose={closeTaskEditor}
                            workshopId={workshopId}
                            initialData={taskEditorInitialData}
                            resetTaskEditor={resetTaskEditor}
                        />
                    ) : null}

                    <AbsenceEdit
                        isOpen={isAbsenceEditorOpen}
                        onClose={() => {
                            setIsAbsenceEditorOpen(false);
                            setEditAbsenceId(null);
                            setEditExtraId(null);
                            setCurrentlySelectedAbsenceIndex(0);
                        }}
                        workshopId={workshopId}
                        absenceId={editAbsenceId}
                        extraId={editExtraId}
                        currentlySelectedIndex={currentlySelectedAbsenceIndex}
                        setCurrentlySelectedIndex={setCurrentlySelectedAbsenceIndex}
                    />
                </>
            ) : null}

            {workshopId && mechanics?.length === 0 && (
                <NoMechanicsStyled>{t("customer_has_no_registered_mechanics")}</NoMechanicsStyled>
            )}
        </SchedulerPageStyled>
    );
};

export default Scheduler;

const SchedulerPageStyled = styled.div`
    margin-top: 2rem;
    margin-left: 3.125rem;
    margin-right: 3.125rem;

    .hidden {
        visibility: hidden;
    }

    opacity: 0;
    animation: fadeIn 300ms ease forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const NoMechanicsStyled = styled(T)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    animation: fadeIn 400ms ease forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const SpinnerContainer = styled.article`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 300ms 500ms ease forwards;
`;
