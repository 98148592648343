import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useInspectionConfigsForCustomer } from "./hooks/useInspectionConfigsForCustomer";
import { useInspectionConfigTemplates } from "./hooks/useInspectionConfigTemplates";
import { useInspectionConfig } from "./hooks/useInspectionConfig";
import { useSaveInspectionConfig } from "./hooks/useSaveInspectionConfig";
import { useDeleteInspectionConfig } from "./hooks/useDeleteInspectionConfig";
import { useInspectionCategoriesForCustomer } from "./hooks/useInspectionCategoriesForCustomer";
import { useInspectionCategoriesTemplates } from "./hooks/useInspectionCategoriesTemplates";

import { Input, InputWithLabel, SelectForm } from "../../../components/inputs";
import { CleanButton } from "../../../components/buttons";
import { TB, T } from "../../../components/texts";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import ConfirmModal from "../../../components/application/ConfirmModal";
import InspectionConfigCategory from "./InspectionConfigCategory";

const InspectionConfigSetup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { customerId, configId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm();

    const categories = useInspectionCategoriesForCustomer(customerId);
    const templateCategories = useInspectionCategoriesTemplates();
    const configs = useInspectionConfigsForCustomer(customerId);
    const configTemplates = useInspectionConfigTemplates();
    const selectedConfig = useInspectionConfig(+configId);

    const allCategories = useMemo(() => {
        if (!categories?.data || !templateCategories?.data) return null;
        const categoriesSorted = [...categories.data].sort((a, b) => a.order - b.order);
        const templateCategoriesSorted = [...templateCategories.data].sort(
            (a, b) => a.order - b.order
        );

        const allCategories =
            customerId === 1 ? categoriesSorted : categoriesSorted.concat(templateCategoriesSorted);

        return allCategories;
    }, [categories?.data, templateCategories?.data]);

    // Set up config options for copying checkpoints from other configs
    const templateOptions = useMemo(() => {
        if (!configs?.data || !configTemplates?.data) return [];

        const allConfigs =
            customerId === 1 ? configs.data : [...configTemplates.data, ...configs.data];

        return allConfigs
            .filter((config) => config.id !== +configId)
            ?.map((config) => ({
                label:
                    customerId === 1 || config.customer_id !== 1
                        ? config.name
                        : `${config.name} (systemmal)`,

                value: config.id,
            }));
    }, [configs?.data, configTemplates?.data]);

    const { mutate: saveConfig, isLoading: isConfigSaving } = useSaveInspectionConfig();
    const { mutate: deleteConfig, isLoading: isDeletingConfig } = useDeleteInspectionConfig();

    useEffect(() => {
        if (selectedConfig?.data) {
            const data = selectedConfig.data;

            methods.reset({
                name: data.name,
                checkpoints: data.checkpoints?.map((item) => String(item)),
                workhours: data.workhours,
            });
        }
    }, [selectedConfig?.data]);

    function handleConfigTemplateChange(configId) {
        const selectedTemplate =
            configs?.data?.find((item) => item.id === configId) ||
            configTemplates?.data?.find((item) => item.id === configId);

        if (selectedTemplate?.checkpoints) {
            methods.setValue(
                "checkpoints",
                selectedTemplate.checkpoints.map((item) => String(item)),
                { shouldDirty: true }
            );
        }
    }

    function onSubmit(data) {
        const post = {
            name: data.name,
            periodic_interval: 365,
        };

        if (selectedConfig?.data?.id) {
            post.id = selectedConfig.data.id;
        } else {
            post.customer_id = customerId;
        }
        post.checkpoints = Array.isArray(data.checkpoints)
            ? data.checkpoints?.map((item) => +item)
            : [];
        post.workhours = +data.workhours || null;

        saveConfig(post, {
            onSuccess: () => navigate(`/administration/setup/inspection/${customerId}`),
        });
    }

    function askToRemoveConfig() {
        setIsModalOpen(true);
    }

    function removeConfig() {
        deleteConfig(selectedConfig?.data?.id, {
            onSuccess: () => {
                closeModal();
                navigate("/administration/setup/inspection");
            },
        });
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    return (
        <>
            <QueryWrapper data={+configId ? selectedConfig : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <InputsContainer>
                            <NameInput
                                label={t("title_of_config")}
                                name="name"
                                validate={{ required: t("name_required") }}
                            />

                            <InputWithUnitContainer>
                                <label htmlFor="workhours">
                                    <T>{t("estimated_duration")}</T>
                                </label>

                                <InputWithUnit>
                                    <Input
                                        {...methods.register("workhours", {
                                            validate: (value) =>
                                                !isNaN(value) || t("has_to_be_number"),
                                        })}
                                    />
                                    <span>{t("hours")}</span>
                                </InputWithUnit>
                            </InputWithUnitContainer>

                            <SelectContainer>
                                <SelectForm
                                    name="inspection-template"
                                    label={`${t("get_from")}:`}
                                    options={templateOptions}
                                    onChange={(option) => handleConfigTemplateChange(option.value)}
                                />
                            </SelectContainer>

                            {selectedConfig?.data?.id ? (
                                <DeleteButtonContainer>
                                    <CleanButton type="button" onClick={askToRemoveConfig}>
                                        <TB $link $underline>
                                            {t("remove_config")}
                                        </TB>
                                    </CleanButton>
                                </DeleteButtonContainer>
                            ) : null}
                        </InputsContainer>

                        <QueryWrapper data={[categories, templateCategories]}>
                            {allCategories?.map((category, index) => (
                                <InspectionConfigCategory
                                    key={`${category.id}_${index}`}
                                    categoryData={category}
                                    customerId={customerId}
                                />
                            ))}
                        </QueryWrapper>

                        <SaveOrAbort saving={isConfigSaving} />
                    </form>
                </FormProvider>
            </QueryWrapper>

            {isModalOpen && (
                <ConfirmModal
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    onConfirm={removeConfig}
                    text={t("sure_you_want_to_remove_config")}
                    isLoading={isDeletingConfig}
                />
            )}
        </>
    );
};

export default InspectionConfigSetup;

const InputsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;

    > *:last-child {
        margin-bottom: 3rem;
    }
`;

const NameInput = styled(InputWithLabel)`
    min-width: 20rem;
    margin-right: 1rem;
`;

const InputWithUnitContainer = styled.section`
    margin-right: 5rem;
`;

const SelectContainer = styled.section`
    min-width: 22rem;
    margin-right: 2rem;
`;

const DeleteButtonContainer = styled.section`
    padding-top: 2rem;
`;

const InputWithUnit = styled.section`
    display: flex;
    align-items: center;

    input {
        min-width: 5rem;
        max-width: 7rem;
        margin-right: 0.5rem;
        text-align: right;
        padding-right: 0.5rem;
    }
`;
