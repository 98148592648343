import { updateData, url2data, fetchData, patchData } from "../fetch";

const getUnit = async (id, include_pre_check_list = false) =>
    fetchData(`core/unit/${id}${include_pre_check_list ? "?include_pre_check_list" : ""}`);
const getUnitByUuid = async (uuid, params = "") =>
    fetchData(`core/unit/by-uuid/${uuid}/public${params}`);
const getUnitsOverview = async (params = "") => fetchData(`core/unit/overview${params}`);
const getUnitsDashboard = async (params = "") => fetchData(`core/unit/dashboard${params}`);
const getAll = async () => fetchData(`core/unit/all`);
const getInUse = async () => fetchData(`core/unit/inuse`);
const getLastUsed = async () => fetchData(`core/unit/last`);
const getStart = async (id) => fetchData(`core/unit/${id}/start`);
const getTake = async (id) => fetchData(`core/unit/${id}/take`);
const updateUnit = async (data) => updateData(data, "core/unit");
const patchUnit = async ({ id, ...data }) => patchData(`core/unit/${id}`, data);
const getUnitLastPreCheck = async (id) => fetchData(`core/unit/${id}/last-pre-check`);

const getOpenComments = async () => fetchData(`core/unit/comments`);
const getUnitComment = async (id) => fetchData(`core/unit/comment/${id}`);
const getUnitComments = async (id) => fetchData(`core/unit/${id}/comments`);
const postUnitCommentTentative = async (id, tentative_fix) =>
    url2data(`core/unit/comment/${id}/tentative`, { tentative_fix });
const getAcknowledgeComment = async (id) => url2data(`core/unit/${id}/comment/acknowledge`);

const getUnitSearch = async (search, permission = null, include_pre_check_list = false) =>
    url2data(`core/unit/search${include_pre_check_list ? "?include_pre_check_list" : ""}`, {
        search,
        permission,
    });
const postUnitFinished = async (id, comment, statusId) =>
    url2data(`core/unit/${id}/finish`, { comment, statusId });

const getUnitGroup = async (id) => fetchData(`core/unit/group/${id}`);
const getUnitGroups = async () => fetchData(`core/unit/groups`);
const updateGroup = async (data) => updateData(data, "core/unit/group");

const getArea = async (id) => fetchData(`core/unit/area/${id}`);
const getAreas = async (includeUnits) =>
    fetchData(`core/unit/area${includeUnits ? "?include_units=true" : ""}`);
const getAreasPublic = async () => fetchData(`core/unit/area/public`);
const updateArea = async (data) => updateData(data, "core/unit/area");

const getUnitPositionLast = async (unitId) => fetchData(`core/unit/${unitId}/position`);
const getUnitSensorDataFromTo = async ({ unitId, from, to }) =>
    fetchData(`core/unit/${unitId}/sensordata?from=${from}&to=${to}`);
const getUnitSensorDataFirstAfter = async ({ unitId, timestamp }) =>
    fetchData(`core/unit/${unitId}/sensordata/first?timestamp=${timestamp}`);
const getUnitSensorDataLastBefore = async ({ unitId, timestamp }) =>
    fetchData(`core/unit/${unitId}/sensordata/last?before=${timestamp}`);
const getUnitSensorDataLast = async ({ unitId }) =>
    fetchData(`core/unit/${unitId}/sensordata/last`);

const patchUnitHourCounter = async ({ unitId, hour_counter }) =>
    patchData(`core/unit/${unitId}`, { hour_counter });
const getUnitDefectHistories = async (params) =>
    fetchData(`core/unit/defect-history${params || ""}`);

export default {
    getUnit,
    getUnitByUuid,
    getAll,
    getUnitsOverview,
    getUnitsDashboard,
    getInUse,
    getLastUsed,
    getStart,
    getTake,
    updateUnit,
    patchUnit,
    getOpenComments,
    getUnitComment,
    getUnitComments,
    postUnitCommentTentative,
    getUnitSearch,
    postUnitFinished,
    getAcknowledgeComment,
    getUnitGroup,
    getUnitGroups,
    updateGroup,
    getArea,
    getAreas,
    getAreasPublic,
    updateArea,
    getUnitPositionLast,
    getUnitSensorDataFromTo,
    getUnitSensorDataFirstAfter,
    getUnitSensorDataLastBefore,
    getUnitSensorDataLast,
    patchUnitHourCounter,
    getUnitLastPreCheck,
    getUnitDefectHistories,
};
