import { useState, useEffect, useMemo, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { isSameDay } from "date-fns";

import { useAbsencesBetween } from "../absence/hooks/useAbsencesBetween";
import { useExtrasBetween } from "../absence/hooks/useExtrasBetween";

import {
    isInCurrentWeek,
    getDate,
    startOfWeek,
    weekDays,
    startOfDay,
    hasSameDay,
    endOfDay,
    hasSameWeek,
} from "../../common/date";
import { calculateCurrentTimePosition } from "./helpers";
import { TB, T } from "../../components/texts";
import { TaskStatus } from "../../api/core/taskAPI";
import { CleanButton } from "../../components/buttons";
import Task from "./Task";
import Absence from "./Absence";
import Extra from "./Extra";

const CalendarCells = ({
    from_date,
    tasks,
    mechanics,
    createPrefilledTask,
    daySchedule,
    isOffHoursExpanded,
    setIsOffHoursExpanded,
    editAbsence,
    editExtra,
    handleTaskDoubleClick,
    nationalHolidays,
    isInAutoPlannerMode,
    autoPlannerSelectedTaskIds,
    onTaskCheckboxUpdate,
    isCheckboxDisabled,
}) => {
    const [timeIndicatorPositionX, setTimeIndicatorPositionX] = useState(0);
    const [timeIndicatorVisible, setTimeIndicatorVisible] = useState(false);
    const [hoursArray, setHoursArray] = useState(null);
    const schedulerRef = useRef(null);

    const dragStartMouseX = useRef(null);
    const dragStartMouseY = useRef(null);

    const theme = useTheme();
    const { t } = useTranslation();
    const params = useParams();
    const workshopId = Number(params?.workshopId);

    const dayStart = Math.floor(+daySchedule.work_start);
    const dayEnd = Math.ceil(+daySchedule.work_end);
    const hoursInDay = dayEnd - dayStart;

    const { data: absences } = useAbsencesBetween({
        fromDate: startOfWeek(from_date).toISODate(),
        toDate: startOfWeek(from_date).plus({ weeks: 1 }).toISODate(),
        customerId: workshopId,
    });

    const { data: extras } = useExtrasBetween({
        fromDate: startOfWeek(from_date).toISODate(),
        toDate: startOfWeek(from_date).plus({ weeks: 1 }).toISODate(),
        customerId: workshopId,
    });

    useEffect(() => {
        const generatedHours = [...Array(hoursInDay)].map((_, index) => dayStart + index);
        setHoursArray(generatedHours);
    }, [daySchedule]);

    // Update time indicator position with time
    useEffect(() => {
        if (from_date && isInCurrentWeek(from_date)) {
            updateTime();
        } else {
            setTimeIndicatorVisible(false);
        }

        function updateTime() {
            const xPositionForCurrentTime = calculateCurrentTimePosition({
                date: from_date,
                dayWidth: theme.scheduler.dayWidth,
                nonWorkingHoursWidth: theme.scheduler.nonWorkingHoursWidth,
                daySchedule: daySchedule,
                isOffHoursExpanded: isOffHoursExpanded,
            });

            const currentTimeMs = new Date().getTime();
            const workDayStartMs = new Date().setHours(dayStart, 0, 0, 0);
            const workDayEndMs = new Date().setHours(dayEnd, 0, 0, 0);

            // Hide current time indicator if its outside of working hours
            if (
                isOffHoursExpanded ||
                (!isOffHoursExpanded &&
                    currentTimeMs > workDayStartMs &&
                    currentTimeMs < workDayEndMs)
            ) {
                setTimeIndicatorVisible(true);
            } else {
                setTimeIndicatorVisible(false);
            }

            setTimeIndicatorPositionX(xPositionForCurrentTime);
        }

        const timeUpdater = window.setInterval(() => {
            updateTime();
        }, 1000 * 60);
        return () => window.clearInterval(timeUpdater);
    }, [isOffHoursExpanded, daySchedule, from_date]);

    function resetHoveredElementStyles() {
        const elements = document.querySelectorAll(
            ".markAsHovered, .firstHalfOnly, .secondHalfOnly"
        );

        if (elements && elements.length > 0) {
            elements.forEach((element) =>
                element.classList.remove("markAsHovered", "firstHalfOnly", "secondHalfOnly")
            );
        }
    }

    function handleCalendarMouseMove(event) {
        const isMouseButtonDown = event.buttons === 1;

        if (!dragStartMouseX.current || !dragStartMouseY.current || !isMouseButtonDown) return;

        const currentMousePos = event.clientX;
        const dragStartMousePos = dragStartMouseX.current;
        const leftDragPos =
            currentMousePos > dragStartMousePos ? dragStartMousePos : currentMousePos;
        const rightDragPos =
            currentMousePos > dragStartMousePos ? currentMousePos : dragStartMousePos;

        let allSelectedCells = [];

        for (let i = leftDragPos; i <= rightDragPos; i++) {
            const cellForPosition = document.elementFromPoint(i, dragStartMouseY.current);
            if (!allSelectedCells.includes(cellForPosition)) {
                allSelectedCells.push(cellForPosition);
            }
        }

        resetHoveredElementStyles();

        allSelectedCells.forEach((cell, index) => {
            const isFirstCell = index === 0;
            const isLastCell = index === allSelectedCells.length - 1;

            if (isFirstCell) {
                const selectedHalf = getSelectedCellHalfForMouseX(cell, leftDragPos);

                if (selectedHalf === 2) {
                    cell.classList.add("secondHalfOnly");
                }
            } else if (isLastCell) {
                const selectedHalf = getSelectedCellHalfForMouseX(cell, rightDragPos);

                if (selectedHalf === 1) {
                    cell.classList.add("firstHalfOnly");
                }
            }
            cell.classList.add("markAsHovered");
        });
    }

    function handleCalendarMouseUp(event) {
        if (dragStartMouseX.current && dragStartMouseY.current) {
            const dragFinishMouseX = event.clientX;

            const firstSelectedCell = document.elementFromPoint(
                dragStartMouseX.current,
                dragStartMouseY.current
            );

            const lastSelectedCell = document.elementFromPoint(
                dragFinishMouseX,
                dragStartMouseY.current
            );

            if (
                isCellClickable(firstSelectedCell) &&
                isCellClickable(lastSelectedCell) &&
                isSameDay(
                    new Date(firstSelectedCell.getAttribute("data-date")),
                    new Date(lastSelectedCell.getAttribute("data-date"))
                )
            ) {
                const firstCellHalf = getSelectedCellHalfForMouseX(
                    firstSelectedCell,
                    dragStartMouseX.current
                );
                const lastCellHalf = getSelectedCellHalfForMouseX(
                    lastSelectedCell,
                    dragFinishMouseX
                );

                createNewTaskForCells(
                    firstSelectedCell,
                    lastSelectedCell,
                    firstCellHalf,
                    lastCellHalf
                );
            }
        }

        dragStartMouseX.current = null;
        dragStartMouseY.current = null;
        resetHoveredElementStyles();
    }

    function isCellClickable(cell) {
        return cell.classList.contains("clickable");
    }

    function getSelectedCellHalfForMouseX(cell, mouseX) {
        const cellRect = cell?.getBoundingClientRect();
        const cellMidPoint = cellRect?.left + cellRect?.width / 2;
        const selectedHalf = mouseX < cellMidPoint ? 1 : 2;

        return selectedHalf;
    }

    function createNewTaskForCells(startTarget, endTarget, startTargetHalf, endTargetHalf) {
        if (!startTarget || !endTarget || !startTargetHalf || !endTargetHalf) return;

        const startTargetDate = new Date(startTarget.getAttribute("data-date"));
        const endTargetDate = new Date(endTarget.getAttribute("data-date"));

        let startDate;
        let duration;

        if (startTargetDate < endTargetDate) {
            startDate = new Date(startTargetDate.setMinutes(startTargetHalf === 2 ? 30 : 0));
            const endDate = endTargetDate;
            duration =
                endDate.getHours() -
                (endTargetHalf === 1 ? 0.5 : 0) -
                (startTargetHalf === 2 ? 0.5 : 0) -
                startDate.getHours() +
                1;
        } else if (endTargetDate < startTargetDate) {
            startDate = new Date(endTargetDate.setMinutes(endTargetHalf === 2 ? 30 : 0));
            const endDate = startTargetDate;
            duration =
                endDate.getHours() -
                (endTargetHalf === 2 ? 0.5 : 0) -
                (startTargetHalf === 1 ? 0.5 : 0) -
                startDate.getHours() +
                1;
        } else if (startTargetDate.getTime() === endTargetDate.getTime()) {
            const startHalf = startTargetHalf <= endTargetHalf ? startTargetHalf : endTargetHalf;
            startDate = new Date(startTargetDate.setMinutes(startHalf === 2 ? 30 : 0));
            duration = startTargetHalf === endTargetHalf ? 0.5 : 1;
        }

        const mechanicId = startTarget?.getAttribute("data-mechanicid");

        createPrefilledTask({
            start: startDate,
            duration,
            mechanic_id: +mechanicId,
            status: {
                value: TaskStatus.SCHEDULED_DRAFT,
                label: t(`status-${TaskStatus.SCHEDULED_DRAFT}`),
            },
        });
    }

    function isTaskInsideWorkHours(task) {
        if (isOffHoursExpanded) {
            return true;
        } else {
            const taskStartHour = getDate(task.start).hour;
            const taskEndHour = getDate(task.end).hour;

            const startsBeforeDay = taskStartHour < daySchedule.work_start;
            const startsAfterDay = taskStartHour >= daySchedule.work_end;
            const endsBeforeDay = taskEndHour < daySchedule.work_start;

            const isOutsideWorkingHours = (startsBeforeDay && endsBeforeDay) || startsAfterDay;

            if (isOutsideWorkingHours) {
                return false;
            } else {
                return true;
            }
        }
    }

    // Generate the individual hours table headers
    const WeekDaysHeader = useMemo(() => {
        return weekDays(from_date).map((day, index) => {
            return (
                <div
                    className={"day"}
                    key={`day-header-${day}-${index}`}
                    style={{ left: `${index * theme.scheduler.dayWidth}px` }}
                >
                    {!isOffHoursExpanded ? (
                        <BeforeHoursCell onClick={() => setIsOffHoursExpanded(true)}>
                            <TB>
                                {typeof daySchedule?.work_start === "number"
                                    ? `00 – ${daySchedule.work_start < 10 ? 0 : ""}${Math.floor(
                                          daySchedule.work_start - 1
                                      )}`
                                    : ""}
                            </TB>
                        </BeforeHoursCell>
                    ) : null}

                    {hoursArray &&
                        hoursArray.map((hour, index) => {
                            return (
                                <HourCellStyled
                                    key={`day-cell-${day}-${hour}-${index}`}
                                    className={"hour"}
                                    data-hour={hour}
                                    $breakStart={daySchedule?.break_start}
                                    $breakEnd={daySchedule?.break_end}
                                    $amountOfWorkingHours={hoursArray.length}
                                    $isOffHoursExpanded={isOffHoursExpanded}
                                    $isOffDay={nationalHolidays?.some((holiday) =>
                                        hasSameDay(getDate(holiday?.date), getDate(day))
                                    )}
                                >
                                    <HourTextStyled>
                                        {hour <= 9 && "0"}
                                        {hour}
                                    </HourTextStyled>
                                </HourCellStyled>
                            );
                        })}
                    {!isOffHoursExpanded ? (
                        <AfterHoursCell onClick={() => setIsOffHoursExpanded(true)}>
                            <TB>
                                {typeof daySchedule?.work_end === "number"
                                    ? `${daySchedule.work_end <= 9 ? 0 : ""}${Math.floor(
                                          daySchedule.work_end
                                      )} – 23`
                                    : ""}
                            </TB>
                        </AfterHoursCell>
                    ) : null}
                </div>
            );
        });
    }, [tasks, from_date, hoursArray, nationalHolidays]);

    // Generates all the divs that make up the calendar table
    const ScheduleArea = useMemo(
        () =>
            mechanics.map((mechanic) =>
                [...Array(mechanic.rows)].map((_row, rowIndex) => (
                    <div key={mechanic.id + rowIndex} className={"mechanic"}>
                        {weekDays(from_date).map((day, dayIndex) => {
                            const hasTaskOutsideView =
                                tasks
                                    .filter((task) => task.mechanic_id === mechanic.id)
                                    ?.filter((task) => hasSameDay(getDate(task.start), day))
                                    ?.filter((task) => {
                                        const taskStartDate = getDate(task.start);
                                        const taskEndDate = getDate(task.end);

                                        const startsBeforeDay =
                                            taskStartDate.hour < daySchedule.work_start;

                                        const endsAfterDay =
                                            taskEndDate.hour > daySchedule.work_end ||
                                            (taskEndDate.hour === daySchedule.work_end &&
                                                taskEndDate.minute > 0);

                                        return (
                                            !isTaskInsideWorkHours(task) ||
                                            startsBeforeDay ||
                                            endsAfterDay
                                        );
                                    })?.length > 0;

                            function handleDayMouseDown(event) {
                                const cellUnderMouse = document.elementFromPoint(
                                    event.clientX,
                                    event.clientY
                                );

                                if (!cellUnderMouse.classList.contains("clickable")) return;

                                dragStartMouseX.current = event.clientX;
                                dragStartMouseY.current = event.clientY;

                                cellUnderMouse.classList.remove("displayPlusButton");
                            }

                            function handleCellMouseOver(event) {
                                const isMouseButtonDown = event.buttons === 1;

                                if (!isMouseButtonDown) {
                                    event.target.classList.add("displayPlusButton");
                                }
                            }

                            function handleCellMouseLeave(event) {
                                event.target.classList.remove("displayPlusButton");
                            }

                            return (
                                <div
                                    className={"day"}
                                    key={`calendar-day-${day}-${dayIndex}-${mechanic.id}`}
                                    style={{
                                        left: `${dayIndex * theme.scheduler.dayWidth}px`,
                                        height: `${theme.scheduler.cellHeight}px`,
                                    }}
                                    onMouseDown={handleDayMouseDown}
                                >
                                    {!isOffHoursExpanded ? (
                                        <BeforeHoursCell
                                            onClick={() => setIsOffHoursExpanded(true)}
                                        >
                                            {rowIndex === 0 && hasTaskOutsideView ? "..." : null}
                                        </BeforeHoursCell>
                                    ) : null}

                                    {hoursArray
                                        ? hoursArray.map((hour, hourIndex) => {
                                              const date = day.plus({ hours: hour }).toISO();
                                              return (
                                                  <HourCellStyled
                                                      key={`calendar-cell-${day}-${hour}-${hourIndex}`}
                                                      className={"hour clickable"}
                                                      data-hour={hour}
                                                      data-date={date}
                                                      data-mechanicid={mechanic.id}
                                                      $breakStart={daySchedule?.break_start}
                                                      $breakEnd={daySchedule?.break_end}
                                                      $amountOfWorkingHours={hoursArray.length}
                                                      $isOffHoursExpanded={isOffHoursExpanded}
                                                      onMouseOver={handleCellMouseOver}
                                                      onMouseOut={handleCellMouseLeave}
                                                      $isOffDay={nationalHolidays?.some((holiday) =>
                                                          hasSameDay(
                                                              getDate(holiday?.date),
                                                              getDate(day)
                                                          )
                                                      )}
                                                  >
                                                      <PlusButton>
                                                          <T>+</T>
                                                      </PlusButton>
                                                  </HourCellStyled>
                                              );
                                          })
                                        : null}
                                    {!isOffHoursExpanded ? (
                                        <AfterHoursCell onClick={() => setIsOffHoursExpanded(true)}>
                                            {rowIndex === 0 && hasTaskOutsideView ? "..." : null}
                                        </AfterHoursCell>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ))
            ),
        [tasks, from_date, mechanics, hoursArray, nationalHolidays]
    );

    const ScheduledTasks = useMemo(() => {
        const firstDayOfWeek = startOfWeek(from_date);

        return tasks.filter(isTaskInsideWorkHours).map((task) => {
            const mechanic = mechanics.find((item) => item.id === task.mechanic_id);

            const distanceToStartOfWeek =
                startOfDay(task.start).diff(firstDayOfWeek).as("minutes") *
                (theme.scheduler.dayWidth / (24 * 60));

            const startOfWorkDay = startOfDay(task.start).plus({ hours: dayStart });
            const taskStartDate = getDate(task.start);
            const startsBeforeDay = taskStartDate.hour < daySchedule.work_start;

            const distanceFromStartOfWorkToTask = startsBeforeDay
                ? 0
                : taskStartDate.diff(getDate(new Date(startOfWorkDay))).as("minutes") *
                  ((theme.scheduler.dayWidth -
                      (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth * 2)) /
                      (hoursInDay * 60));

            const xPosition =
                distanceToStartOfWeek +
                (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth) +
                distanceFromStartOfWorkToTask -
                2;

            const yPosition =
                mechanic?.top -
                theme.scheduler.cellHeight +
                task.displayRow * theme.scheduler.cellHeight;

            const isCheckboxChecked = autoPlannerSelectedTaskIds.includes(task.id);
            return (
                <div
                    key={`${task.id}-${task.isAutoPlanned}`}
                    className={"task"}
                    style={{ left: `${xPosition}px`, top: `${yPosition}px` }}
                >
                    <Task
                        task={task}
                        hoursInDay={hoursInDay}
                        isOffHoursExpanded={isOffHoursExpanded}
                        tooltipRenderTarget={{ current: document.body }}
                        daySchedule={daySchedule}
                        explicitTooltipPlacement={() => {
                            return {
                                left:
                                    xPosition +
                                    (schedulerRef.current?.getBoundingClientRect().x || 0),
                                top:
                                    yPosition +
                                    (schedulerRef.current?.getBoundingClientRect().y || 0),
                            };
                        }}
                        handleTaskDoubleClick={handleTaskDoubleClick}
                        isCheckboxDisabled={isCheckboxDisabled}
                        isCheckboxVisible={
                            isInAutoPlannerMode && (isCheckboxChecked || !isCheckboxDisabled)
                        }
                        isCheckboxChecked={isCheckboxChecked}
                        onCheckboxClick={() => {
                            onTaskCheckboxUpdate(
                                task.id,
                                !autoPlannerSelectedTaskIds.includes(task.id)
                            );
                        }}
                        isGhostTask={task.isAutoPlanned}
                    />
                </div>
            );
        });
    }, [
        tasks,
        from_date,
        hoursInDay,
        isOffHoursExpanded,
        daySchedule,
        isInAutoPlannerMode,
        autoPlannerSelectedTaskIds,
        onTaskCheckboxUpdate,
        isCheckboxDisabled,
    ]);

    const Absences = useMemo(() => {
        if (!absences || !absences.length > 0) return null;

        let splitAbsences = [];

        absences.forEach((absence) => {
            const start = getDate(absence.from);
            const end = getDate(absence.to);
            const durationInDays = startOfDay(absence.to).diff(startOfDay(absence.from)).as("days");

            if (durationInDays < 0 || Object.is(durationInDays, -0)) return;

            if (hasSameDay(start, end)) {
                return splitAbsences.push(absence);
            } else {
                for (let i = 0; i <= durationInDays; i++) {
                    const absenceStart =
                        i === 0 ? absence.from : startOfDay(start.plus({ days: i })).toISO();

                    const absenceEnd =
                        i === durationInDays
                            ? absence.to
                            : endOfDay(start.plus({ days: i })).toISO();

                    if (absenceStart < absenceEnd)
                        splitAbsences.push({ ...absence, from: absenceStart, to: absenceEnd });
                }
            }
        });

        const allAbsencesForWeek = splitAbsences.filter((absence) =>
            hasSameWeek(getDate(absence.from), getDate(from_date))
        );

        if (!allAbsencesForWeek || !allAbsencesForWeek.length > 0) return null;

        return allAbsencesForWeek.map((absence, index) => {
            const mechanic = mechanics.find((mechanic) => mechanic.id === absence.user_id);

            const firstDayOfWeek = startOfWeek(from_date);
            const firstHourOfDay = startOfDay(absence.from);
            const startOfWorkDay = firstHourOfDay.plus({ hours: dayStart });
            const absenceStartDate = getDate(absence.from);
            const startsBeforeDay = absenceStartDate.hour < daySchedule.work_start;

            const distanceToStartOfWeek =
                firstHourOfDay.diff(firstDayOfWeek).as("minutes") *
                (theme.scheduler.dayWidth / (24 * 60));

            const distanceFromStartOfWorkToAbsence = startsBeforeDay
                ? 0
                : absenceStartDate.diff(startOfWorkDay).as("minutes") *
                  ((theme.scheduler.dayWidth -
                      (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth * 2)) /
                      (hoursInDay * 60));

            const xPosition =
                distanceToStartOfWeek +
                (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth) +
                distanceFromStartOfWorkToAbsence -
                2;

            const yPosition = mechanic?.top;

            const durationInHours = getDate(absence.to).diff(getDate(absence.from)).as("hours");

            return (
                <div
                    key={`${absence.id}-${index}`}
                    style={{
                        position: "absolute",
                        top: `${yPosition}px`,
                        left: `${xPosition}px`,
                    }}
                    onDoubleClick={() => editAbsence(absence)}
                >
                    <Absence
                        absence={absence}
                        hoursInDay={hoursInDay}
                        isOffHoursExpanded={isOffHoursExpanded}
                        durationInHours={durationInHours}
                        daySchedule={daySchedule}
                    />
                </div>
            );
        });
    }, [absences, from_date, hoursInDay, isOffHoursExpanded, daySchedule, tasks, mechanics]);

    const Extras = useMemo(() => {
        if (!extras || !extras.length > 0) return null;

        let splitExtras = [];

        extras.forEach((extra) => {
            const start = getDate(extra.from);
            const end = getDate(extra.to);
            const durationInDays = startOfDay(extra.to).diff(startOfDay(extra.from)).as("days");

            if (durationInDays < 0 || Object.is(durationInDays, -0)) return;

            if (hasSameDay(start, end)) {
                return splitExtras.push(extra);
            } else {
                for (let i = 0; i <= durationInDays; i++) {
                    const extraStart =
                        i === 0 ? extra.from : startOfDay(start.plus({ days: i })).toISO();

                    const extraEnd =
                        i === durationInDays ? extra.to : endOfDay(start.plus({ days: i })).toISO();

                    if (extraStart < extraEnd)
                        splitExtras.push({ ...extra, from: extraStart, to: extraEnd });
                }
            }
        });

        const allExtrasForWeek = splitExtras.filter((extra) =>
            hasSameWeek(getDate(extra.from), getDate(from_date))
        );

        if (!allExtrasForWeek || !allExtrasForWeek.length > 0) return null;

        return allExtrasForWeek.map((extra, index) => {
            const mechanic = mechanics.find((mechanic) => mechanic.id === extra.user_id);

            const firstDayOfWeek = startOfWeek(from_date);
            const firstHourOfDay = startOfDay(extra.from);
            const startOfWorkDay = firstHourOfDay.plus({ hours: dayStart });
            const extraStartDate = getDate(extra.from);
            const startsBeforeDay = extraStartDate.hour < daySchedule.work_start;

            const distanceToStartOfWeek =
                firstHourOfDay.diff(firstDayOfWeek).as("minutes") *
                (theme.scheduler.dayWidth / (24 * 60));

            const distanceFromStartOfWorkToExtra = startsBeforeDay
                ? 0
                : extraStartDate.diff(startOfWorkDay).as("minutes") *
                  ((theme.scheduler.dayWidth -
                      (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth * 2)) /
                      (hoursInDay * 60));

            const xPosition =
                distanceToStartOfWeek +
                (isOffHoursExpanded ? 0 : theme.scheduler.nonWorkingHoursWidth) +
                distanceFromStartOfWorkToExtra -
                2;

            const yPosition = mechanic?.top;

            const durationInHours = getDate(extra.to).diff(getDate(extra.from)).as("hours");

            return (
                <div
                    key={`${extra.id}-${index}`}
                    style={{
                        position: "absolute",
                        top: `${yPosition}px`,
                        left: `${xPosition}px`,
                    }}
                    onDoubleClick={() => editExtra(extra)}
                >
                    <Extra
                        extra={extra}
                        hoursInDay={hoursInDay}
                        isOffHoursExpanded={isOffHoursExpanded}
                        durationInHours={durationInHours}
                        daySchedule={daySchedule}
                    />
                </div>
            );
        });
    }, [extras, from_date, hoursInDay, isOffHoursExpanded, daySchedule, tasks, mechanics]);

    return hoursArray ? (
        <CellsWrapper>
            <div className={"header"}>{WeekDaysHeader}</div>
            <div
                id={"scheduler"}
                className={"schedule dropzone"}
                data-dropzone={"schedule"}
                ref={schedulerRef}
                onMouseLeave={resetHoveredElementStyles}
                onMouseMove={handleCalendarMouseMove}
                onMouseUp={handleCalendarMouseUp}
            >
                {ScheduleArea}
                {Absences}
                {Extras}
                {ScheduledTasks}

                {timeIndicatorVisible ? (
                    <TimeIndicatorStyled $xPosition={timeIndicatorPositionX} />
                ) : null}
            </div>
        </CellsWrapper>
    ) : null;
};

export default CalendarCells;

const CellsWrapper = styled.div`
    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const OffHoursCell = styled.div`
    width: ${(p) => p.theme.scheduler.nonWorkingHoursWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #f5f5f5;
    cursor: pointer;
    user-select: none;
`;

const BeforeHoursCell = styled(OffHoursCell)`
    border-right: 2px dotted ${(p) => p.theme.color.neutral.xlight};
`;

const AfterHoursCell = styled(OffHoursCell)``;

const PlusButton = styled(CleanButton)`
    pointer-events: none;
    display: none;
    margin: 0 auto;
    padding: 0 0.5rem;

    > ${T} {
        color: ${(p) => p.theme.color.primary.base};
    }
`;

const HourCellStyled = styled.div`
    user-select: none;
    width: ${(p) =>
        (p.$isOffHoursExpanded ? p.theme.scheduler.dayWidth : p.theme.scheduler.workingHoursWidth) /
        p.$amountOfWorkingHours}px;
    display: flex;
    align-items: center;
    height: 100%;
    border-right: ${(p) =>
        `${p.theme.scheduler.borderWidth}px dotted ${p.theme.color.neutral.xlight}`};
    background: ${(p) => (p.$isOffDay ? `#f5f5f588` : "none")};

    &:last-child {
        border-right: unset;
    }

    // Controls lunch break indicator display
    &[data-hour="${(p) => Math.floor(p.$breakStart)}"] {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            display: ${(p) => (p.$breakEnd - p.$breakStart <= 0 ? `none` : `initial`)};
            /* Calculate  the width for lunch break marker taking into account the border on both sides of all cells */
            width: ${(p) => {
                const breakLength = p.$breakEnd - p.$breakStart;
                return `calc(${breakLength * 100}% + ${
                    Math.floor(breakLength) * p.theme.scheduler.borderWidth
                }px)`;
            }};
            height: 100%;
            margin-left: ${(p) => {
                const breakStartMinutes = p.$breakStart % Math.floor(p.$breakStart);
                return breakStartMinutes === 0
                    ? `-${p.theme.scheduler.borderWidth}px`
                    : `calc(${breakStartMinutes * 100}% - ${p.theme.scheduler.borderWidth}px)`;
            }};
            background: ${(p) => p.theme.color.neutral.xlight}77;
            border-left: ${(p) =>
                p.$breakStart % Math.floor(p.$breakStart) === 0
                    ? `none`
                    : `2px dotted ${p.theme.color.neutral.xlight}`};
            border-right: ${(p) =>
                p.$breakEnd % Math.floor(p.$breakEnd) === 0
                    ? `none`
                    : `2px dotted ${p.theme.color.neutral.xlight}`};
            z-index: 5;
            pointer-events: none;
        }
    }

    &.clickable {
        cursor: pointer;

        &.displayPlusButton {
            > ${PlusButton} {
                display: initial;
            }
        }
    }

    span {
        z-index: 9;
    }

    &.markAsHovered {
        background: ${(p) => p.theme.color.neutral.base}11;

        &.firstHalfOnly {
            background: linear-gradient(
                to right,
                ${(p) => p.theme.color.neutral.base}11 50%,
                transparent 50%
            );
        }

        &.secondHalfOnly {
            background: linear-gradient(
                to right,
                transparent 50%,
                ${(p) => p.theme.color.neutral.base}11 50%
            );
        }
    }
`;

const HourTextStyled = styled(TB)`
    padding-left: 0.15rem;
`;

const TimeIndicatorStyled = styled.div`
    position: absolute;
    background: ${(p) => p.theme.color.primary.base};
    height: calc(100% + ${(p) => p.theme.scheduler.cellHeight}px);
    width: ${(p) => p.theme.scheduler.borderWidth}px;
    top: ${(p) => 0 - p.theme.scheduler.cellHeight}px;
    left: ${(p) => p.$xPosition}px;
    pointer-events: none;
`;
