import { useMemo } from "react";
import toast from "react-hot-toast";
import { useQueries } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useMultipleUnitSensorDataFromTo = (unitIds, from, to) => {
    const queries = useMemo(
        () =>
            unitIds.map((unitId) => ({
                queryKey: ["units", "sensordata", "all", unitId, from, to],
                queryFn: () => unitAPI.getUnitSensorDataFromTo({ unitId, from, to }),
                enabled: !!unitId && !!from && !!to,
                staleTime: Infinity,
                onError: (error) => {
                    toast.error(
                        `Klarte ikke hente data. Det oppsto en feil: ${
                            error.message || "Ukjent feil"
                        }`
                    );
                    console.log("Loading error: ", error.message);
                },
            })),
        [unitIds, from, to]
    );

    return useQueries(queries);
};
