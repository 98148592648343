import { useQuery, useQueryClient } from "react-query";
import userAPI from "../../../../api/core/userAPI";

export const useUsers = () => {
    const queryClient = useQueryClient();

    return useQuery(["users"], userAPI.getAll, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["users", item.id], item));
        },
    });
};
