import { useQuery, useQueryClient } from "react-query";
import partAPI from "../../../api/core/partAPI";

export const usePartsWithParams = (params) => {
    const queryClient = useQueryClient();

    return useQuery(["parts", "params", params], () => partAPI.getAllWithParams(params), {
        onSuccess: (response) => {
            response.data?.forEach((item) => queryClient.setQueryData(["parts", item.id], item));
        },
    });
};
