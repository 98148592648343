import React from "react";
import { useTranslation } from "react-i18next";
import { T, TB } from "../../components/texts";
import styled, { useTheme } from "styled-components";
import { Checkbox } from "../../components/inputs";

const CalendarLeft = ({
    mechanics,
    isCheckboxVisible = false,
    isCheckboxDisabled = false,
    autoPlannerSelectedMechanicIds,
    onCheckboxClick = null,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <CalendarLeftStyled>
            <HeaderStyled>
                <CellStyled />
            </HeaderStyled>

            <HeaderStyled>
                <CellStyled>
                    <TB>{t("mechanics", "Mechanics")}</TB>
                </CellStyled>
            </HeaderStyled>

            <div className={"schedule"}>
                {mechanics.map((mechanic) => {
                    const isChecked = autoPlannerSelectedMechanicIds.some(
                        (id) => id === mechanic.id
                    );
                    return (
                        <Flexbox
                            key={mechanic.id}
                            style={{ height: `${mechanic.rows * theme.scheduler.cellHeight}px` }}
                        >
                            {isCheckboxVisible ? (
                                <Checkbox
                                    disabled={isCheckboxDisabled}
                                    className="checkbox"
                                    checked={isChecked}
                                    size={16}
                                    onClick={() => {
                                        if (onCheckboxClick != null) {
                                            onCheckboxClick(mechanic.id, !isChecked);
                                        }
                                    }}
                                />
                            ) : null}
                            <div>
                                <T>{mechanic.name}</T>
                            </div>
                        </Flexbox>
                    );
                })}
            </div>

            <BottomHeaderStyled>
                <TB>{t("sum_hours", "Sum hours")}</TB>
            </BottomHeaderStyled>
        </CalendarLeftStyled>
    );
};

export default CalendarLeft;

const Flexbox = styled.div`
    display: flex;
    gap: 5px;
`;

const CalendarLeftStyled = styled.div`
    // Add shadow to right edge only
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -10px 0px 0px);
    z-index: 1;
    width: ${(p) => p.leftWidth || p.theme.scheduler.leftWidth}px;
    border-right: ${(p) =>
        `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};

    .schedule {
        > div {
            display: flex;
            align-items: center;
            height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;
            border-bottom: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
        }

        > div:last-child {
            border-bottom: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.base}`};
        }
    }
`;

const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;
`;

const BottomHeaderStyled = styled(HeaderStyled)`
    border-bottom: ${(p) =>
        `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
`;

const CellStyled = styled.div`
    display: flex;
    align-items: center;
    width: ${(p) => p.dayWidth || p.theme.scheduler.dayWidth}px;
    border-bottom: ${(p) =>
        `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
    height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;
`;
