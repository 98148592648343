import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import { useMapHighlight } from "./hooks/useMapHighlight";

mapboxgl.accessToken =
    "pk.eyJ1IjoibHN0cm9jaWFrIiwiYSI6ImNsNnAzN2Q3YjA2d2ozYmxwZWJ2a3lmbWUifQ.I0YRhD7jWyP5uTbNlXmSJQ";

const UnitStatusMap = ({
    sensorData,
    selectedUnitIds,
    allUnits,
    sensorColors,
    isSensorDataLoading,
    fromDate,
    toDate,
}) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const previousMapSources = useRef(null);
    const hoveredLayerId = useRef(null);

    const { t } = useTranslation();

    const { highlightedVehicleId, setHighlightedVehicleId, closestDataPointIndexForUnit } =
        useMapHighlight();

    const isSensorDataAllLoaded = sensorData.every((item) => item.status === "success");

    // Initiate map
    useEffect(() => {
        if (mapRef.current) return;

        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/lstrociak/cl6p3ee5l002314n04f4xw4t7",
            center: [13, 65],
            zoom: 4.2,
        });

        mapRef.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        // return () => mapRef.current.remove();
    }, []);

    // Set up data display on the initiated map
    useEffect(() => {
        if (!mapRef.current || !isSensorDataAllLoaded) return;

        // Delay map setup to avoid missing map style bug
        setTimeout(() => setUpMapDataDisplay(sensorData), 100);
    }, [isSensorDataAllLoaded, mapRef.current, selectedUnitIds, fromDate, toDate]);

    // Higlight vehicle on map when hovering in table graph
    useEffect(() => {
        const allPathLayerIds = sensorData
            ?.filter((item) => Boolean(item.data?.length))
            .map((item) => "routeForUnit" + item.data[0].unit_id);

        // Clear existing layer styles
        if (mapRef.current?.isStyleLoaded() && allPathLayerIds?.length) {
            allPathLayerIds?.forEach((layerId) =>
                mapRef.current?.removeFeatureState({ source: layerId })
            );
        }

        // Add hover state to selected layer
        if (highlightedVehicleId) {
            const vehicleRouteLayerId = "routeForUnit" + highlightedVehicleId;
            const vehicleRouteLayer = mapRef.current.getLayer(vehicleRouteLayerId);

            if (vehicleRouteLayer) {
                // Set layer hover state to true
                mapRef.current?.setFeatureState(
                    {
                        source: vehicleRouteLayerId,
                        id: 0,
                    },
                    { hover: true }
                );
            }
        }
    }, [highlightedVehicleId]);

    useEffect(() => {
        const allPositionDotsSourcesIds = sensorData
            ?.filter((item) => Boolean(item.data?.length))
            ?.map((item) => "positionDotsSource" + item.data[0].unit_id);

        // Clear existing layer styles
        if (mapRef.current?.isStyleLoaded() && allPositionDotsSourcesIds?.length) {
            allPositionDotsSourcesIds?.forEach((sourceId) =>
                mapRef.current?.removeFeatureState({ source: sourceId })
            );
        }

        // Add hover state to selected dots
        if (highlightedVehicleId && closestDataPointIndexForUnit) {
            const vehiclePositionDotsLayer = mapRef.current.getLayer(
                "positionDotsLayer" + highlightedVehicleId
            );

            if (vehiclePositionDotsLayer) {
                mapRef.current?.setFeatureState(
                    {
                        source: "positionDotsSource" + highlightedVehicleId,
                        id: closestDataPointIndexForUnit,
                    },
                    { highlight: true }
                );
            }
        }
    }, [highlightedVehicleId, closestDataPointIndexForUnit]);

    function setUpMapDataDisplay(sensorData) {
        if (!mapRef.current) return;

        const sensorDataWithValues = sensorData.filter((item) => item.data.length > 0);

        const pathSources = getPathSources(sensorDataWithValues);
        const startStopSources = getStartStopSources(sensorDataWithValues);
        const positionDotsSources = getPositionDotsSources(sensorDataWithValues);
        const pathLayers = getPathLayersForSources(pathSources);
        const startStopLayers = getStartStopLayersForSources(startStopSources);
        const positionDotsLayers = getPositionDotsLayersForSources(positionDotsSources);

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            className: "map-popup",
        });

        if (
            previousMapSources.current?.length > pathSources.length ||
            (previousMapSources.current?.length && !pathSources?.length)
        ) {
            const removedSources = previousMapSources.current.filter(
                (item) =>
                    !pathSources.some(
                        (source) => source.data.properties.unitId === item.data.properties.unitId
                    )
            );

            removedSources.forEach((source) => {
                mapRef.current.removeLayer(source.data.properties.pathId);
                mapRef.current.removeLayer("startStopLayer" + source.data.properties.unitId);
                mapRef.current.removeLayer("positionDotsLayer" + source.data.properties.unitId);
                mapRef.current.removeSource(source.data.properties.pathId);
            });
        }

        previousMapSources.current = pathSources;

        pathSources.forEach((source) => {
            const existingSource = mapRef.current.getSource(source.data.properties.pathId);

            if (existingSource) {
                existingSource.setData(source.data);
            } else {
                mapRef.current.addSource(source.data.properties.pathId, source);
            }
        });

        startStopSources.forEach((source) => {
            const sourceId = "startStopSource" + source.data.features[0]?.properties.unitId;
            const existingSource = mapRef.current.getSource(sourceId);

            if (existingSource) {
                existingSource.setData(source.data);
            } else {
                mapRef.current.addSource(sourceId, source);
            }
        });

        positionDotsSources.forEach((source) => {
            const sourceId = "positionDotsSource" + source.data.features[0]?.properties.unitId;
            const existingSource = mapRef.current.getSource(sourceId);

            if (existingSource) {
                existingSource.setData(source.data);
            } else {
                mapRef.current.addSource(sourceId, source);
            }
        });

        startStopLayers.forEach((layer) => {
            const existingLayer = mapRef.current.getLayer(layer.id);

            if (!existingLayer) mapRef.current.addLayer(layer);
        });

        positionDotsLayers.forEach((layer) => {
            const existingLayer = mapRef.current.getLayer(layer.id);

            if (!existingLayer) mapRef.current.addLayer(layer);
        });

        pathLayers.forEach((layer) => {
            const existingLayer = mapRef.current.getLayer(layer.id);

            if (existingLayer) {
                return;
            } else {
                mapRef.current.addLayer(layer);

                mapRef.current.on("mousemove", layer.id, (event) => {
                    const featureProperties = event.features[0].properties;
                    const unitData = JSON.parse(featureProperties?.unitData || {});

                    setHighlightedVehicleId(unitData?.id);

                    if (hoveredLayerId.current !== null) {
                        mapRef.current.setFeatureState(
                            {
                                source: hoveredLayerId.current,
                                id: 0,
                            },
                            { hover: false }
                        );
                        popup.remove();
                    }

                    hoveredLayerId.current = layer.id;

                    mapRef.current.getCanvas().style.cursor = "pointer";
                    mapRef.current.setFeatureState(
                        {
                            source: layer.id,
                            id: 0,
                        },
                        { hover: true }
                    );

                    const htmlForPopup = `
                    <p>${unitData?.int_id}</p>
                    <hr/>
                    <p>${unitData?.manufacturer || ""} ${unitData?.type || ""}</p>
                    <p class="hour-counter">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path
                            d="M17.5995 4.40072C21.2446 8.04524 21.2446 13.9544 17.5995 17.5993C13.9547 21.2447 8.04516 21.2447 4.40034 17.5993C0.755224 13.9547 0.755224 8.04554 4.40034 4.40072C8.04516 0.755301 13.9544 0.755301 17.5995 4.40072Z"
                            stroke="#38558D"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            />
                            <path d="M11 4.97849V11L13.7097 13.7097" stroke="#38558D" stroke-miterlimit="10" />
                            <path d="M2.56982 11H3.77413" stroke="#38558D" stroke-miterlimit="10" />
                            <path d="M18.2256 11H19.4299" stroke="#38558D" stroke-miterlimit="10" />
                            <path d="M11 19.4301V18.2258" stroke="#38558D" stroke-miterlimit="10" />
                            <path d="M11 3.77419V2.56989" stroke="#38558D" stroke-miterlimit="10" />
                        </svg>

                        ${t("timecounter")}: ${
                            unitData?.hour_counter ? Math.round(unitData.hour_counter) : "–"
                        } ${t("hours_unit_symbol")}
                    </p>
                    `;

                    popup.setLngLat(event.lngLat).setHTML(htmlForPopup).addTo(mapRef.current);
                });

                mapRef.current.on("mouseleave", layer.id, () => {
                    mapRef.current.getCanvas().style.cursor = "";
                    mapRef.current.setFeatureState(
                        {
                            source: layer.id,
                            id: 0,
                        },
                        { hover: false }
                    );
                    hoveredLayerId.current = null;

                    popup.remove();
                    setHighlightedVehicleId(null);
                });
            }
        });

        const allPathCoordinates = pathSources.flatMap(
            (source) => source.data.geometry.coordinates
        );

        if (pathSources?.length && allPathCoordinates?.length) {
            fitMapViewToCoordinates(allPathCoordinates);
        }
    }

    function fitMapViewToCoordinates(coordinates) {
        const mapDisplayBounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
        coordinates.forEach((coordinate) => mapDisplayBounds.extend(coordinate));

        mapRef.current.fitBounds(mapDisplayBounds, {
            padding: 80,
            maxZoom: 15,
        });
    }

    function getPathSources(data) {
        return data.map((item, index) => {
            const coordinates = getCoordinatesArray(item.data);

            return {
                type: "geojson",
                generateId: true,
                data: {
                    type: "Feature",
                    properties: {
                        pathId: "routeForUnit" + item.data[0]?.unit_id,
                        unitId: item.data[0]?.unit_id,
                        fromDate: item.data?.[0]?.timestamp,
                        toDate: item.data?.[item.data?.length - 1]?.timestamp,
                        unitData: allUnits?.data?.find((unit) => unit.id === item.data[0]?.unit_id),
                        pathColor: sensorColors[index < 10 ? index : String(index)?.slice(-1)],
                    },
                    geometry: { type: "LineString", coordinates },
                },
            };
        });
    }

    function getStartStopSources(data) {
        return data.map((item) => {
            return {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: (item.data?.length
                        ? [item.data[0], item.data[item.data.length - 1]]
                        : []
                    ).map((dataPoint, index) => ({
                        type: "Feature",
                        properties: {
                            unitId: dataPoint.unit_id,
                            positionType: index === 0 ? "start" : "stop",
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [
                                dataPoint.data["position.longitude"],
                                dataPoint.data["position.latitude"],
                            ],
                        },
                    })),
                },
            };
        });
    }

    function getPositionDotsSources(data) {
        return data.map((item) => {
            const positionDotFeatures = item.data?.map((dataPoint) => ({
                type: "Feature",
                properties: {
                    unitId: dataPoint.unit_id,
                    timestamp: dataPoint.timestamp,
                    engineIgnitionStatus: dataPoint.data["engine.ignition.status"],
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        dataPoint.data["position.longitude"],
                        dataPoint.data["position.latitude"],
                    ],
                },
            }));

            return {
                type: "geojson",
                generateId: true,
                data: {
                    type: "FeatureCollection",
                    features: positionDotFeatures,
                },
            };
        });
    }

    function getPathLayersForSources(sources) {
        return sources.map((source) => ({
            id: source.data.properties.pathId,
            type: "line",
            source: source.data.properties.pathId,
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    "#000000",
                    ["get", "pathColor"],
                ],
                "line-width": 4,
                "line-opacity": 0.8,
            },
        }));
    }

    function getStartStopLayersForSources(sources) {
        return sources.map((source) => ({
            id: "startStopLayer" + source.data.features[0]?.properties.unitId,
            type: "circle",
            source: "startStopSource" + source.data.features[0]?.properties.unitId,
            paint: {
                "circle-radius": ["case", ["==", ["get", "positionType"], "start"], 3, 5],
                "circle-color": [
                    "case",
                    ["==", ["get", "positionType"], "start"],
                    "#ffffff",
                    "#000000",
                ],
                "circle-stroke-color": "#000000",
                "circle-stroke-width": 2,
            },
        }));
    }

    function getPositionDotsLayersForSources(sources) {
        return sources.map((source) => ({
            id: "positionDotsLayer" + source.data.features[0]?.properties.unitId,
            type: "circle",
            source: "positionDotsSource" + source.data.features[0]?.properties.unitId,
            paint: {
                "circle-radius": 5,
                "circle-color": "#62c370",
                "circle-stroke-color": "#38558d",
                "circle-stroke-width": 2,
                "circle-opacity": [
                    "case",
                    ["boolean", ["feature-state", "highlight"], false],
                    1,
                    0,
                ],
                "circle-stroke-opacity": [
                    "case",
                    ["boolean", ["feature-state", "highlight"], false],
                    1,
                    0,
                ],
            },
        }));
    }

    function getCoordinatesArray(data) {
        const coordinates = data
            .map((item) => {
                const lng = item.data["position.longitude"];
                const lat = item.data["position.latitude"];

                if (lng && lat) {
                    return [lng, lat];
                } else {
                    return null;
                }
            })
            .filter(Boolean);

        return coordinates;
    }

    return (
        <MapContainerStyled ref={mapContainerRef} className={"map-container"}>
            {isSensorDataLoading ? (
                <SpinnerContainer>
                    <LoadingSpinner />
                </SpinnerContainer>
            ) : null}
        </MapContainerStyled>
    );
};

export default UnitStatusMap;

const MapContainerStyled = styled.article`
    height: 50rem;
    opacity: 0;
    animation: fadeIn 3000ms 200ms ease forwards;

    .map-popup .mapboxgl-popup-content {
        padding: 0.3rem 0;
        border-radius: 0;

        p {
            font-family: ${(props) => props.theme.font.t["font-family"]};
            font-size: ${(props) => props.theme.font.t["font-size"]};
            line-height: ${(props) => props.theme.font.t["line-height"]};
            margin: 0;
            padding-left: 0.5rem;
            padding-right: 1rem;

            &.hour-counter {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 0.3rem;
                }
            }
        }

        hr {
            margin: 0.3rem 0;
        }
    }
`;

const SpinnerContainer = styled.article`
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.5;
`;
