import { useEffect, useState, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatISO, startOfDay, endOfDay } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import styled from "styled-components";

import { checkForPermission, usePermissions } from "../user/hooks/usePermissions";
import { useTasksWithParams } from "../task/hooks/useTasksWithParams";
import { useSearchParams } from "../application/hooks/useSearchParams";
import { useParts } from "../materials/hooks/useParts";
import { getLastUsedWorkshopId, saveLastUsedWorkshopId } from "../../app/localStorage";
import { TaskStatus } from "../../api/core/taskAPI";
import { TASK_CAUSES, TASK_TYPES } from "../../constants";
import { PERMISSIONS } from "../auth/permissions";

import MainArea from "../../components/layout/MainArea";
import SelectStyled from "../../components/application/styles/SelectStyled";
import { TB, T } from "../../components/texts";
import { Button, CleanButton } from "../../components/buttons";
import { DateInput, InputWithLabel } from "../../components/inputs";
import { Horizontal } from "../../components/layout/FlexGrid";
import { standardDateOnly } from "../../common/date";
import Spacer from "../../components/helpers/Spacer";
import TasksTable from "./TasksTable";
import TaskEdit from "../task/TaskEdit";
import WorkshopSelect from "../../components/application/workshop/WorkshopSelect";
import TasksOverviewRowCost from "./TasksOverviewRowCost";

const selectFilter = (row, columnId, value) => {
    if (columnId === "status") return true;
    const { getValue } = row;
    return getValue(columnId) === value;
};

const TasksOverview = () => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const allStatusExceptInvoiced = Object.values(TaskStatus)?.filter(
        (item) => item !== "invoiced" && item !== "denied"
    );

    const [statusList, setStatusList] = useState([]);

    const { t } = useTranslation();
    const methods = useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const { getParam, setParam, deleteParam, hasParam, queryParams } = useSearchParams();

    const search = methods.watch("search");
    const workshop_id = getParam("workshop_id");
    const fromParam = getParam("from");
    const toParam = getParam("to");

    const tasks = useTasksWithParams(workshop_id, statusList);
    const parts = useParts();
    const permissions = usePermissions();
    const taskTypes = TASK_TYPES.map((type) => ({
        id: type.id,
        name: t(`task_type.${type.name}`),
    }));
    const taskCauses = TASK_CAUSES.map((cause) => ({
        id: cause.id,
        name: t(`task_cause.${cause.name}`),
    }));
    const [columnFilters, setColumnFilters] = useState([]);

    useEffect(() => {
        if (hasParam("status")) {
            const statusListFromParams = getParam("status");
            setStatusList(statusListFromParams?.split(",") || []);
        } else {
            setStatusList(allStatusExceptInvoiced);
        }
    }, [location]);

    useEffect(() => {
        const newFilter = [];
        if (hasParam("status")) {
            const value = getParam("status");
            if (value) {
                newFilter.push({
                    id: "status",
                    value,
                });
            }
        }

        if (hasParam("unit_int_id")) {
            const value = getParam("unit_int_id");
            if (value) {
                newFilter.push({
                    id: "unit_int_id",
                    value,
                });
            }
        }

        const categoryParam = t("category");
        if (hasParam(categoryParam)) {
            const value = getParam(categoryParam);
            if (value) {
                newFilter.push({
                    id: categoryParam,
                    value,
                });
            }
        }

        if (hasParam("cause_id")) {
            const value = getParam("cause_id");
            if (value) {
                newFilter.push({
                    id: "cause_id",
                    value,
                });
            }
        }

        setColumnFilters(newFilter);
    }, [location]);

    useEffect(() => {
        methods.setValue("search", "");
        if (workshop_id) {
            saveLastUsedWorkshopId(workshop_id);
        } else {
            tryRedirectingToLastUsedWorkshop();
        }
    }, [workshop_id]);

    const data = useMemo(() => {
        if (!tasks?.data) return [];
        if (!fromParam && !toParam) return tasks.data;
        // Filter for tasks between selected from/to dates
        return tasks.data.filter((task) =>
            task.start
                ? (fromParam
                      ? startOfDay(new Date(task.start)) >= startOfDay(new Date(fromParam))
                      : true) &&
                  (toParam ? endOfDay(new Date(task.start)) <= endOfDay(new Date(toParam)) : true)
                : false
        );
    }, [tasks?.data, fromParam, toParam]);

    const initialState = useMemo(() => {
        const filtersFromUrl = [...queryParams.entries()]
            .filter((entry) => entry[0] !== "workshop_id" && entry[0] !== "status" && !!entry[1])
            .map((entry) => ({ id: entry[0], value: entry[1] || undefined }));

        return {
            pagination: { pageSize: 25 },
            filters: filtersFromUrl,
            sorting: [{ id: "start", desc: true }],
        };
    }, []);

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? standardDateOnly(value) : "";
                },
                enableColumnFilter: false,
            },
            {
                header: t("order"),
                accessorKey: "workorder.id",
                cell: ({ getValue, row }) =>
                    canOpenTasksForCustomer(row.original?.host_id) ? (
                        <CleanButton
                            type="button"
                            onClick={() => openEditorForTask(row.original.id)}
                        >
                            <TB $link>{getValue()}</TB>
                        </CleanButton>
                    ) : (
                        <T>{getValue()}</T>
                    ),
                enableColumnFilter: false,
            },
            {
                header: t("status"),
                filterFn: "select",
                filterElement: (context) => <SelectColumnFilter {...context} />,
                accessorKey: "status",
                cell: ({ getValue }) => {
                    return <div>{t(`status-${getValue()}`)}</div>;
                },
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
                cell: ({ getValue, row }) => {
                    const value = getValue();
                    return value && canEditUnitsForCustomer(row.original?.unit?.customer_id) ? (
                        <EditLink to={`/units/${row.original.unit?.id}/edit`}>
                            <TB $link>{value}</TB>
                        </EditLink>
                    ) : (
                        <T>{value || "-"}</T>
                    );
                },
                filterFn: "select",
                filterElement: (context) => <SelectColumnFilter {...context} />,
            },
            {
                header: t("category"),
                accessorFn: (row) => taskTypes.find((type) => type.id === row.type_id)?.name || "",
                filterElement: (context) => <SelectColumnFilter {...context} />,
                filterFn: "select",
            },
            {
                header: t("description_2"),
                accessorKey: "description",
                enableColumnFilter: false,
            },
            {
                header: t("customer_ref"),
                accessorKey: "customer_ref",
                enableColumnFilter: false,
            },
            {
                header: t("cause"),
                accessorKey: "cause_id",
                accessorFn: (row) =>
                    taskCauses.find((cause) => cause.id === row.cause_id)?.name || "",
                filterElement: (context) => <SelectColumnFilter {...context} />,
                filterFn: "select",
            },
            {
                header: t("feedback"),
                accessorKey: "report",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: t("timecounter"),
                accessorKey: "unit_hour_counter",
                enableColumnFilter: false,
            },
            {
                header: t("duration"),
                accessorKey: "duration",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? `${value.toString().replace(".", ",")} t` : "";
                },
                enableColumnFilter: false,
            },
            {
                header: t("cost"),
                cell: ({ row }) => (
                    <TasksOverviewRowCost
                        customer_id={row.original.customer_id}
                        invoice_total={row.original.invoice_total}
                        duration={row.original.duration}
                        partsCost={row.original.parts_cost}
                        status={row.original.status}
                        overtime={row.original.overtime}
                        additionalExpensesCost={row.original.additional_expenses?.reduce(
                            (acc, value) => acc + value.cost,
                            0
                        )}
                    />
                ),
                enableColumnFilter: false,
            },
        ],
        [parts?.data, permissions?.data, workshop_id, statusList, location]
    );

    const table = useReactTable({
        columns,
        data,
        initialState,
        filterFns: {
            select: selectFilter,
        },
        state: {
            columnFilters,
        },
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function SelectColumnFilter({ column }) {
        const { id, getFacetedUniqueValues, getFilterValue } = column;
        const uniqueColumnKeys =
            id === "status"
                ? Object.values(TaskStatus)
                      .map((item) => ({
                          label: t(`status-${item}`),
                          value: item,
                      }))
                      .sort((a, b) => a.label?.localeCompare(b.label))
                : Array.from(getFacetedUniqueValues().keys())
                      .filter((key) => key?.length > 0)
                      .map((key) => ({
                          label: key,
                          value: key,
                      }));

        if (id === "status") {
            uniqueColumnKeys.unshift({
                label: t("all"),
                value: Object.values(TaskStatus)?.join(","),
            });

            uniqueColumnKeys.unshift({
                label: t("all_open_orders"),
                value: allStatusExceptInvoiced?.join(","),
            });
        } else {
            uniqueColumnKeys.unshift({ label: t("all"), value: "" });
        }

        const filterValue = getFilterValue();
        const currentValue = uniqueColumnKeys.find((item) => item.value === filterValue);
        return (
            <SelectStyled
                style={{
                    marginTop: "0.2rem",
                    marginBottom: "0.2rem",
                }}
            >
                <Select
                    defaultValue={uniqueColumnKeys[0]}
                    value={currentValue || undefined}
                    onChange={(e) => {
                        setParam(id, e.value);
                    }}
                    options={uniqueColumnKeys}
                    classNamePrefix="rs"
                    name={id}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            </SelectStyled>
        );
    }

    const durationTotal = useMemo(() => {
        if (!rowModel.rowsById) return null;
        const rows = Object.values(rowModel.rowsById);
        if (!(rows.length > 0)) return null;
        return rows.reduce((prev, { original }) => prev + (original.duration || 0), 0);
    }, [rowModel.rows]);

    // Calculate the total cost of all tasks in the table
    const costTotal = useMemo(() => {
        if (!rowModel.rowsById) return null;
        const rows = Object.values(rowModel.rowsById);
        if (!(rows.length > 0)) return null;
        return rows.reduce((prev, { original }) => {
            function sumUpTask() {
                let hourlyRate = original.customer?.price_0;

                if (+original.overtime === 100) {
                    hourlyRate = original.customer?.price_100;
                } else if (+original.overtime === 50) {
                    hourlyRate = original.customer?.price_50;
                }
                const hoursCost = (+original.duration || 0) * (hourlyRate || 0);

                return (
                    hoursCost +
                    (original.parts_cost || 0) +
                    (original.additional_expenses?.reduce((acc, value) => acc + value.cost, 0) || 0)
                );
            }

            const taskCost =
                original.status === TaskStatus.INVOICED && original.invoice_total
                    ? original.invoice_total
                    : sumUpTask();

            return prev + taskCost;
        }, 0);
    }, [rowModel.rows]);

    // Calculate the total cost of all tasks in the table
    const costMaterial = useMemo(() => {
        if (!rowModel.rowsById) return null;
        const rows = Object.values(rowModel.rowsById);
        if (!(rows.length > 0)) return null;
        return rows.reduce((prev, { original }) => {
            return prev + (original.parts_cost ?? 0);
        }, 0);
    }, [rowModel.rows]);

    async function tryRedirectingToLastUsedWorkshop() {
        const lastUsedWorkshopId = await getLastUsedWorkshopId();
        if (lastUsedWorkshopId) navigate(`/scheduler/tasks?workshop_id=${lastUsedWorkshopId}`);
    }

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function createNewTask() {
        setEditTaskId(null);
        setIsTaskEditorOpen(true);
    }

    function canEditUnitsForCustomer(customer_id) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.unit_admin.name,
            customer_id: customer_id,
        });
    }

    function canOpenTasksForCustomer(host_id) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        const validPermissions = [
            PERMISSIONS.scheduler_full.name,
            PERMISSIONS.scheduler_read.name,
            PERMISSIONS.mechanic.name,
        ];

        return validPermissions
            .map((permission) =>
                checkForPermission({
                    dataToCheck: permissions.data,
                    permission,
                    customer_id: host_id,
                })
            )
            .some((check) => check === true);
    }

    function updateFromToDate(newDate, key) {
        const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

        if (formattedDate) {
            setParam(key, formattedDate);
        } else {
            deleteParam(key);
        }
    }

    return (
        <MainArea>
            <Container>
                <Header>
                    <CustomerSelectContainer>
                        <WorkshopSelect
                            onChange={(e) => setParam("workshop_id", e.value)}
                            defaultWorkshopId={workshop_id}
                        />
                    </CustomerSelectContainer>

                    <Spacer />

                    {!!workshop_id && (
                        <Button
                            onClick={createNewTask}
                            type="button"
                            style={{ minWidth: "12rem", marginBottom: "0.5rem" }}
                        >
                            <TB $second>{t("add_new_task")}</TB>
                        </Button>
                    )}
                </Header>

                <FormProvider {...methods}>
                    {!!workshop_id && (
                        <form>
                            <InputsContainer>
                                <SearchInput
                                    name="search"
                                    label={t("search_tasks")}
                                    autoComplete="off"
                                />
                                <DateInputContainer>
                                    <DateInput
                                        name="from"
                                        label={t("from_start_time")}
                                        value={fromParam ? new Date(fromParam) : null}
                                        setValue={(v) => updateFromToDate(v, "from")}
                                    />
                                </DateInputContainer>
                                <DateInputContainer>
                                    <DateInput
                                        name="to"
                                        label={t("to_start_time")}
                                        value={toParam ? new Date(toParam) : null}
                                        setValue={(v) => updateFromToDate(v, "to")}
                                    />
                                </DateInputContainer>
                            </InputsContainer>

                            <TotalAmountSpan>
                                {t("total_duration")}:{" "}
                                {durationTotal != null
                                    ? `${durationTotal.toLocaleString("no")} t`
                                    : "–"}
                            </TotalAmountSpan>

                            <TotalAmountSpan>
                                {t("total_cost")}:{" "}
                                {costTotal != null
                                    ? `${Number(costTotal.toFixed(0)).toLocaleString("no")} kr`
                                    : "–"}
                            </TotalAmountSpan>

                            <TotalAmountSpan>
                                {t("workhours")}:{" "}
                                {costTotal != null && costMaterial != null
                                    ? `${Number((costTotal - costMaterial).toFixed(0)).toLocaleString("no")} kr`
                                    : "–"}
                            </TotalAmountSpan>

                            <TotalAmountSpan>
                                {t("material")}:{" "}
                                {costMaterial != null
                                    ? `${Number(costMaterial.toFixed(0)).toLocaleString("no")} kr`
                                    : "–"}
                            </TotalAmountSpan>

                            <TasksTable
                                headerGroups={headerGroups}
                                rowModel={rowModel}
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                setPageIndex={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                                isLoading={tasks?.isLoading}
                            />
                        </form>
                    )}
                </FormProvider>

                {isTaskEditorOpen && (
                    <TaskEdit
                        editTaskId={editTaskId}
                        isOpen={isTaskEditorOpen}
                        onClose={closeTaskEditor}
                        workshopId={workshop_id}
                        resetTaskEditor={resetTaskEditor}
                        initialData={{
                            ...taskEditorInitialData,
                            status: {
                                value: TaskStatus.REQUEST,
                                label: t(`status-${TaskStatus.REQUEST}`),
                            },
                        }}
                    />
                )}
            </Container>
        </MainArea>
    );
};

export default TasksOverview;

const TotalAmountSpan = styled.span`
    margin-right: 1.5rem;
`;

const InputsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

const DateInputContainer = styled.div`
    margin-right: 1rem;
`;

const SearchInput = styled(InputWithLabel)`
    flex-grow: 1;
    max-width: 27rem;
    margin-right: 1rem;
`;

const Container = styled.div`
    opacity: 0;
    animation: fadeIn 300ms ease forwards;
`;

const Header = styled(Horizontal)`
    margin-top: 0.8rem;
    margin-bottom: 1rem;

    align-items: center;
    flex-wrap: wrap;
`;

const CustomerSelectContainer = styled.section`
    min-width: 20rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
`;

const EditLink = styled(Link)`
    text-decoration: none;
`;
