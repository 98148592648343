import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { CleanButton } from "../../../components/buttons";
import { CloseIcon } from "../../../components/icons";

import { T, TB } from "../../../components/texts";

const UnitStatusRowInfo = ({
    unitData,
    dataForIgnitionOn,
    color,
    handleRemoveUnit,
    isHovered,
    rowHeight,
}) => {
    const { t } = useTranslation();

    function getDurationForIgnitionOn() {
        const durationInHours = Math.floor(dataForIgnitionOn?.durationInSeconds / (60 * 60));
        const durationInMinutes =
            Math.floor(dataForIgnitionOn?.durationInSeconds / 60) - durationInHours * 60;

        const duration =
            (durationInHours
                ? `${durationInHours.toLocaleString("no")} ${t("hours_unit_symbol")}`
                : "") +
            (durationInMinutes ? ` ${durationInMinutes} ${t("minutes_unit_symbol")}` : "");

        return duration;
    }

    return (
        <Wrapper isHovered={isHovered}>
            <ContentContainer isHovered={isHovered} rowHeight={rowHeight}>
                <ColorBox color={color} />
                <TextField>
                    <EditLink to={`/units/${unitData?.id}/edit`}>
                        <TB $link>
                            {unitData?.int_id}{" "}
                            {!!unitData?.group?.name && ` – ${unitData.group.name}`}
                        </TB>{" "}
                    </EditLink>
                    <T>
                        {t("timecounter")}:{" "}
                        {+unitData?.hour_counter
                            ? `${Math.round(unitData.hour_counter).toLocaleString("no")} ${t(
                                  "hours_unit_symbol"
                              )}`
                            : "-"}
                    </T>
                    <T>
                        {t("in_use")}: {getDurationForIgnitionOn() || "–"}
                    </T>
                </TextField>

                <RemoveButton onClick={handleRemoveUnit}>
                    <CloseIcon stroke="#38558D" />
                </RemoveButton>
            </ContentContainer>
        </Wrapper>
    );
};

export default UnitStatusRowInfo;

const Wrapper = styled.div`
    border-top: 2px dotted ${(p) => p.theme.color.neutral.xlight};
    background: ${(p) => (p.isHovered ? "rgba(0, 0, 0, 0.05)" : "none")};
`;

const ContentContainer = styled.section`
    border-right: 2px dotted ${(p) => p.theme.color.neutral.xlight};
    display: flex;
    width: 19rem;
    align-items: center;
    height: ${(p) => p.rowHeight - 2}px;
    background: ${(p) => (p.isHovered ? "rgb(243,243,243)" : "#ffffff")};
    pointer-events: all;
    overflow: hidden;
`;

const TextField = styled.section`
    display: flex;
    flex-direction: column;
    padding: 0 0.6rem;
    flex-grow: 1;
`;

const EditLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    width: max-content;
`;

const ColorBox = styled.section`
    background: ${(p) => p.color};
    display: block;
    width: 0.6rem;
    height: calc(100% - 8px);
`;

const RemoveButton = styled(CleanButton)`
    display: flex;
    padding-right: 0.3rem;

    > svg {
        transform: scale(1.3);
    }
`;
