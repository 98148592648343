import { url2data, fetchData, updateData } from "../fetch";

const getMe = async () => {
    return fetchData(`core/users/me`);
};

const getPermissions = async () => {
    return fetchData(`core/users/permissions`);
};

const getAll = async () => {
    return fetchData(`core/users`);
};

const getUserFull = async (id) => {
    return fetchData(`core/users/${id}/full`);
};

const getUsernameExists = async (username) => {
    return url2data(`core/users/username/exists`, { username });
};

const getEmailExists = async (email) => {
    return url2data(`core/users/email/exists`, { email });
};

const updateUser = async (data) => updateData(data, `core/users`);

const updateSelf = async (data) => {
    return url2data(`core/users/self`, data, "PUT");
};

export default {
    getMe,
    getPermissions,
    getAll,
    getUserFull,
    getUsernameExists,
    getEmailExists,
    updateUser,
    updateSelf,
};
