import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnit } from "../unit/hooks/useUnit";
import { usePatchUnit } from "../admin/units/hooks/usePatchUnit";

import { TB, T } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";
import { Button } from "../../components/buttons";
import { standardDateOnly } from "../../common/date";

const TaskEditHourCounter = ({ defaultValue, disabled }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const unit_id = watch("unit_id");
    const { data: unit } = useUnit(unit_id?.value);
    const { mutate: patchUnit } = usePatchUnit();

    function disableVehicle() {
        if (!unit.id) return;

        patchUnit({
            id: unit.id,
            defect: true,
        });
    }

    const hourCounterUpdatedAt = unit?.hour_counter_updated_at;
    return (
        <Container>
            <HourCounterContainer>
                <HourCounterInput
                    name="hour_counter"
                    label={t("new_hour_counter")}
                    type="number"
                    min={0}
                    step="any"
                    defaultValue={defaultValue}
                    disabled={disabled}
                />

                <T>
                    {`${t("most_recent_hour_counter_value")}: `}
                    <TB>
                        {unit?.hour_counter
                            ? `${String(unit?.hour_counter).replace(".", ",")} ${t(
                                  "hours_unit_symbol"
                              )}`
                            : "–"}
                    </TB>
                    {hourCounterUpdatedAt ? (
                        <div>
                            <span>{t("last_updated_at")}: </span>
                            <TB>
                                <HourCounterDateContainer
                                    $isOutdated={
                                        Math.round(
                                            (new Date().getTime() -
                                                new Date(hourCounterUpdatedAt).getTime()) /
                                                (1000 * 3600 * 24)
                                        ) > 30
                                    }
                                >
                                    ({standardDateOnly(hourCounterUpdatedAt)})
                                </HourCounterDateContainer>
                            </TB>
                        </div>
                    ) : null}
                </T>
            </HourCounterContainer>

            <ButtonContainer>
                <VehicleDefectButton
                    onClick={disableVehicle}
                    disabled={!unit || unit?.defect}
                    type="button"
                >
                    <TB $second>{t("mark_vehicle_as_defect")}</TB>
                </VehicleDefectButton>
            </ButtonContainer>
        </Container>
    );
};

export default TaskEditHourCounter;

const Container = styled.section`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const HourCounterContainer = styled.section`
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 2rem;
    min-width: 20rem;
`;

const HourCounterDateContainer = styled.span`
    color: ${({ $isOutdated, theme }) => ($isOutdated ? theme.color.error.base : "inherit")};
`;

const HourCounterInput = styled(InputWithLabel)`
    max-width: 10rem;
    margin-right: 0.5rem;

    > input {
        text-align: right;
        padding-right: 0.5rem;
    }
`;

const ButtonContainer = styled.section`
    flex: 1;
`;

const VehicleDefectButton = styled(Button)`
    display: block;
    max-width: max-content;
`;
