import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import SelectStyled from "./styles/SelectStyled";
import { T } from "../texts";

const ServerSelect = ({
    name,
    label,
    defaultValue,
    selectUse,
    selectKey = null,
    filter = null,
    listKey = "name",
    validate,
    hideErrorLabel,
    metaKey = null,
    styles,
    ...props
}) => {
    const { t } = useTranslation();

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const data = selectKey ? selectUse(selectKey) : selectUse();
    const value = data.data ? watch(name) : null;

    // get setValue function from form context if possible, otherwise has to be sent in through props
    const setValue = useFormContext().setValue || props.setValue;

    const options = useMemo(() => {
        const optionsDataFiltered = !filter
            ? data.data
            : data.data?.filter((element) =>
                  Object.keys(filter).every((key) => filter[key] === element[key])
              );

        const options = optionsDataFiltered?.map((element) => ({
            value: element.id,
            label: element[listKey],
            meta: metaKey ? element[metaKey] : null,
        }));

        return options;
    }, [data.data, selectKey, listKey, filter]);

    useEffect(() => {
        if (!data.data || !options || !value) return;
        let selectedOption = null;

        if (typeof value === "number") {
            selectedOption = options.find((option) => option.value === value);
        } else if ("value" in value && !("label" in value)) {
            selectedOption = options.find((option) => option.value === value.value);
        }

        if (selectedOption) {
            setValue(name, selectedOption);
        }
    }, [value, data.data, options]);

    return (
        <SelectStyled>
            {!!label && <T>{`${label} ${validate ? " *" : ""}`}</T>}

            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={validate}
                render={({ field }) => (
                    <Select
                        isClearable={true}
                        classNamePrefix={"rs"}
                        {...field}
                        placeholder={t("select")}
                        noOptionsMessage={() => t("no_results")}
                        options={options}
                        {...props}
                        menuPortalTarget={document.body}
                        styles={{ ...styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                )}
            />

            {!hideErrorLabel &&
                (name in errors ? <p role={"alert"}>{errors[name]?.message}</p> : <p>&nbsp;</p>)}
        </SelectStyled>
    );
};

export default ServerSelect;
