import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TB, T } from "../../components/texts";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { SortIcon } from "../../components/icons";
import { flexRender } from "@tanstack/react-table";

const TasksTable = ({
    headerGroups,
    rowModel,
    visibleColumns,
    renderRowSubComponent,
    pageCount,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    setPageIndex,
    pageIndex,
    isLoading,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TableContainer>
                <TableStyled>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <Fragment key={headerGroup.id}>
                                <tr>
                                    {headerGroup.headers.map((header) => {
                                        const canSort = header.column.getCanSort();
                                        const isSorted = header.column.getIsSorted();
                                        return (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                <div
                                                    className={
                                                        canSort ? "cursor-pointer select-none" : ""
                                                    }
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <TB>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </TB>

                                                    {canSort ? (
                                                        <SortIcon sort={isSorted ?? ""} />
                                                    ) : null}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id} style={{ paddingRight: ".5rem" }}>
                                            {header.column.getCanFilter() ? (
                                                <Filter {...header.getContext()} />
                                            ) : null}
                                        </th>
                                    ))}
                                </tr>
                            </Fragment>
                        ))}
                    </thead>

                    <tbody>
                        {rowModel.rows.map((row) => (
                            <Fragment key={row.id}>
                                <tr>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                            <T>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </T>
                                        </td>
                                    ))}
                                </tr>

                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns?.length}>
                                            {renderRowSubComponent
                                                ? renderRowSubComponent({ row })
                                                : null}
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        ))}
                    </tbody>
                </TableStyled>
            </TableContainer>

            <TablePaginationNav
                pageCount={pageCount}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                gotoPage={setPageIndex}
                pageIndex={pageIndex}
            />

            {isLoading ? (
                <DashboardSpinner />
            ) : rowModel.rows.length < 1 ? (
                <NothingToShow>
                    <TB>{t("no_tasks_to_show")}</TB>
                </NothingToShow>
            ) : null}
        </>
    );
};

export default TasksTable;

function Filter(args) {
    return args.column.columnDef.filterElement?.(args) ?? null;
}

const DashboardSpinner = () => (
    <section style={{ height: "30vh" }}>
        <LoadingSpinner light />
    </section>
);

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 80rem;

    tr {
        td {
            vertical-align: top;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        th:nth-child(3),
        td:nth-child(3) {
            min-width: 12rem;
        }

        th:nth-child(4),
        td:nth-child(4) {
            min-width: 7.5rem;
        }

        th:nth-child(5),
        td:nth-child(5) {
            min-width: 17rem;
        }

        th:nth-child(6),
        td:nth-child(6) {
            min-width: 18rem;
            padding-left: 1rem;
            padding-right: 1.5rem;
        }

        th:nth-child(8),
        td:nth-child(8) {
            min-width: 16rem;
        }

        th:nth-child(9),
        td:nth-child(9) {
            min-width: 20rem;
        }

        th:nth-child(10),
        td:nth-child(10) {
            text-align: right;
        }

        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }
`;
