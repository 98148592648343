import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import toast from "react-hot-toast";

import { useUnit } from "../unit/hooks/useUnit";
import { useTask } from "./hooks/useTask";
import { useWorkshops } from "../admin/customers/hooks/useWorkshops";
import { useSavePart } from "../materials/hooks/useSavePart";
import { useSaving } from "../../components/application/hooks/useSaving";
import { TaskStatus } from "../../api/core/taskAPI";
import { TASK_CAUSES, TASK_TYPES } from "../../constants";
import { getDate } from "../../common/date";
import { useCustomer } from "../admin/customers/hooks/useCustomer";
import taskAPI from "../../api/core/taskAPI";

import { FlexGap } from "../../components/helpers/FlexGap";
import { H1, TB } from "../../components/texts";
import { InputWithLabel, ExpandableSection, SelectForm } from "../../components/inputs";
import ServerSelect from "../../components/application/ServerSelect";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import Button from "../../components/buttons/Button";
import TaskPartsSelector from "./TaskPartsSelector";
import ServiceExecutionForm from "./ServiceExecutionForm";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import InspectionExecutionForm from "./InspectionExecutionForm";
import TaskAdditionalExpenses from "./TaskAdditionalExpenses";
import TaskEditMainSection from "./TaskEditMainSection";
import TaskEditHourCounter from "./TaskEditHourCounter";
import TaskEditResultExtraInfo from "./TaskEditResultExtraInfo";
import UnitDocument from "../admin/units/UnitDocument";
import { useTaskPreChecks } from "../admin/task-pre-checks/hooks/useTaskPreChecks";
import TaskPreCheckModal from "./TaskPreCheckModal";
import TaskPostCheckModal from "./TaskPostCheckModal";
import { useTaskPostChecks } from "../admin/task-post-checks/hooks/useTaskPostChecks";
import TaskPreAndPostCheckExpansion from "./TaskPreAndPostCheckExpansion";
import { CleanButton } from "../../components/buttons";

const TaskEditContainer = ({
    editTaskId,
    onClose,
    workshopId,
    initialData,
    isInMechanicView,
    resetTaskEditor,
}) => {
    const [partsCost, setPartsCost] = useState(0);
    const [additionalExpensesCost, setAdditionalExpensesCost] = useState(0);
    const { saving, save, postErrors } = useSaving();
    const { t } = useTranslation();

    const { data: selectedTask, isLoading } = useTask(editTaskId);
    const { mutateAsync: savePart, isLoading: isSavingPart } = useSavePart();

    const methods = useForm({ mode: "onChange" });

    const {
        formState: { errors: formErrors },
    } = methods;

    const customer_id = methods.watch("customer_id");
    const unit_id = methods.watch("unit_id");
    const internal_note = methods.watch("internal_note");
    const selectedParts = methods.watch("parts");
    const host_id = methods.watch("host_id");
    const taskType_id = methods.watch("type_id");
    const selectedStatus = methods.watch("status");
    const pre_check_template = methods.watch("pre_check_template");
    const post_check_template = methods.watch("post_check_template");
    const unit_hour_counter = methods.watch("unit_hour_counter");
    const [parsedPreCheckTemplate, setParsedPreCheckTemplate] = useState(null);
    const [parsedPostCheckTemplate, setParsedPostCheckTemplate] = useState(null);
    const [isTaskPreCheckModalOpen, setIsTaskPreCheckModalOpen] = useState(false);
    const [isTaskPostCheckModalOpen, setIsTaskPostCheckModalOpen] = useState(false);

    useEffect(() => {
        if (pre_check_template) {
            if (!pre_check_template) {
                setParsedPreCheckTemplate(null);
                return;
            }

            const parsed = JSON.parse(pre_check_template);
            if (!parsed) {
                setParsedPreCheckTemplate(null);
                return;
            }

            parsed?.schema?.elements?.sort((a, b) => a.order - b.order);
            setParsedPreCheckTemplate(parsed);
        }
    }, [pre_check_template]);

    useEffect(() => {
        if (post_check_template) {
            if (!post_check_template) {
                setParsedPostCheckTemplate(null);
                return;
            }

            const parsed = JSON.parse(post_check_template);
            if (!parsed) {
                setParsedPostCheckTemplate(null);
                return;
            }

            parsed?.schema?.elements?.sort((a, b) => a.order - b.order);
            setParsedPostCheckTemplate(parsed);
        }
    }, [post_check_template]);

    const unit = useUnit(unit_id?.value);
    const { data: customerData } = useCustomer(customer_id?.value);
    const taskPreCheck = useTaskPreChecks(host_id);
    const taskPostCheck = useTaskPostChecks(host_id);

    const priorityOptions = [
        { value: "low", label: t("priority-low") },
        { value: "normal", label: t("priority-normal") },
        { value: "high", label: t("priority-high") },
    ];

    const taskTypeOptions = TASK_TYPES.filter((type) => type.active).map((type) => ({
        value: type.id,
        label: t(`task_type.${type.name}`),
    }));

    const taskCauseOptions = TASK_CAUSES.sort((a, b) => a.order - b.order)
        .filter((type) => type.active)
        .map((type) => ({
            value: type.id,
            label: t(`task_cause.${type.name}`),
        }));

    // Reset form based on selected task
    useEffect(() => {
        if (!selectedTask) {
            methods.reset({
                status: initialData?.status || {
                    value: TaskStatus.REQUEST,
                    label: t(`status-${TaskStatus.REQUEST}`),
                },
                start: initialData?.start ? new Date(initialData.start) : null,
                duration: initialData?.duration || 0,
                unit_id: initialData?.unit_id || null,
                mechanic_id: initialData?.mechanic_id || null,
                workorder_id: null,
                priority: {
                    value: "normal",
                    label: priorityOptions.find((item) => item.value === "normal")?.label,
                },
                type_id: initialData?.type_id || null,
                task_check_template: initialData?.task_check_template,
                cause_id: null,
                description: initialData?.description || "",
                report: "",
                overtime: "0",
                kitting_box_id: null,
                internal_note: "",
                parts_cost: 0,
                parts: [],
                customer_id: initialData?.customer_id || null,
                host_id: workshopId || initialData?.host_id || null,
                additional_expenses: [],
                customer_ref: initialData?.customer_ref || "",
                hour_counter: initialData?.unit_hour_counter ?? null,
            });
        } else {
            methods.reset({
                ...selectedTask,
                type_id:
                    taskTypeOptions?.find((item) => item.value === selectedTask?.type_id) || null,
                cause_id:
                    taskCauseOptions?.find((item) => item.value === selectedTask?.cause_id) || null,
                workorder_id: selectedTask.workorder?.id,
                status: { value: selectedTask.status, label: t(`status-${selectedTask.status}`) },
                overtime:
                    typeof selectedTask.overtime === "number" ? String(selectedTask.overtime) : "0",
                start: selectedTask.start ? new Date(selectedTask.start) : null,
                priority: selectedTask.priority
                    ? {
                          value: selectedTask.priority,
                          label: priorityOptions?.find(
                              (item) => item.value === selectedTask.priority
                          )?.label,
                      }
                    : {
                          value: "normal",
                          label: priorityOptions.find((item) => item.value === "normal")?.label,
                      },

                parts: selectedTask.parts || [],
                extra_callout: selectedTask.extra_callout ? "extra_callout" : "",
                extra_shift: selectedTask.extra_shift ? "extra_shift" : "",
                extras: selectedTask.extra_hall
                    ? "extra_hall"
                    : selectedTask.extra_out
                      ? "extra_out"
                      : "",
                task_check_template: formatTaskCheckTemplateForForm(
                    JSON.parse(selectedTask.task_check_template)
                ),
                additional_expenses: selectedTask.additional_expenses || [],
                hour_counter: selectedTask.unit_hour_counter ?? null,
            });
        }
    }, [selectedTask]);

    // Set customer to unit owner when unit is changed
    useEffect(() => {
        if (unit.data) {
            methods.setValue("customer_id", unit.data.customer_id);
        }
    }, [unit?.data]);

    // Reset unit when a different customer is selected
    useEffect(() => {
        if (customer_id?.value && unit?.data && customer_id.value !== unit.data.customer_id) {
            methods.setValue("unit_id", null);
        }
    }, [customer_id]);

    // Show posting errors to user in an alert box
    useEffect(() => {
        if (postErrors)
            toast.error(
                `Errors while posting: ${
                    Array.isArray(postErrors) ? postErrors?.join(", ") : postErrors
                }`
            );
    }, [postErrors]);

    // Main submit function
    async function onSubmit(data, options) {
        // Prevent updating invoiced tasks
        if (
            selectedTask?.status === TaskStatus.INVOICED &&
            data.status?.value !== TaskStatus.COMPLETED &&
            data.status !== TaskStatus.COMPLETED
        ) {
            return toast.error(t("updating_invoiced_task_not_allowed"));
        }

        const unitHourCounter = Number.parseInt(data?.hour_counter);
        const post = {
            status: data.status?.value || data.status,
            start: data.start || null,
            end:
                data.start && data.duration
                    ? new Date(getDate(data.start).plus({ hours: data.duration }))
                    : null,
            duration: +data.duration || 0,
            invoice_duration: +data.duration || 0,
            invoicable: data.invoicable?.value || true,
            unit_id: data.unit_id?.value,
            mechanic_id: data.mechanic_id ? data.mechanic_id.value : null,
            priority: data.priority?.value || data.priority || "",
            type_id: data.type_id?.value || null,
            cause_id: data.cause_id?.value || null,
            description: data.description || "",
            report: data.report,
            overtime: +data.overtime,
            extra_callout: Boolean(data.extra_callout),
            extra_shift: Boolean(data.extra_shift),
            extra_out: Boolean(data.extras === "extra_out"),
            internal_note: data.internal_note || "",
            pre_check_template: data.pre_check_template
                ? typeof data.pre_check_template === "string"
                    ? data.pre_check_template
                    : JSON.stringify(data.pre_check_template)
                : taskPreCheck?.data?.length > 0
                  ? JSON.stringify({
                        version: "1",
                        type: "PreCheck",
                        done: false,
                        customer_id: taskPreCheck.data[0]?.customer_id,
                        schema: {
                            elements: taskPreCheck.data[0]?.checkpoints?.map((checkpoint, i) => ({
                                id: checkpoint.id,
                                type: "Checkbox",
                                name: checkpoint.name,
                                description: checkpoint.description,
                                value: null,
                                order: checkpoint.order ?? i,
                            })),
                        },
                    })
                  : "{}",
            task_check_template:
                data.task_check_template?.serviceConfigurationId ||
                data.task_check_template?.configurationId
                    ? formatTaskCheckTemplateForPost(data.task_check_template)
                    : JSON.stringify({}),
            post_check_template: data.post_check_template
                ? typeof data.post_check_template === "string"
                    ? data.post_check_template
                    : JSON.stringify(data.post_check_template)
                : taskPostCheck?.data?.length > 0
                  ? JSON.stringify({
                        version: "1",
                        type: "PostCheck",
                        done: false,
                        customer_id: taskPostCheck.data[0]?.customer_id,
                        schema: {
                            elements: taskPostCheck.data[0]?.checkpoints?.map((checkpoint, i) => ({
                                id: checkpoint.id,
                                type: "Checkbox",
                                name: checkpoint.name,
                                description: checkpoint.description,
                                value: null,
                                order: checkpoint.order ?? i,
                            })),
                        },
                    })
                  : "{}",
            customer_id: data.customer_id?.value,
            host_id: data.host_id.value || data.host_id,
            parts:
                data.parts?.length > 0
                    ? data.parts
                          .filter((p) => p.id)
                          ?.map((p) => ({
                              part_id: p.id || p.value,
                              count: Number(p.count) || 1,
                              empty: p.empty ?? false,
                          }))
                    : [],
            parts_cost: partsCost || 0,
            living_expenses: data.living_expenses || "",
            extra_hall: Boolean(data.extras === "extra_hall"),
            customer_ref: data.customer_ref || "",
            additional_expenses: data.additional_expenses || null,
            unit_hour_counter: Number.isNaN(unitHourCounter) ? null : unitHourCounter,
        };

        // Calculate the total invoice cost for task
        let hourlyRate = customerData?.price_0;
        if (post.overtime === 100) {
            hourlyRate = customerData?.price_100;
        } else if (post.overtime === 50) {
            hourlyRate = customerData?.price_50;
        }
        const hoursCost = (+post.duration || 0) * (hourlyRate || 0);
        const otherCosts =
            +post.parts_cost +
                +post.additional_expenses?.reduce((acc, value) => acc + value.cost, 0) || 0;
        post.invoice_total = hoursCost + otherCosts;
        //

        // Don't allow completing a task without defining start date, duration or mechanic
        if (post.status === TaskStatus.COMPLETED) {
            if (!post.start || !post.duration || !post.mechanic_id) {
                methods.setError(
                    "duration",
                    { type: "required", message: t("duration_required") },
                    { shouldFocus: true }
                );
                methods.setError("mechanic_id", {
                    type: "required",
                    message: t("mechanic_required"),
                });
                methods.setError("start", { type: "required", message: t("date_required") });
                return;
            }
        }

        // Don't allow setting task status to completed or invoiced without choosing a cause or unit_hour_counter
        if (post.status === TaskStatus.COMPLETED || post.status === TaskStatus.INVOICED) {
            let shouldReturn = false;
            if (!post.cause_id) {
                shouldReturn = true;
                methods.setError("cause_id", { type: "required" }, { shouldFocus: true });
            }

            if (post.unit_hour_counter == null && !unit?.data?.disable_hour_counter) {
                shouldReturn = true;
                methods.setError(
                    "hour_counter",
                    {
                        type: "required",
                        message: t("unit_hour_counter_required"),
                    },
                    { shouldFocus: true }
                );
            }

            if (shouldReturn) return;
        }

        const selectedParts = data.parts?.filter((p) => p.id);
        const newCustomParts = data.parts?.filter((p) => p.custom);

        if (selectedParts?.length) {
            post.parts = selectedParts.map((p) => ({
                part_id: p.id || p.value,
                count: Number(p.count) || 1,
                empty: p.empty ?? false,
            }));
        }

        if (data.id) {
            post.id = data.id;
            post.customer_id = undefined;
            post.unit_id = undefined;
        }
        if (selectedTask?.workorder?.id) {
            delete post.customer_id;
            delete post.unit_id;
        }

        // Create any new custom parts in the database before adding them to the post object
        if (newCustomParts?.length) {
            await Promise.all(
                newCustomParts.map((newPart) =>
                    savePart({
                        name: newPart.name,
                        description: newPart.description,
                        customer_id: post.host_id,
                        stock: false,
                    }).then((response) => {
                        post.parts.push({
                            part_id: response.data?.id,
                            count: Number(newPart.count),
                            empty: false,
                        });
                    })
                )
            );
        }

        const selectedTaskStatus = selectedTask?.status;
        const status = data.status?.value;
        const customer_id = data.customer_id?.value;
        const unit_id = data.unit_id?.value;
        const type_id = data.type_id?.value;
        const { customer_ref, host_id, task_check_template } = data;
        save({
            api: taskAPI.updateTask,
            post,
            onOk: () => {
                if (
                    // Is service task?
                    type_id === 2 &&
                    // Was just marked as completed?
                    selectedTaskStatus !== TaskStatus.COMPLETED &&
                    status === TaskStatus.COMPLETED
                ) {
                    const parsedTaskCheckTemplate =
                        typeof task_check_template === "string"
                            ? JSON.parse(task_check_template)
                            : task_check_template;
                    const nonApprovedFields = parsedTaskCheckTemplate.schema.elements.reduce(
                        (acc, curr) => {
                            curr.elements?.forEach((element) => {
                                const service = element.values?.service;
                                const comment = element.values?.comment ?? "";

                                // eslint-disable-next-line eqeqeq
                                if (service && service != "1") {
                                    acc.push({
                                        name: element.name,
                                        service,
                                        comment,
                                    });
                                }
                            });

                            return acc;
                        },
                        []
                    );
                    if (nonApprovedFields.length > 0) {
                        resetTaskEditor({
                            customer_id,
                            unit_id,
                            customer_ref,
                            host_id,
                            description: nonApprovedFields
                                .map(({ name, comment }) => `${name}:\n${comment}`)
                                .join("\n\n"),
                        });
                        return;
                    }
                } else if (
                    // Is control task?
                    type_id === 3 &&
                    // Was just marked as completed?
                    selectedTaskStatus !== TaskStatus.COMPLETED &&
                    status === TaskStatus.COMPLETED
                ) {
                    const parsedTaskCheckTemplate =
                        typeof task_check_template === "string"
                            ? JSON.parse(task_check_template)
                            : task_check_template;
                    const hasNonOKValues = parsedTaskCheckTemplate.schema.elements.some(
                        (groupElement) =>
                            groupElement.elements?.some((element) => {
                                const service = element.values?.service?.value;
                                switch (service) {
                                    case "0": // - 0 - Checked and found in order
                                    case "OK":
                                    case "M": // M - Not found
                                    case "U": // U - Fixed
                                    case "NA": // NA - Not applicable
                                    case "-": // - - Not applicable
                                    case null: // Left empty
                                    case undefined: // Left empty
                                        return false;
                                    default:
                                        return true;
                                }
                            })
                    );
                    if (hasNonOKValues) {
                        const result = {
                            ...parsedTaskCheckTemplate,
                            schema: {
                                ...parsedTaskCheckTemplate.schema,
                                elements: parsedTaskCheckTemplate.schema.elements.map(
                                    (groupElement) => {
                                        let allElementsHidden = true;
                                        const elements = groupElement.elements.map((element) => {
                                            const service = element.values?.service?.value;
                                            const hidden = (() => {
                                                if (groupElement.order === -1) return false;
                                                switch (service) {
                                                    case "0": // - 0 - Checked and found in order
                                                    case "OK":
                                                    case "M": // M - Not found
                                                    case "U": // U - Fixed
                                                    case "NA": // NA - Not applicable
                                                    case "-": // - - Not applicable
                                                    case null: // Left empty
                                                    case undefined: // Left empty
                                                        return true;
                                                    default:
                                                        return false;
                                                }
                                            })();
                                            if (!hidden) {
                                                allElementsHidden = false;
                                            }
                                            return {
                                                ...element,
                                                hidden,
                                            };
                                        });
                                        return {
                                            ...groupElement,
                                            elements,
                                            hidden: allElementsHidden,
                                        };
                                    }
                                ),
                            },
                        };
                        resetTaskEditor({
                            customer_id,
                            unit_id,
                            customer_ref,
                            host_id,
                            type_id: {
                                value: 3,
                                label: t("task_type.periodic_technical_inspection"),
                            },
                            task_check_template: result,
                        });
                        return;
                    }
                }

                const close = options?.doNotCloseOnSubmit ? null : onClose;
                if (close) {
                    close();
                }
            },
        });
    }

    function formatTaskCheckTemplateForPost(templateData) {
        if (!Object.keys(templateData).length) return JSON.stringify({});

        if (
            templateData.type === "PeriodicTechnicalInspection" &&
            templateData.schema?.elements?.length > 0
        ) {
            let updatedElements = null;

            switch (templateData.version) {
                case "1":
                    updatedElements = templateData.schema.elements.map((element) => {
                        const serviceValue =
                            element.values?.service?.value || element.values?.service || null;

                        if (serviceValue) {
                            return {
                                ...element,
                                values: {
                                    ...element.values,
                                    service: serviceValue,
                                },
                            };
                        } else {
                            return element;
                        }
                    });
                    break;
                default:
                    updatedElements = templateData.schema.elements.map((group) => ({
                        ...group,
                        elements: group.elements.map((element) => {
                            const serviceValue =
                                element.values?.service?.value || element.values?.service || null;

                            if (serviceValue) {
                                return {
                                    ...element,
                                    values: {
                                        ...element.values,
                                        service: serviceValue,
                                    },
                                };
                            } else {
                                return element;
                            }
                        }),
                    }));
            }

            return JSON.stringify({
                ...templateData,
                schema: { elements: updatedElements },
            });
        } else {
            return JSON.stringify(templateData);
        }
    }

    function formatTaskCheckTemplateForForm(templateData) {
        if (!templateData || !Object.keys(templateData).length) return {};

        if (
            templateData.type === "PeriodicTechnicalInspection" &&
            templateData.schema?.elements?.length > 0
        ) {
            let updatedElements = null;

            switch (templateData.version) {
                case "1":
                    updatedElements = templateData.schema.elements.map((element) => {
                        const serviceValue = element.values?.service;

                        if (serviceValue) {
                            return {
                                ...element,
                                values: {
                                    ...element.values,
                                    service: {
                                        value: serviceValue,
                                        label: t(`inspection_result-${serviceValue}`),
                                    },
                                },
                            };
                        } else {
                            return element;
                        }
                    });
                    break;
                default:
                    updatedElements = templateData.schema.elements.map((group) => ({
                        ...group,
                        elements: group.elements.map((element) => {
                            const serviceValue = element.values?.service;

                            if (serviceValue) {
                                return {
                                    ...element,
                                    values: {
                                        ...element.values,
                                        service: {
                                            value: serviceValue,
                                            label: t(`inspection_result-${serviceValue}`),
                                        },
                                    },
                                };
                            } else {
                                return element;
                            }
                        }),
                    }));
            }

            const formatedTemplateData = {
                ...templateData,
                schema: {
                    elements: updatedElements,
                },
            };

            return formatedTemplateData;
        } else {
            return templateData;
        }
    }

    function handleActivityActionClick() {
        const currentStatus = selectedTask.status;

        const isTaskStarted = currentStatus === TaskStatus.STARTED;
        const isTaskFinished = currentStatus === TaskStatus.COMPLETED;
        const shouldCloseOnSave = !isTaskStarted && !isTaskFinished;

        const newStatus = isTaskStarted
            ? TaskStatus.COMPLETED
            : isTaskFinished
              ? TaskStatus.INVOICED
              : TaskStatus.STARTED;

        methods.handleSubmit((data) =>
            onSubmit(
                { ...data, status: { value: newStatus } },
                { doNotCloseOnSubmit: shouldCloseOnSave }
            )
        )();
    }

    return isLoading ? (
        <SpinnerContainer>
            <LoadingSpinner light />
        </SpinnerContainer>
    ) : (
        <>
            {isTaskPreCheckModalOpen ? (
                <TaskPreCheckModal
                    isOpen={isTaskPreCheckModalOpen}
                    preCheckTemplate={parsedPreCheckTemplate}
                    setPreCheckTemplate={(val) => {
                        methods.setValue("pre_check_template", JSON.stringify(val));
                        setIsTaskPreCheckModalOpen(false);
                        if (selectedTask?.status === TaskStatus.INVOICED) return;
                        if (selectedTask?.status === TaskStatus.COMPLETED) return;
                        if (selectedTask?.status === TaskStatus.DENIED) return;
                        if (selectedTask?.status === TaskStatus.STARTED) return;
                        handleActivityActionClick();
                    }}
                    onClose={() => setIsTaskPreCheckModalOpen(false)}
                />
            ) : null}
            {isTaskPostCheckModalOpen ? (
                <TaskPostCheckModal
                    isOpen={isTaskPostCheckModalOpen}
                    postCheckTemplate={parsedPostCheckTemplate}
                    setPostCheckTemplate={(val) => {
                        methods.setValue("post_check_template", JSON.stringify(val));
                        setIsTaskPostCheckModalOpen(false);
                        if (selectedTask?.status !== TaskStatus.STARTED) return;
                        handleActivityActionClick();
                    }}
                    onClose={() => setIsTaskPostCheckModalOpen(false)}
                />
            ) : null}
            <TaskEditStyled>
                <TitleStyled>{t("plan_task_execution")}</TitleStyled>

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {!host_id && (
                            <>
                                <WorkshopSelectContainer>
                                    <ServerSelect
                                        label={t("choose_workshop")}
                                        name="host_id"
                                        selectUse={useWorkshops}
                                        validate={{ required: t("workshop_required") }}
                                    />
                                </WorkshopSelectContainer>
                                <Summary>
                                    <SummaryButtons>
                                        <SaveButtonsContainer>
                                            <CleanButton type="button" onClick={onClose}>
                                                <TB $underline $link>
                                                    {t("abort")}
                                                </TB>
                                            </CleanButton>
                                        </SaveButtonsContainer>
                                    </SummaryButtons>
                                </Summary>
                            </>
                        )}

                        {!!host_id && (
                            <>
                                <TaskEditMainSection
                                    selectedTask={selectedTask}
                                    isInMechanicView={isInMechanicView}
                                    workshopId={workshopId}
                                />

                                {/* COMMUNICATION SECTION */}
                                <ExpandableSection
                                    label={`${t("communication")} (${`${internal_note ? 1 : 0} ${t("note")}`})`}
                                >
                                    <FlexGap $count={2}>
                                        <InputWithLabel
                                            name={"internal_note"}
                                            label={t("internal_note")}
                                            rows={6}
                                            disabled={isInMechanicView && !!selectedTask}
                                        />
                                    </FlexGap>
                                    <FlexGap $count={2}>
                                        <InputWithLabel
                                            name={"tip"}
                                            label={t("tip_for_unit_maintenance")}
                                            rows={6}
                                            value={selectedTask?.unit?.comment}
                                            disabled
                                        />
                                    </FlexGap>
                                </ExpandableSection>

                                {/* MATERIALS/PARTS SECTION */}
                                <ExpandableSection
                                    label={`${t("materials")} (${selectedParts?.length || 0} ${
                                        selectedParts?.length === 1
                                            ? t("part")
                                            : t("parts_lowercase")
                                    })`}
                                >
                                    <TaskPartsSelector
                                        setPartsCost={setPartsCost}
                                        customerId={customer_id?.value}
                                        hostId={host_id?.value || host_id}
                                        kittingBox={selectedTask?.kitting_box}
                                    />

                                    <TaskAdditionalExpenses
                                        setAdditionalExpensesCost={setAdditionalExpensesCost}
                                    />
                                </ExpandableSection>

                                {/* SERVICE SECTION */}
                                {taskType_id?.value === 2 ? (
                                    <ExpandableSection label={`${t("service")}`}>
                                        <ServiceExecutionForm
                                            selectedTask={selectedTask}
                                            isServiceDone={
                                                selectedTask?.status === TaskStatus.COMPLETED ||
                                                selectedTask?.status === TaskStatus.INVOICED
                                            }
                                        />
                                    </ExpandableSection>
                                ) : null}

                                {/* PERIODIC TECHNICAL INSPECTION SECTION */}
                                {taskType_id?.value === 3 ? (
                                    <ExpandableSection
                                        label={`${t("periodic_technical_inspection")}`}
                                    >
                                        <InspectionExecutionForm
                                            selectedTask={selectedTask}
                                            isInspectionDone={
                                                selectedTask?.status === TaskStatus.COMPLETED ||
                                                selectedTask?.status === TaskStatus.INVOICED
                                            }
                                        />
                                    </ExpandableSection>
                                ) : null}

                                {/* TASK PRE CHECK SECTION */}
                                <TaskPreAndPostCheckExpansion
                                    parsedPreCheckTemplate={parsedPreCheckTemplate}
                                    parsedPostCheckTemplate={parsedPostCheckTemplate}
                                    setPreCheckTemplate={(val) => {
                                        methods.setValue(
                                            "pre_check_template",
                                            typeof val === "string" ? val : JSON.stringify(val)
                                        );
                                    }}
                                    setPostCheckTemplate={(val) => {
                                        methods.setValue(
                                            "post_check_template",
                                            typeof val === "string" ? val : JSON.stringify(val)
                                        );
                                    }}
                                />

                                {/* DOCUMENTS SECTION */}
                                <ExpandableSection label={t("documents_title")}>
                                    <h4>{t("from_unit")}:</h4>
                                    {unit?.data?.data?.documents?.length > 0 ? (
                                        <DocumentsContainer>
                                            {unit.data.data.documents?.map((document) => (
                                                <UnitDocument
                                                    key={`image-${document}`}
                                                    documentId={document}
                                                    showDeleteButton={false}
                                                />
                                            ))}
                                        </DocumentsContainer>
                                    ) : (
                                        <NothingToShow>
                                            <TB>{t("no_documents_to_show")}</TB>
                                        </NothingToShow>
                                    )}
                                </ExpandableSection>

                                {/* RESULTS SECTION */}
                                <ExpandableSection
                                    label={t("result")}
                                    expandedByDefault={isInMechanicView && !!selectedTask}
                                    expanded={
                                        !!formErrors?.cause_id || !!formErrors?.hour_counter
                                            ? true
                                            : null
                                    }
                                >
                                    <FlexGap $count={3}>
                                        <TaskCauseSelect
                                            name="cause_id"
                                            label={t("cause")}
                                            options={taskCauseOptions}
                                            placeholder={t("select")}
                                            validate={{
                                                required:
                                                    selectedStatus?.value ===
                                                        TaskStatus.COMPLETED ||
                                                    selectedStatus?.value === TaskStatus.INVOICED
                                                        ? t("cause_required")
                                                        : false,
                                            }}
                                            isClearable
                                        />
                                    </FlexGap>

                                    <FlexGap $count={2}>
                                        <InputWithLabel
                                            name={"report"}
                                            label={t("feedback_to_customer")}
                                            rows={10}
                                        />

                                        <TaskEditResultExtraInfo
                                            partsCost={partsCost}
                                            additionalExpensesCost={additionalExpensesCost}
                                            invoiceTotal={selectedTask?.invoice_total}
                                            unitHourCounter={selectedTask?.unit_hour_counter}
                                            status={selectedTask?.status}
                                        />
                                    </FlexGap>

                                    <ResultSeperationLine />
                                    <ResultSectionHeading>
                                        {t("vehicle_update")}
                                    </ResultSectionHeading>

                                    <TaskEditHourCounter
                                        defaultValue={unit_hour_counter}
                                        disabled={unit?.data?.disable_hour_counter === true}
                                    />
                                </ExpandableSection>

                                <Summary>
                                    <SummaryButtons>
                                        <SaveButtonsContainer>
                                            <SaveOrAbort
                                                save={saving || isSavingPart ? "saving" : "save"}
                                                saving={saving || isSavingPart}
                                                onAbort={onClose}
                                            />
                                        </SaveButtonsContainer>

                                        <MechanicButtonsContainer>
                                            <ActionButton
                                                type="button"
                                                onClick={() => {
                                                    const values = methods.getValues();
                                                    const {
                                                        customer_id,
                                                        unit_id,
                                                        customer_ref,
                                                        host_id,
                                                    } = values;

                                                    resetTaskEditor({
                                                        customer_id: customer_id.value,
                                                        unit_id: unit_id.value,
                                                        customer_ref,
                                                        host_id,
                                                    });
                                                }}
                                            >
                                                {t("new_request")}
                                            </ActionButton>
                                        </MechanicButtonsContainer>

                                        {parsedPostCheckTemplate?.type === "PostCheck" &&
                                        parsedPostCheckTemplate?.schema?.elements?.length > 0 &&
                                        parsedPostCheckTemplate.done === false &&
                                        selectedTask?.status === TaskStatus.STARTED ? (
                                            <ActivityButton
                                                type="button"
                                                onClick={() => {
                                                    setIsTaskPostCheckModalOpen(true);
                                                }}
                                            >
                                                {t("finish_activity")}
                                            </ActivityButton>
                                        ) : parsedPreCheckTemplate?.type === "PreCheck" &&
                                          parsedPreCheckTemplate?.schema?.elements?.length > 0 &&
                                          parsedPreCheckTemplate.done === false &&
                                          selectedTask?.status !== TaskStatus.INVOICED &&
                                          selectedTask?.status !== TaskStatus.DENIED &&
                                          selectedTask?.status !== TaskStatus.COMPLETED &&
                                          selectedTask?.status !== TaskStatus.STARTED ? (
                                            <ActivityButton
                                                type="button"
                                                onClick={() => {
                                                    setIsTaskPreCheckModalOpen(true);
                                                }}
                                            >
                                                {t("start_activity")}
                                            </ActivityButton>
                                        ) : (
                                            <ActivityButton
                                                type="button"
                                                onClick={handleActivityActionClick}
                                                disabled={
                                                    !selectedTask ||
                                                    selectedTask?.status === TaskStatus.INVOICED ||
                                                    !selectedTask.mechanic_id ||
                                                    !selectedTask.start
                                                }
                                            >
                                                {t(
                                                    selectedTask?.status === TaskStatus.STARTED
                                                        ? "finish_activity"
                                                        : selectedTask?.status ===
                                                            TaskStatus.COMPLETED
                                                          ? "invoice_activity"
                                                          : selectedTask?.status ===
                                                              TaskStatus.INVOICED
                                                            ? "status-invoiced"
                                                            : "start_activity"
                                                )}
                                            </ActivityButton>
                                        )}
                                    </SummaryButtons>
                                </Summary>
                            </>
                        )}
                    </form>
                </FormProvider>
            </TaskEditStyled>
        </>
    );
};

export default TaskEditContainer;

const ResultSeperationLine = styled.hr`
    border: none;
    border-bottom: 2px solid ${(p) => p.theme.color.primary.light};
    margin: 1rem 0;
`;

const ResultSectionHeading = styled.p`
    font-weight: bold;
    color: ${(p) => p.theme.color.neutral.base};
`;

const WorkshopSelectContainer = styled.section`
    padding-left: 1rem;
    max-width: max(25%, 20rem);
`;

const TaskEditStyled = styled.div`
    .collapseBottomMargin {
        margin-bottom: -1.5rem;
    }
`;

const TitleStyled = styled(H1)`
    padding-left: 1rem;
`;

const Summary = styled.section`
    padding: 0rem 1rem 0.5rem;

    > ${TB} {
        display: block;
        margin: 0.5rem 0 1rem;
    }

    ${Button} {
        margin-right: 1rem;

        ${TB} {
            color: white;
        }
    }
`;

const SummaryButtons = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
`;

const SpinnerContainer = styled.article`
    height: 100%;
`;

const SaveButtonsContainer = styled.section`
    margin-right: 2rem;
    margin-bottom: 1rem;
`;

const MechanicButtonsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1rem;
    margin-right: auto;
`;

const ActionButton = styled(Button)`
    background: ${(p) => p.theme.color.primary.light};
    margin-bottom: 1rem;
`;

const ActivityButton = styled(Button)`
    min-width: 15rem;
    margin-top: 2rem;
    margin: 1rem 0;
`;

const TaskCauseSelect = styled(SelectForm)`
    margin-bottom: 1.5rem;
`;

const DocumentsContainer = styled.div`
    display: flex;
`;

const NothingToShow = styled.section`
    min-height: 10rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;
