import { useState, useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isValid } from "date-fns";
import styled from "styled-components";

import { useUpdateTaskPart } from "../task/mutations/useUpdateTaskPart";
import { TaskPartStatus } from "../../api/core/taskAPI";
import QueryWrapper from "../../components/application/QueryWrapper";

import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { InputWithLabel, SelectForm, InputCheckboxClean } from "../../components/inputs";
import { CleanButton, SecondaryButton } from "../../components/buttons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import TaskEdit from "../task/TaskEdit";
import { standardDateOnly } from "../../common/date";
import { Horizontal } from "../../components/layout/FlexGrid";
import { useRequisitions } from "../task/hooks/useRequisitions";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";

const InventoryWithdrawal = () => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const { t } = useTranslation();
    const methods = useForm();

    const tasks = useRequisitions();
    const { mutate: updateTaskPart } = useUpdateTaskPart();

    const search = methods.watch("search");
    const checkboxes = methods.watch("checkboxes");
    const statusFilterValue = methods.watch("status")?.value || "all_open_orders";
    const selectedRequests = checkboxes
        ?.filter((item) => item.checked)
        ?.map((item) => item.requestId);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 30,
            },
        }),
        []
    );

    const data = useMemo(() => {
        if (!tasks?.data || !tasks.data.length) return [];

        const allRequestedParts = [];
        tasks.data.forEach((task) => {
            if (task.parts?.length) {
                task.parts.forEach((part) => {
                    const count = part?.count || 1;
                    allRequestedParts.push({
                        ...task,
                        requestedPart: {
                            ...part,
                            count,
                            cost: part?.part?.price ? part.part.price * count : null,
                        },
                    });
                });
            }
        });

        const allRequestsInStock = allRequestedParts.filter(
            (item) => item.requestedPart?.part?.stock
        );
        const requestsWithSelectedStatus = allRequestsInStock?.filter((item) =>
            statusFilterValue === "all_open_orders"
                ? item.requestedPart.status !== "paid"
                : item.requestedPart.status === "paid"
        );

        return requestsWithSelectedStatus;
    }, [tasks?.data, statusFilterValue]);

    // Add request ids to row checkbox values
    useEffect(() => {
        const allRequestIds =
            data?.map((part) => ({
                requestId: part.requestedPart.id,
                checked: false,
            })) || [];
        methods.setValue("checkboxes", allRequestIds);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                header: "",
                id: "checkbox",
                accessorKey: "requestedPart.id",
                cell: ({ row, getValue }) => (
                    <CheckboxInputContainer>
                        <InputCheckboxClean
                            id={`check-${getValue()}`}
                            {...methods.register(`checkboxes.${row.id}.checked`)}
                        />
                    </CheckboxInputContainer>
                ),
                enableSorting: false,
            },
            {
                header: t("status"),
                accessorFn: (row) =>
                    row.requestedPart.status === "paid" ? "invoiced" : row.requestedPart.status,
                cell: ({ getValue }) =>
                    getValue() === "invoiced" ? t(`status-invoiced`) : t(`status-withdrawal`),
            },
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value && isValid(new Date(value)) ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("order"),
                accessorKey: "workorder_id",
                cell: ({ getValue, row }) => (
                    <CleanButton type="button" onClick={() => openEditorForTask(row.original.id)}>
                        <TB $link>{getValue()}</TB>
                    </CleanButton>
                ),
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
            },
            {
                header: t("customer_ref"),
                accessorKey: "customer_ref",
            },
            {
                header: t("part_name"),
                accessorKey: "requestedPart.part.name",
                cell: ({ row, getValue }) => {
                    const partId = row.original.requestedPart.part_id;
                    return (
                        <Link to={`/materials/parts/${partId}/edit`}>
                            <TB $link>{getValue()}</TB>
                        </Link>
                    );
                },
            },
            {
                header: t("description_2"),
                accessorKey: "requestedPart.part.description",
                id: "description",
                enableSorting: false,
            },
            {
                header: t("quantity"),
                accessorKey: "requestedPart.count",
                enableSorting: false,
            },
            {
                header: t("empty"),
                accessorKey: "requestedPart.empty",
                enableSorting: false,
                cell: ({ getValue }) => (getValue() ? t("yes") : t("no")),
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        initialState,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function onStatusUpdate(newStatus) {
        const dataToPatch = selectedRequests?.map((id) => ({ id, status: newStatus }));

        if (dataToPatch?.length) {
            Promise.all(dataToPatch.map((part) => updateTaskPart(part)));
        }
    }

    return (
        <MainArea>
            <Header>
                <H1>{t("inventory_withdrawal")}</H1>
            </Header>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        name="search"
                        label={t("search_inventory_withdrawal")}
                        style={{ maxWidth: "27rem" }}
                    />

                    <OptionsRow>
                        <SelectStyled
                            name={`status`}
                            defaultValue={{ value: "all_open_orders", label: t("all_open_orders") }}
                            label={t("show_status")}
                            options={[
                                { value: "all_open_orders", label: t("all_open_orders") },
                                { value: "invoiced", label: t("status-invoiced") },
                            ]}
                        />

                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.PAID)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_invoiced")}
                        </StatusActionButton>
                    </OptionsRow>

                    <QueryWrapper data={[tasks]}>
                        {rowModel.rows.length > 0 ? (
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                    />
                                </TableStyled>
                            </TableContainer>
                        ) : (
                            <NothingToShow>
                                <TB>{t("no_requisitions_to_show")}</TB>
                            </NothingToShow>
                        )}
                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </form>
            </FormProvider>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                />
            )}
        </MainArea>
    );
};

export default InventoryWithdrawal;

const Header = styled(Horizontal)`
    align-items: center;
    flex-wrap: wrap;
`;

const CheckboxInputContainer = styled.section`
    position: relative;
`;

const StatusActionButton = styled(SecondaryButton).attrs({ type: "button" })`
    padding-top: 0.7rem;
    margin: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
`;

const SelectStyled = styled(SelectForm)`
    min-width: 10rem;
    margin: 0.3rem 0;
`;

const OptionsRow = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    ${SelectStyled} {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
`;

const TableContainer = styled.article`
    margin-top: 1rem;
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 75rem;

    td:first-child,
    th:first-child {
        min-width: 1rem;
        max-width: 2rem;
    }

    tr {
        td:nth-child(4) {
            padding-right: 2rem;
        }

        td:nth-child(12),
        td:nth-child(13),
        td:nth-child(14),
        td:nth-child(15) {
            max-width: 10rem;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;
