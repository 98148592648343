import { createContext, useCallback, useEffect, useRef } from "react";
import { useQueryClient, useMutation } from "react-query";
import io from "socket.io-client";

import { SOCKET_BASE_URL, SOCKET_HOSTNAME_URL, SOCKET_PATH } from "../../constants";
import taskAPI from "../../api/core/taskAPI";

export const socket = io(SOCKET_HOSTNAME_URL, {
    path: SOCKET_PATH,
    autoConnect: false,
});

export const WebSocketContext = createContext(null);

export default ({ children }) => {
    const lastJwt = useRef(null);
    const listeners = useRef({});
    const taskPayloads = useRef([]);
    const debounceTimer = useRef(null);

    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: () => {
            const payloads = taskPayloads.current;
            taskPayloads.current = [];
            const ids = payloads.map(({ id }) => id).join(",");
            const idToCrud = payloads.reduce((acc, { id, crud }) => {
                acc.set(id, crud);
                return acc;
            }, new Map());

            taskAPI.getTasks(`?ids=${ids}`).then((tasks) =>
                queryClient
                    .getQueryCache()
                    .findAll({
                        queryKey: ["tasks", "websocket-hooked"],
                    })
                    .forEach(({ queryKey, meta }) => {
                        tasks.forEach((task) => {
                            const crud = idToCrud.get(task.id);
                            if (crud === "read") return;
                            const filteredOut = !meta?.filterFn?.(task);
                            if (filteredOut) {
                                if (crud === "create") return;
                                if (crud === "delete") return;
                            }

                            queryClient.setQueryData(queryKey, (oldData) => {
                                if (crud === "create") {
                                    if (oldData == null) return [task];
                                    // Check if task already exists in dataset
                                    return oldData?.some((oldTask) => oldTask.id === task.id)
                                        ? oldData
                                        : [...oldData, task];
                                }
                                if (crud === "delete") {
                                    return oldData?.filter(
                                        (filterTask) => filterTask.id !== task.id
                                    );
                                }
                                if (crud === "update") {
                                    // Handle task updates filtering out existing tasks
                                    if (filteredOut) {
                                        return oldData?.filter(
                                            (filterTask) => filterTask.id !== task.id
                                        );
                                    }
                                    let taskExistsInOldData = false;
                                    const newData = oldData?.map((oldTask) => {
                                        if (oldTask.id === task.id) {
                                            taskExistsInOldData = true;
                                            return task;
                                        }
                                        return oldTask;
                                    });

                                    // Handle task updates filtering in existing tasks
                                    if (!taskExistsInOldData)
                                        return newData ? [...newData, task] : [task];

                                    return newData;
                                }

                                return oldData;
                            });
                        });
                    })
            );
        },
    });

    useEffect(() => {
        function onConnect() {
            console.log("Socket connected to", SOCKET_BASE_URL);
        }

        function onMessage(payload) {
            console.log("Got message", payload);

            switch (payload.command) {
                case "unit":
                    queryClient.invalidateQueries("units");
                    break;
                case "unit_update":
                    queryClient.invalidateQueries("units");
                    break;
                case "unit-comment":
                    queryClient.invalidateQueries("units");
                    break;
                case "user":
                    queryClient.invalidateQueries("users");
                    break;
                case "customer":
                    queryClient.invalidateQueries("customers");
                    break;
                case "role":
                    queryClient.invalidateQueries("permissions");
                    queryClient.invalidateQueries("roles");
                    queryClient.invalidateQueries("users");
                    break;
                case "area":
                    queryClient.invalidateQueries("unitAreas");
                    break;
                case "unit-group":
                    queryClient.invalidateQueries("units");
                    break;
                case "pre-check":
                    queryClient.invalidateQueries("prechecks");
                    break;
                case "task":
                    const { id, crud } = payload;
                    taskPayloads.current.push(payload);
                    queryClient.invalidateQueries("task", id);

                    if (crud === "read") return;
                    clearTimeout(debounceTimer.current);
                    debounceTimer.current = setTimeout(() => {
                        mutate();
                    }, 500);
                    break;
                default:
                    return;
            }
        }

        function onSensor(payload) {
            const parsedPayload = JSON.parse(payload);

            try {
                const key = `UNIT:${parsedPayload.unit_id}:${parsedPayload.customer_id}`;
                if (key in listeners.current) {
                    listeners.current[key].forEach((callback) => callback(parsedPayload));
                }
            } catch (error) {
                console.log("Got error receiving sensor data from socket", error);
            }
        }

        function onReconnectAttempt() {
            console.log("Socket reconnect_attempt");
            socket.query = {
                token: lastJwt.current,
            };
            socket.io.opts.query = {
                token: lastJwt.current,
            };
        }

        function onDisconnect() {
            console.log("Socket disconnected");
        }

        function onReconnect() {
            console.log("Socket reconnected");
        }

        socket.on("connect", onConnect);
        socket.on("message", onMessage);
        socket.on("sensor", onSensor);
        socket.on("reconnect_attempt", onReconnectAttempt);
        socket.on("disconnect", onDisconnect);
        socket.on("reconnect", onReconnect);

        return () => {
            socket.off("connect", onConnect);
            socket.off("message", onMessage);
            socket.off("sensor", onSensor);
            socket.off("reconnect_attempt", onReconnectAttempt);
            socket.off("disconnect", onDisconnect);
            socket.off("reconnect", onReconnect);
        };
    }, []);

    const addSensorListener = useCallback(({ unit_id, customer_id, token, callback }) => {
        const key = `UNIT:${unit_id}:${customer_id}`;
        if (!(key in listeners.current)) listeners.current[key] = [];
        listeners.current[key].push(callback);
        socket?.emit("sensor_add_unit", { unit_id, customer_id, token });
    });

    const removeSensorListener = useCallback((callback) => {
        for (const key of Object.keys(listeners.current)) {
            const keep = listeners.current[key].filter((fn) => fn !== callback);
            if (keep?.length !== listeners.current[key].length) {
                if (keep.length === 0) {
                    const keyParts = key.split(":");
                    socket.emit("sensor_remove_unit", {
                        unit_id: keyParts[1],
                        customer_id: keyParts[2],
                    });
                    delete listeners.current[key];
                } else {
                    listeners.current[key] = keep;
                }
                return;
            }
        }
    });

    return (
        <WebSocketContext.Provider
            value={{
                addSensorListener,
                removeSensorListener,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};
