import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import Task from "./Task";
import { useNotScheduledTasks } from "../task/hooks/useNotScheduledTasks";
import { CleanButton } from "../../components/buttons";
import { TB } from "../../components/texts";
import { useTranslation } from "react-i18next";

const TasksList = ({
    workshopId,
    handleTaskDoubleClick,
    isInAutoPlannerMode,
    onTaskCheckboxUpdate,
    autoPlannerSelectedTaskIds,
    autoPlannerPlannedTasks,
    setAutoPlannerSelectedTaskIds,
}) => {
    const { t } = useTranslation();
    const tooltipRenderTarget = useRef(null);
    const { data: tasks } = useNotScheduledTasks(workshopId);
    const mergedTasks = useMemo(() => {
        if (tasks == null) return [];

        const autoPlannerTasksMap = new Map();
        for (const autoPlannedTask of autoPlannerPlannedTasks) {
            autoPlannerTasksMap.set(autoPlannedTask.id, autoPlannedTask);
        }

        return tasks.reduce((acc, task) => {
            acc.push({
                ...task,
                isAutoPlanned: autoPlannerTasksMap.get(task.id) != null,
            });

            return acc;
        }, []);
    }, [tasks, autoPlannerPlannedTasks]);

    return (
        <WrapperStyled className="tasks" data-dropzone="tasks" ref={tooltipRenderTarget}>
            {mergedTasks
                .sort((a, b) =>
                    a.unit?.["int_id"] < b.unit?.["int_id"]
                        ? -1
                        : a.unit?.["int_id"] > b.unit?.["int_id"]
                          ? 1
                          : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                )
                .map((task) => {
                    const isCheckboxChecked = autoPlannerSelectedTaskIds.includes(task.id);
                    const isCheckboxDisabled = autoPlannerPlannedTasks.length > 0;
                    return (
                        <Task
                            key={task.id}
                            task={task}
                            tooltipRenderTarget={tooltipRenderTarget}
                            handleTaskDoubleClick={handleTaskDoubleClick}
                            isCheckboxDisabled={isCheckboxDisabled}
                            isCheckboxVisible={
                                isInAutoPlannerMode && (isCheckboxChecked || !isCheckboxDisabled)
                            }
                            isCheckboxChecked={isCheckboxChecked}
                            onCheckboxClick={() => {
                                onTaskCheckboxUpdate(
                                    task.id,
                                    !autoPlannerSelectedTaskIds.includes(task.id)
                                );
                            }}
                        />
                    );
                })}
            {isInAutoPlannerMode && autoPlannerPlannedTasks.length === 0 ? (
                <CleanButton
                    onClick={() => {
                        setAutoPlannerSelectedTaskIds(tasks.map(({ id }) => id));
                    }}
                >
                    <TB $link>{t("select_all")}</TB>
                </CleanButton>
            ) : null}
        </WrapperStyled>
    );
};

export default TasksList;

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
`;
